import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionSwitchAccount = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M8.262 9.75a3.75 3.75 0 1 0 7.5 0 3.75 3.75 0 0 0-7.5 0Zm5.5 0a1.75 1.75 0 1 1-3.499 0 1.75 1.75 0 0 1 3.5 0Zm-8.11 7.08A7.93 7.93 0 0 1 4.012 12a8 8 0 0 1 15.59-2.5h2.08a10.001 10.001 0 1 0-9.67 12.5h.5v-2c-.166.01-.333.01-.5 0a8 8 0 0 1-4.8-1.62l.5-1.38h4.8v-2h-6.2l-.66 1.83Zm16.62.67a3.26 3.26 0 0 1-3.26 3.25h-1.93l1.47-1.75h-2l-2.1 2.5 2.1 2.5h2l-1.47-1.75h1.93a4.75 4.75 0 0 0 4.75-4.75h-1.49Zm-3.26-3.25h1.94L19.473 16h2l2.1-2.5-2.1-2.5h-2l1.47 1.75h-1.93a4.75 4.75 0 0 0-4.75 4.75h1.5a3.26 3.26 0 0 1 3.25-3.25Z"
    />
  </svg>
);
export default SvgActionSwitchAccount;
