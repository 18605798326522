import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscLearnFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M5 13.74V17.61l7 4.04 7-4.04v-3.87l-7 4.04-7-4.04Z"
    />
    <path
      fill="#2D2E2E"
      d="M12 3.35 1 9.7l11 6.35 7.92-4.58 1.08-.62V19h2V9.7L12 3.35Z"
    />
  </svg>
);
export default SvgMiscLearnFill;
