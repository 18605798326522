import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgFormCheckSquare = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M3 21h18V3H3v18ZM17.71 9.71 11 16.41l-4.21-4.2 1.42-1.42 2.79 2.8 5.29-5.3 1.42 1.42Z"
    />
  </svg>
);
export default SvgFormCheckSquare;
