import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgZapDetails = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path fill="#2D2E2E" d="M20 8V6.59L15.41 2H4v20h16V10h-2v10H6V4h8v4h6Z" />
    <path
      fill="#2D2E2E"
      d="m10.75 18.858 4.809-5.729H13.25V9.067l-4.808 5.729h2.308v4.062Z"
    />
  </svg>
);
export default SvgZapDetails;
