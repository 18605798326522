// Based on https://github.com/Swizec/useDimensions

import { useEffect, useRef, useState } from 'react';

type Dimensions = [React.MutableRefObject<HTMLElement | null>, DOMRect];

const defaultDOMRect: DOMRect = {
  height: 0,
  width: 0,
  x: 0,
  y: 0,
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
  toJSON: () => {},
};

export const useDimensions = (): Dimensions => {
  const ref = useRef<HTMLElement>(null);
  const [dimensions, setDimensions] = useState<DOMRect>(defaultDOMRect);

  useEffect(() => {
    const rect =
      ref && ref.current
        ? (ref.current.getBoundingClientRect() as DOMRect)
        : defaultDOMRect;
    setDimensions(rect);
  }, []);

  return [ref, dimensions];
};
