import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgArrowRedo = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      fillRule="evenodd"
      d="m15.03 12 2.52-3H10.5a4.5 4.5 0 0 0 0 9h.5v2h-.5a6.5 6.5 0 1 1 0-13h7.05l-2.52-3h2.61L21 8l-3.36 4h-2.61Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowRedo;
