import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgStatusStatus = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      fillRule="evenodd"
      d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2Zm-7.9 8.735a8 8 0 1 1-.066 2h4.211l1.665-2.69 3.824 7.597 2.71-4.907h1.814v-2h-2.993l-1.449 2.623-3.726-7.404-2.959 4.781H4.099Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgStatusStatus;
