import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgCommentConvo = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M17 2H1v13h6v-2H3V4h12v9h-4.47L7 17.21V20h5.53l1.68 2h2.61l-3.35-4H9l2.47-3H17V9h4v9h-4v2h6V7h-6V2Z"
    />
  </svg>
);
export default SvgCommentConvo;
