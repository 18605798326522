import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscAiChatbot = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      fillRule="evenodd"
      d="m5.375 3.827-.734 1.122-.378.34-.983.509.983.508.378.341.734 1.122.734-1.122.378-.34.983-.509-.983-.508-.378-.341-.734-1.122Zm-.837-2.374h1.674l1.427 2.18 2.468 1.277v1.776L7.639 7.962l-1.427 2.181H4.538L3.111 7.962.643 6.686V4.91l2.468-1.276 1.427-2.181ZM2 11.469V18h8v-2H4v-4.531H2ZM11 4h9v12h-5.47L9.5 22h2.61l3.36-4H22V2H11v2Z"
      clipRule="evenodd"
    />
    <path
      fill="#2D2E2E"
      fillRule="evenodd"
      d="m13.122 6.837-.898 2.726-2.732.896v.95l2.732.897.898 2.726h.95l.899-2.726 2.732-.897v-.95l-2.732-.896-.899-2.726h-.95Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMiscAiChatbot;
