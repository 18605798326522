import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionLock = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M17 8V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H4v10h2v-8h12v10H4v2h16V8h-3Zm-2 0H9V6c0-1.65 1.35-3 3-3s3 1.35 3 3v2Z"
    />
    <path
      fill="#2D2E2E"
      d="M12 17.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Z"
    />
  </svg>
);
export default SvgActionLock;
