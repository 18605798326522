import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgFileAdd = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path fill="#2D2E2E" d="M13 18v-3h3v-2h-3v-3h-2v3H8v2h3v3h2Z" />
    <path
      fill="#2D2E2E"
      d="M20 6.59 15.41 2H4v20h16V10h-2v10H6V4h8v4h6V6.59Z"
    />
  </svg>
);
export default SvgFileAdd;
