/** @jsx isolateComponent */

import React from 'react';
import { css } from '@emotion/react';

import { isolateComponent, labelStyles } from '@zapier/style-encapsulation';
import { createUniqueCustomProperties } from '../../../utils/createUniqueCustomProperty';
import { Animation, Colors } from '../../../theme';
import { FormLabel } from '../../forms/FormLabel';
import { BooleanInput, BooleanInputType } from './BooleanInput';

export type Props = {
  /**
   * Optional accessibility label for the underlying `input`.
   * Using a `label` tag is generally preferred for more clickable area.
   */
  ariaLabel?: string;

  /**
   * Indicates whether the input is checked.
   */
  checked: boolean;

  /**
   * The content to render for the label.
   */
  children: React.ReactNode;

  /**
   * `id` that is attached to the underlying `input`, used to attach
   * an external `label` to it via the `for` attribute.
   */
  id?: string;

  /**
   * Indicates whether the input is disabled.
   */
  isDisabled?: boolean;

  /**
   * Indicates whether the input is errored.
   */
  isErrored?: boolean;

  /**
   * Indicates whether the input is required.
   */
  isRequired?: boolean;

  /**
   * `name` that is attached to the underlying `input`, used to group
   * multiple `BooleanInput` values together.
   */
  name?: string;

  /**
   * Handler for when the `input` changes between checked and unchecked.
   */
  onChange: (e: React.SyntheticEvent) => void;

  /**
   * The `tabIndex`. The default is almost always correct unless this
   * `LabeledBooleanInput` is being used inside a larger control that manages
   * focus state and keyboard interactions. e.g. a dropdown.
   */
  tabIndex: number;

  /**
   * The type of `input` to render.
   */
  type: BooleanInputType;

  /**
   * `value` that is attached to the underlying `input`, used to differentiate
   * one `input` from another.
   */
  value?: string;
};

const Vars = createUniqueCustomProperties('LabeledBooleanInput', [
  'outlineColor',
]);

const Styles = labelStyles('LabeledBooleanInput', {
  root: css`
    ${Vars.outlineColor}: transparent;
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px;
    align-items: center;
    padding: 10px 8px;
    min-height: 0;

    // This is the outline that renders around the label when
    // it's focused. It's a pseudo element to preserve transparency
    // between the edge of the label and the edge of the outline.
    // It's defined here vs only in ':focus-with' so that it can transition
    // between states.
    &::before {
      content: '';
      position: absolute;
      pointer-events: none;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 2px solid var(${Vars.outlineColor});
      border-radius: 3px;
      transition: ${Animation.transitionValue};
    }

    &:focus-within {
      ${Vars.outlineColor}: ${Colors.blue};

      &[data-errored] {
        ${Vars.outlineColor}: ${Colors.error};
      }
    }
  `,
});

export const LabeledBooleanInput = (props: Props) => {
  return (
    <FormLabel
      isDisabled={props.isDisabled}
      isErrored={props.isErrored}
      isRequired={props.isRequired}
      isSelected={props.checked}
      justifyContent="stretch"
    >
      <span css={Styles.root} data-errored={props.isErrored ? '' : undefined}>
        <BooleanInput
          ariaLabel={props.ariaLabel}
          checked={props.checked}
          disabled={props.isDisabled}
          id={props.id}
          isErrored={props.isErrored}
          name={props.name}
          onChange={props.onChange}
          tabIndex={props.tabIndex}
          type={props.type}
          value={props.value}
        />
        {props.children}
      </span>
    </FormLabel>
  );
};

LabeledBooleanInput.defaultProps = {
  checked: false,
  onChange: () => {},
  tabIndex: 0,
};
