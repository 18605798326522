import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgArrowCodeDots = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M12 10.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM17.25 12a1.25 1.25 0 1 0-2.5 0 1.25 1.25 0 0 0 2.5 0ZM8 10.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM18.27 6h-2.61l5 6-5 6h2.61l5-6-5-6ZM8.34 6H5.73l-5 6 5 6h2.61l-5-6 5-6Z"
    />
  </svg>
);
export default SvgArrowCodeDots;
