import { useEffect } from 'react';

const hasBody = () => {
  return typeof document !== 'undefined' && !!document.body;
};

export const useUnscrollableBody = ({
  shouldAllowScroll,
}: {
  shouldAllowScroll: boolean;
}) => {
  useEffect(() => {
    if (hasBody()) {
      document.body.style.overflow = shouldAllowScroll ? '' : 'hidden';
    }
    return () => {
      if (hasBody()) {
        document.body.style.overflow = '';
      }
    };
  }, [shouldAllowScroll]);
};
