import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscCloudCheck = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m11.5 17.41 5.21-5.2-1.42-1.42-3.79 3.8-1.79-1.8-1.42 1.42 3.21 3.2Z"
    />
    <path
      fill="#2D2E2E"
      d="M19.43 9.54A7.463 7.463 0 0 0 12 3a7.46 7.46 0 0 0-6.63 4h2.42c1-1.22 2.51-2 4.21-2 2.96 0 5.38 2.32 5.49 5.29l.03.71.67.21C19.87 11.73 21 13.26 21 15c0 2.21-1.79 4-4 4H7c-2.21 0-4-1.79-4-4s1.79-4 4-4h2V9H7c-3.31 0-6 2.69-6 6s2.69 6 6 6h10c3.31 0 6-2.69 6-6 0-2.39-1.41-4.51-3.57-5.46Z"
    />
  </svg>
);
export default SvgMiscCloudCheck;
