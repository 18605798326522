import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionAutoRenew = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M19 11v2a5 5 0 0 1-5 5H7.24l2.52-3H7.15l-3.36 4 3.35 4h2.61l-2.51-3H14a7 7 0 0 0 7-7v-2h-2ZM5 11a5 5 0 0 1 5-5h6.76l-2.52 3h2.61l3.36-4-3.36-4h-2.61l2.52 3H10a7 7 0 0 0-7 7v2h2v-2Z"
    />
  </svg>
);
export default SvgActionAutoRenew;
