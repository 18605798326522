import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgCommentAddFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M10 22h2.11l3.36-4H22V2H2v16h8v4ZM8 11V9h3V6h2v3h3v2h-3v3h-2v-3H8Z"
    />
  </svg>
);
export default SvgCommentAddFill;
