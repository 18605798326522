/** @jsx isolateComponent */
import { useEffect, useState } from 'react';
import { css } from '@emotion/react';

import { Img } from '../../display/Img';
import { Animation, Colors, Typography } from '../../../theme';
import { isolateComponent, labelStyles } from '@zapier/style-encapsulation';

export type Props = {
  /**
   * Controls whether `Avatar` is hidden from screenreaders.
   * Useful when it renders alongside redundant content.
   */
  ariaHidden?: boolean;
  /**
   * Background color of the Avatar when no image exists.
   * When omitted, will default to `lava`, `ocean`, or `sun`
   * based on the child number it is.
   * V5 colors are `primary`, `secondary` and `tertiary`
   * V4 colors will automatically map to V5
   */
  color?: 'lava' | 'ocean' | 'sun' | 'primary' | 'secondary' | 'tertiary';
  /** Indicates whether to render the component as a block-level element. */
  isBlock?: boolean;

  /** Indicates whether the Avatar is interactive, i.e. that it lives within an interactive node. */
  isInteractive?: boolean;

  /** Indicates whether the Avatar is selected / clicked on. */
  isSelected?: boolean;

  /** Name of user */
  name: string;

  /** Whether or not to lazy load the underlying image. */
  shouldLazilyLoad?: boolean;

  /** Size of the `Avatar` to display
   *  @deprecated Size 18 deprecated for V5.
   */
  size?: 40 | 25 | 18;

  /** URL to image */
  url?: string | null;
};

const getNameInitials = (name: string): string => {
  const initials = (name || '')
    .trim()
    .split(/\s+/)
    .map((s) => s[0]);
  const firstLetter = initials[0];
  const lastLetter = initials[initials.length - 1];

  if (!firstLetter) {
    return '';
  }

  return initials.length === 1
    ? String(firstLetter)
    : `${firstLetter}${lastLetter}`;
};

const mapV4ColortoV5Color = (color: Props['color']) => {
  let transformedColor;
  switch (color) {
    case 'lava':
      transformedColor = 'primary';
      break;
    case 'ocean':
      transformedColor = 'secondary';
      break;
    case 'sun':
      transformedColor = 'tertiary';
      break;
    default:
      transformedColor = color;
  }
  return transformedColor;
};

const Styles = labelStyles('Avatar', {
  root: (hasError: boolean, props: Props) => [
    css`
      ${Typography.base};
      font-weight: 700;
      line-height: 1;
      user-select: none;
      align-items: center;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      overflow: hidden;
      position: relative;
      border: 1px solid ${Colors.neutral100};
      transition: ${Animation.transitionValue};

      &[data-block] {
        display: flex;
      }

      &[data-interactive] {
        &:hover,
        &:focus {
          border-color: ${Colors.neutral300};
        }
      }

      &[data-selected][data-selected] {
        border-color: ${Colors.blue};
      }
    `,
    !props.url || (props.url && hasError)
      ? css`
          &:nth-of-type(1n),
          &[data-color='lava'][data-color] {
            background-color: ${Colors.neutral800};
            color: ${Colors.neutral100};
          }
          &:nth-of-type(1n),
          &[data-color='primary'][data-color] {
            background-color: ${Colors.neutral800};
            color: ${Colors.neutral100};
          }

          &:nth-of-type(2n),
          &[data-color='ocean'][data-color] {
            background-color: ${Colors.night};
            color: ${Colors.neutral100};
          }
          &:nth-of-type(2n),
          &[data-color='secondary'][data-color] {
            background-color: ${Colors.night};
            color: ${Colors.neutral100};
          }

          &:nth-of-type(3n),
          &[data-color='sun'][data-color] {
            background-color: ${Colors.moss};
            color: ${Colors.neutral100};
          }
          &:nth-of-type(3n),
          &[data-color='tertiary'][data-color] {
            background-color: ${Colors.moss};
            color: ${Colors.neutral100};
          }
        `
      : null,
    css`
      label: -${props.size}x ${props.size};
      height: ${props.size}px;
      width: ${props.size}px;
      font-size: ${Math.floor(
        props.size! * (props.size! > 25 ? 0.35 : 0.45)
      )}px;
    `,
    props.size! >= 40 &&
      css`
        border-width: 2px;
      `,
  ],
  img: css`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  `,
});
/**
 * Displays a user's avatar. Falls back to their initials if an invalid
 * image url or no image url is provided.
 */

export const Avatar = (props: Props) => {
  // Used to fall back to initials if the image fails to load
  const [hasError, setHasError] = useState(false);
  const { name, url, size } = props;
  const initials = name ? getNameInitials(name) : null; // reset hasError if the url changes

  useEffect(() => {
    if (hasError) {
      setHasError(false);
    } // we don't want hasError to trigger this effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.url]);

  return (
    <div
      aria-hidden={props.ariaHidden}
      aria-label={name}
      css={Styles.root(hasError, props)}
      data-block={props.isBlock ? 'true' : undefined}
      data-color={mapV4ColortoV5Color(props.color)}
      data-interactive={props.isInteractive ? 'true' : undefined}
      data-selected={props.isSelected ? 'true' : undefined}
      data-size={props.size}
      data-testid="Avatar"
      role="img"
    >
      {url && !hasError ? (
        <Img
          css={Styles.img}
          alt={name}
          ariaHidden={true}
          height={size} // Use `undefined` for default prop, `null` to opt out.
          onError={() => setHasError(true)}
          src={url}
          width={size}
          loading={props.shouldLazilyLoad ? 'lazy' : 'eager'}
        />
      ) : (
        <span>{initials}</span>
      )}
    </div>
  );
};

Avatar.defaultProps = {
  shouldLazilyLoad: true,
  size: 25,
};
