import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavDashboardFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M2 15v7h5.3l2.54-7H2ZM14.58 2H2v11h8.57l4.01-11ZM9.42 22H22V11h-8.57L9.42 22ZM16.7 2l-2.54 7H22V2h-5.3Z"
    />
  </svg>
);
export default SvgNavDashboardFill;
