import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavHelp = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2Zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Z"
    />
    <path
      fill="#2D2E2E"
      d="M12.01 18.25a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM12.36 6.13c-1.18-.01-2.19.3-2.98.67v1.98c.62-.4 1.65-.9 2.92-.9h.05c1.3.01 2.07.72 2.14 1.38.08.88-.96 1.67-2.65 2.01l-.7.14.01 3.08h1.75v-1.68c2.64-.76 3.47-2.38 3.34-3.73-.18-1.68-1.81-2.93-3.88-2.95Z"
    />
  </svg>
);
export default SvgNavHelp;
