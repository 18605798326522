import { css } from '@emotion/react';

export const isHtmlNode = (type: any) => {
  return typeof type === 'string';
};

const blockNodes = new Set([
  'address',
  'article',
  'aside',
  'blockquote',
  'details',
  'dialog',
  'dd',
  'div',
  'dl',
  'dt',
  'fieldset',
  'figcaption',
  'figure',
  'footer',
  'form',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'header',
  'hgroup',
  'hr',
  'li',
  'main',
  'nav',
  'ol',
  'p',
  'pre',
  'section',
  'table',
  'ul',
]);

export const isBlockNode = (type: any) => {
  return blockNodes.has(type);
};

const svgNodes = new Set([
  'path',
  'rect',
  'ellipse',
  'circle',
  'g',
  'defs',
  'clipPath',
  'linearGradient',
  'stop',
  'animate',
]);

export const isSvgNode = (type: any) => {
  return svgNodes.has(type);
};

const clickableNodes = new Set(['a', 'label', 'button']);

export const isClickableNode = (type: any) => {
  return clickableNodes.has(type);
};

// `all: unset` is a very strong approach to a style reset. It removes (almost)
// every possible CSS property, including ones added by the User Agent (UA)
// styles (browser default CSS). Some of these, like the Firefox and Safari
// rules mentioned below, are critical for making things on screen look correct.
// My suggestion is to use `all: revert` which turns off all additional styles,
// but not UA styles. Combined with turning off padding/margin/border, this is
// most of the way to a "CSS reset" that's good enough to build on. Other rules
// could be borrowed from normalize.css
//
// https://necolas.github.io/normalize.css/
// https://zapierorg.atlassian.net/browse/ZDS-590
export const buildResetStyles = (nodeType: any) => {
  return css`
    all: unset;
    box-sizing: border-box;
    /* Fix Safari 13 issue with 'color' and 'all: unset'
     * (https://bugs.webkit.org/show_bug.cgi?id=158782).
     */
    -webkit-text-fill-color: currentColor;
    /* Fix Firefox textarea issue with 'all: unset' */
    ${nodeType === 'textarea' ? 'white-space: pre-wrap;' : ''}
    ${nodeType === 'strong' ? 'font-weight: bold;' : ''}
    ${nodeType === 'em' ? 'font-style: italic;' : ''}
    ${isBlockNode(nodeType) ? 'display: block;' : ''}
    ${isClickableNode(nodeType) ? 'cursor: pointer;' : ''}
  `;
};
