import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscPinFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M12 2c-4.41 0-8 3.59-8 8 0 4.55 3.28 7.72 8 11.83 4.8-4.18 8-7.3 8-11.83 0-4.41-3.59-8-8-8Zm0 10.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5Z"
    />
  </svg>
);
export default SvgMiscPinFill;
