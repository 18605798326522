import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavBell = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M6.72 8.9C6.93 6.15 9.25 4 12 4c2.75 0 5.07 2.15 5.28 4.9l.51 6.83L18.86 17H2.53l-.03.04V19h7a2.5 2.5 0 0 0 5 0h7v-1.96l-1.77-2.1-.46-6.19C18.99 4.96 15.79 2 12 2 8.21 2 5.01 4.96 4.73 8.75l-.46 6.19-.06.06h2.05l.46-6.1Z"
    />
  </svg>
);
export default SvgNavBell;
