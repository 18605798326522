import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgArrowDropdown = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m12 3.69-5 4.2v2.61l5-4.19 5 4.19V7.89l-5-4.2ZM7 13.5v2.61l5 4.2 5-4.2V13.5l-5 4.19-5-4.19Z"
    />
  </svg>
);
export default SvgArrowDropdown;
