import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscEye = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M12 4C6.87 4 2.7 7.54.59 12c.14.3.43.98 1.2 2.1C1.88 13.89 4.88 6 12 6c5.31 0 8.34 4.57 9.17 6-.82 1.44-3.84 6-9.17 6-3.77 0-6.39-2.31-7.89-4.16-.43.86-.72 1.63-.88 2.07C5.06 17.9 7.94 20 12 20c5.13 0 9.3-3.54 11.41-8-2.1-4.44-6.25-8-11.41-8Z"
    />
    <path
      fill="#2D2E2E"
      d="M16.5 12.09c0-2.48-2.02-4.5-4.5-4.5s-4.5 2.02-4.5 4.5 2.02 4.5 4.5 4.5 4.5-2.02 4.5-4.5Zm-7 0a2.5 2.5 0 0 1 5 0 2.5 2.5 0 0 1-5 0Z"
    />
  </svg>
);
export default SvgMiscEye;
