/** @jsx isolateComponent */
import React, { Component, useState, useEffect } from 'react';

import { Animation, ColorName, Colors } from '../../../theme';
import {
  isolateComponent,
  labelStyles,
  withModifier,
} from '@zapier/style-encapsulation';

import { asyncImportSVG } from './asyncImportSVG';
import type { IconName } from './icons';

export type Props = {
  /**
   * Accessible label for the icon. Optional since in many cases the
   * parent node will already have an `ariaLabel` attached.
   */
  ariaLabel?: string;
  /**
   * Acessible attribute to hide the icon when the only role is presentation.
   * Optional since parent node can accept click events and the icon can have a aria-label. Default is true.
   */
  ariaHidden?: boolean;
  /**
   * Indicates whether `pointer-events` are accepted on this component.
   * `Icon` nodes can interfere with event firing, and disabling
   * `pointer-events` can prevent the interference. Generally the `Icon`
   * shouldn't accept events itself, but rather its parent should for
   * accessibility purposes.
   */
  canAcceptPointerEvents?: boolean;
  /**
   * Icon fill color. If this is not specified, the color will be inherited value of the CSS `color` property.
   */
  color?: ColorName;
  /**
   * Indicates whether the `Icon` is `display: block`, which removes
   * `line-height` related layout from it.
   */
  isBlock?: boolean;
  /**
   * String representing the name of icon to display. If the name is invalid, a square will be rendered instead.
   */
  name: IconName;
  /**
   * Size of the icon. This value will be the width and height of
   * the icon, since it is a square.
   */
  size?: number | string;
};

const sanitizeClassName = (size: string | number) => {
  return `${size}`.replace(/[^a-z0-9]/gi, '-');
};

const Styles = labelStyles('Icon', {
  root: (props: Props) => [
    {
      label: `-${props.name}`,
      display: 'inline-block',
      fill: 'currentColor',

      // Though this component doesn't manage active state on its own such that
      // transition styles are necessary, its parent might. When a parent is
      // toggling this component's `color` prop, we need to animate that
      // consistently with our other transitions.
      transitionProperty: 'fill',
      transitionTimingFunction: Animation.transitionTimingFunction,
      transitionDuration: Animation.transitionDuration,

      '> svg': {
        display: 'block',
        height: 'inherit',
        width: 'inherit',
      },
      '*': {
        fill: 'inherit',
      },
    },

    !props.canAcceptPointerEvents &&
      withModifier('disable-pointer-events', {
        pointerEvents: 'none',
      }),

    props.isBlock &&
      withModifier('block', {
        display: 'block',
      }),

    withModifier(sanitizeClassName(`${props.size}x${props.size}`), {
      height: props.size,
      width: props.size,
    }),
    props.color &&
      withModifier(props.color, {
        fill: Colors[props.color],
      }),
  ],
});

// Renders when the icon is loading
const LoadingIcon = () => (
  <svg
    aria-label="Loading icon"
    data-is-loading-icon="true"
    viewBox="0 0 24 24"
  >
    {/* Nothing to see here, just an empty space. */}
  </svg>
);

// Renders in the event that an invalid icon name is used.
const MissingIcon = () => (
  <svg
    aria-label="Could not load icon"
    data-is-missing-icon="true"
    viewBox="0 0 24 24"
  >
    <rect height="24" width="24" x="0" y="0" />
  </svg>
);

type IconErrorBoundaryState = {
  didFailToLoad: boolean;
};

type IconErrorBoundaryProps = {
  children: React.ReactNode;
};

class IconErrorBoundary extends Component<
  IconErrorBoundaryProps,
  IconErrorBoundaryState
> {
  state = {
    didFailToLoad: false,
  };

  static getDerivedStateFromError() {
    return {
      didFailToLoad: true,
    };
  }

  render() {
    return this.state.didFailToLoad ? <MissingIcon /> : this.props.children;
  }
}

declare global {
  namespace NodeJS {
    interface Process {
      readonly browser: boolean;
    }
  }
}

// Stores which icons have been loaded and their component content.
const iconsCache: { [k: string]: React.ReactNode } = {};

// Handle loading across environments. Some environments return the raw
// contents of the SVG file as a string (e.g. SSR) while others turn the SVG
// into a component via a webpack loader (e.g. Styleguidist).

const loadSvgAsComponent = async (name: string) => {
  if (iconsCache[name]) {
    return iconsCache[name];
  }
  if (!process.browser) {
    return LoadingIcon;
  }
  try {
    const svg = await asyncImportSVG(name);
    const Svg = svg.default;
    // If `Svg` is a string then it still needs to be componentized.
    // If it isn't a string, assume it's already been componentized.
    const props =
      typeof Svg === 'string'
        ? {
            dangerouslySetInnerHTML: { __html: Svg },
          }
        : {
            children: <Svg />,
          };
    iconsCache[name] = () => <React.Fragment {...props} />;
    return iconsCache[name];
  } catch (error) {
    const message = `Could not load Icon with name of ${name}`;
    throw new Error(message);
  }
};

/**
 * Renders an SVG icon.
 *
 * All icons are normalized in size, and should be interchangeable without
 * icon-specific alignment tweaks.
 *
 * Most icons are drawn from Google's Material Design icon library.
 */
export const Icon = (props: Props) => {
  const [IconSvg, setIconSvg] = useState<React.FC>(() => LoadingIcon);
  useEffect(() => {
    // add a flag to make sure we're still mounted
    let isMounted = true;

    loadSvgAsComponent(props.name)
      // @ts-ignore
      .then((SvgComponent: React.FC) => {
        // only set state if we're still mounted
        if (isMounted) {
          setIconSvg(() => SvgComponent);
        }
      })
      .catch(() => {});

    // return an unmount handler to toggle the flag
    return () => {
      isMounted = false;
    };
  }, [props.name]);
  return (
    <span
      aria-hidden={props.ariaHidden ? 'true' : undefined}
      aria-label={props.ariaLabel}
      css={Styles.root(props)}
      data-testid="iconContainer"
    >
      <IconErrorBoundary>
        <IconSvg />
      </IconErrorBoundary>
    </span>
  );
};

Icon.defaultProps = {
  canAcceptPointerEvents: true,
  size: 30,
  ariaHidden: true,
};
