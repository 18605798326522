import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgPersonGroupFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M20.7 13h-7.58l-2.55 7h8.46l-.73-2h-2.78l.73-2h3.45l1.45 4h2.1l-2.55-7ZM3.3 13 .75 20h7.66l2.55-7H3.3ZM7.5 11.5a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM17 11.5a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
    />
  </svg>
);
export default SvgPersonGroupFill;
