/** @jsx isolateComponent */

import {
  isolateComponent,
  labelStyles,
  withModifier,
} from '@zapier/style-encapsulation';
import { Link } from '../../navigation/Link';
import type { LinkProps } from '../../navigation/Link';
import { Colors, Typography } from '../../../theme';

export type Props = {
  /**
   * Optional label for the menu item.
   */
  ariaLabel?: string;
  /**
   * What to render in the `MenuItem`.
   */
  // What to render within `MenuItem`.
  children: React.ReactNode;
  /** A Component to pass into the `MenuItem` if you'd like to render a different kind of link,
   * like a Router Link, for example
   * Only if the `Tag` is a link
   */
  component?: LinkProps['component'];
  /**
   * Indicates whether the item should be disabled
   */
  disabled?: boolean;
  /**
   * Optional property which, when supplied, will render `MenuItem` as an `a` tag.
   */
  href?: string;
  /**
   * Whether the item is currently selected. Should be used in circumstances
   * where items are ephemerally chosen.
   */
  isSelected?: boolean;
  /**
   * Optional `id` so the node can be referenced.
   */
  id?: string;
  /**
   * The optional a11y role for the item. `menuitem` is the semantic equivalent of `<li>`
   *  and should be used when the parent has a role of 'menu',
   *  while 'option' is the semantic equivalent of `<option>`and
   *  should be used when the parent has the role of 'listbox'.
   */
  role?: 'option' | 'menuitem';
  /**
   * Optional click handler for the `MenuItem`.
   */
  onClick?: (event: React.MouseEvent) => void;
};

const Styles = labelStyles('MenuItem', {
  content: (props: Props) => [
    {
      ...Typography.paragraph3Medium,
      alignItems: 'center',
      borderRadius: 3,
      color: Colors.neutral700,
      display: 'flex',
      height: 44,
      lineHeight: '44px',
      padding: '0 10px',
      textDecoration: 'none',
      outline: 'none',
      width: '100%',

      // Bump specificity with `[class]` since these states
      // should have precedence over `isSelected`.
      '&:not([disabled]):hover, &:not([disabled]):focus': {
        backgroundColor: Colors.blue10,
        color: Colors.blue,
        fontWeight: props.isSelected ? 600 : 500,
      },

      '&[disabled]': {
        color: Colors.neutral500,
        cursor: 'not-allowed',
      },
    },

    props.isSelected &&
      withModifier('selected', {
        backgroundColor: Colors.neutral200,
        fontWeight: 600,
      }),
  ],

  text: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

export const MenuItem = (props: Props) => {
  const Tag = props.href && !props.disabled ? Link : 'button';
  const isLink = Tag === Link;
  const ariaLabel = props.isSelected
    ? `${props.ariaLabel || props.children}, selected`
    : props.ariaLabel;
  const conditionalProps = isLink
    ? {
        ariaLabel,
        href: props.href,
      }
    : {
        'aria-label': ariaLabel,
        type: 'button' as 'button',
        disabled: props.disabled,
      };
  const liRole = props.role ? { role: 'none' } : null;

  return (
    <li {...liRole}>
      <Tag
        {...conditionalProps}
        component={isLink ? props.component : undefined}
        // @ts-ignore doesn't understand Link[color=null]
        color={isLink ? null : undefined}
        css={Styles.content(props)}
        href={props.href}
        onClick={props.onClick}
        role={props.role}
      >
        <span css={Styles.text}>{props.children}</span>
      </Tag>
    </li>
  );
};
