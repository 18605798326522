import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscFire = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M3.5 14c0 4.69 3.81 8.5 8.5 8.5 4.69 0 8.5-3.81 8.5-8.5 0-3.37-1.47-5.58-2.65-7.56C16.85 8.08 16.08 10 14 10c-1.49 0-2.93-1.15-2.98-2.92-1.03.98-1.38 1.59-1.59 1.9C10.37 11.16 12.39 12 14 12c1.44 0 2.73-.59 3.71-1.69.52 1.22.79 2.46.79 3.69 0 3.58-2.92 6.5-6.5 6.5S5.5 17.58 5.5 14c0-2.14.86-6.91 8.15-11.4l-1.57-1.31c-2 1.2-8.58 5.7-8.58 12.71Z"
    />
  </svg>
);
export default SvgMiscFire;
