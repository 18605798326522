import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgPersonAccount = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M8.262 9.75a3.75 3.75 0 1 0 7.5 0 3.75 3.75 0 0 0-7.5 0Zm5.5 0a1.75 1.75 0 1 1-3.499 0 1.75 1.75 0 0 1 3.5 0Zm-8.11 7.08A7.93 7.93 0 0 1 4.012 12a8 8 0 0 1 15.59-2.5h2.08a10.001 10.001 0 1 0-9.67 12.5h.5v-2c-.166.01-.333.01-.5 0a8 8 0 0 1-4.8-1.62l.5-1.38h4.8v-2h-6.2l-.66 1.83Z"
    />
    <path
      fill="#2D2E2E"
      fillRule="evenodd"
      d="M21.492 14.028c0 .421.216.81.567 1.02l.941.562v1.784l-.945.562c-.352.211-.568.6-.568 1.021l.005 1.133-1.482.89-.94-.571a1.097 1.097 0 0 0-1.135 0l-.941.571-1.486-.89.005-1.133c0-.421-.216-.81-.568-1.02L14 17.393V15.61l.95-.566c.351-.211.567-.6.567-1.021l-.004-1.133 1.481-.89.941.576c.351.21.783.21 1.135 0l.94-.571 1.486.89-.004 1.133ZM18.5 17.906a1.406 1.406 0 1 0 0-2.812 1.406 1.406 0 0 0 0 2.812Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPersonAccount;
