/** @jsx isolateComponent */
import { isolateComponent } from '@zapier/style-encapsulation';

import { Props as BaseButtonProps, BaseButton } from './BaseButton';

export type Props = Omit<BaseButtonProps, 'isPill'> & {
  color?:
    | 'danger'
    | 'primary'
    | 'primary-alt'
    | 'primary-dark'
    | 'secondary'
    | 'secondary-dark'
    | 'tertiary';
};

/**
 * Classic button component. Use when providing calls to action to users.
 */
export function Button({
  color = 'primary',
  size = 'medium',
  ...props
}: Props) {
  return <BaseButton color={color} size={size} {...props} />;
}
