import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgRatingThumbsUp = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M19 7h-4V4a2 2 0 0 0-2-2h-1.43L6 8.64V16h2V9.36L12.5 4h.5v5h6a1 1 0 0 1 1 1v2.68L15.53 18H6v2h10.47L22 13.4V10a3 3 0 0 0-3-3ZM2 20h2V9H2v11Z"
    />
  </svg>
);
export default SvgRatingThumbsUp;
