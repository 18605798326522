import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgArrowBigDown = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m11 16.86-6-5.04v2.62l7 5.86 7-5.86v-2.62l-6 5.04V4h-2v12.86Z"
    />
  </svg>
);
export default SvgArrowBigDown;
