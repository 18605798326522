import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgFormToggle = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      fillRule="evenodd"
      d="M9 8h6a4 4 0 0 1 0 8H9a4 4 0 0 1 0-8Zm-6 4a6 6 0 0 1 6-6h6a6 6 0 0 1 0 12H9a6 6 0 0 1-6-6Zm15 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFormToggle;
