type CookiesObj = {
  [key: string]: string;
};

export const readCookies = (): CookiesObj => {
  if (typeof document === 'undefined') {
    return {};
  }
  try {
    return (document.cookie || '')
      .split(/;[\s]*/)
      .reduce((accum: CookiesObj, cookie: string) => {
        const indexOfFirstEq = cookie.indexOf('=');
        const cName = cookie.slice(0, indexOfFirstEq);
        const cValue = cookie.slice(indexOfFirstEq + 1);
        accum[decodeURIComponent(cName)] = decodeURIComponent(cValue);
        return accum;
      }, {});
  } catch (e) {
    console.error(`readCookie :: Could not read cookies.`, e);
  }
  return {};
};

export const readCookie = (name: string) => {
  return readCookies()[name];
};
