import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionCamera = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m17.47 4-1.26-1.5H7.79L6.53 4H2v12h2V6h3.47l1.26-1.5h6.54L16.53 6H20v12H2v2h20V4h-4.53Z"
    />
    <path
      fill="#2D2E2E"
      d="M17 11.5c0-2.76-2.24-5-5-5s-5 2.24-5 5 2.24 5 5 5 5-2.24 5-5Zm-8 0c0-1.65 1.35-3 3-3s3 1.35 3 3-1.35 3-3 3-3-1.35-3-3Z"
    />
  </svg>
);
export default SvgActionCamera;
