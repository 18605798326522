/** @jsx isolateComponent */

import React, { useEffect, useRef } from 'react';
import { css, keyframes } from '@emotion/react';
import { Animation, Colors, Shadows, Zindexes } from '../../../theme';
import {
  isolateComponent,
  labelStyles,
  withModifier,
} from '@zapier/style-encapsulation';
import { useClickOutside, useIntersect } from '../../../hooks';

type Props = {
  /**
   * The horizontal alignment of the box relative to the parent element.
   * The vertical position is handled automatically by state.
   */
  align?: 'right' | 'left' | 'stretch';

  /**
   * What to render in the box.
   */
  children: React.ReactNode;

  /**
   * Changes the background color to white (#fff)
   */
  hasWhiteBackground?: boolean;

  /**
   * Override intersect observer's calculated height for box + children since it
   * isn't always accurate
   */
  intersectHeight?: number;

  /** Optionally set max width of box */
  maxWidth?: string;

  /** Optionally set the min width of the box */
  minWidth?: string;

  /**
   * Optional callback for when a click occurs outside of `FloatingBox`.
   * Typically used to unrender `FloatingBox`.
   */
  onClickOutside?: (event: MouseEvent | TouchEvent) => void;
  /**
   * The vertical alignment of the box relative to the parent element.
   * If position is not specified, it is handled automatically by state.
   */

  position?: 'north' | 'south';

  /**
   * Defaults to 3px.
   * This prop is only intended to be used by the AccountsMenu component.
   * See: https://zapier.slack.com/archives/C026EB5GAKZ/p1638557623356400?thread_ts=1638556718.354700&cid=C026EB5GAKZ
   */
  borderRadius?: number;

  /**
   * Defaults to neutral400.
   * This prop is only intended to be used by the AccountsMenu component.
   * See: https://zapier.slack.com/archives/C026EB5GAKZ/p1638885120477000
   */
  borderColor?: string;
};

type DerivedState = {
  position: 'north' | 'south';
};

const menuOpenAnimation = keyframes({
  '0%': {
    transform: 'translateY(-10px)',
    opacity: 0,
  },
  // This "delay" gives enough time for the menu to be measured
  // by `useIntersect` and flip upwards if it needs to, without
  // flickering for a moment while it renders down and is measured.
  '30%': {
    transform: 'translateY(-10px)',
    opacity: 0,
  },
  '100%': {
    transform: 'translateY(0)',
    opacity: 1,
  },
});

const DISTANCE = 'calc(100% + 5px)';

const Styles = labelStyles('FloatingBox', {
  root: (props: Props, derivedState: DerivedState) => [
    css({
      animation: `${Animation.transitionDuration} ease-out ${menuOpenAnimation}`,
      maxWidth: props.maxWidth,
      position: 'absolute',
      minWidth: props.minWidth,
      zIndex: Zindexes.dropdownMenu,
    }),
    props.align === 'left' &&
      withModifier(props.align, {
        left: 0,
      }),
    props.align === 'right' &&
      withModifier(props.align, {
        right: 0,
      }),
    props.align === 'stretch' &&
      withModifier(props.align, {
        left: 0,
        right: 0,
      }),
    derivedState.position === 'south' &&
      withModifier(derivedState.position, {
        top: DISTANCE,
      }),
    derivedState.position === 'north' &&
      withModifier(derivedState.position, {
        bottom: DISTANCE,
      }),
  ],
  box: (backgroundColor: string, borderRadius: number, borderColor: string) =>
    css({
      backgroundColor,
      border: `1px solid ${borderColor}`,
      borderRadius,
      boxShadow: Shadows.elevation20,
    }),
});

function getBorderRadius(borderRadius: number | undefined) {
  return borderRadius || 3;
}

function getBorderColor(borderColor: string | undefined) {
  return borderColor || Colors.neutral400;
}

function getBackgroundColor(hasWhiteBackground = false) {
  return hasWhiteBackground ? Colors.white : Colors.neutral100;
}

/**
 * Box that floats and reorients itself if it renders out of the viewport.
 */
export const FloatingBox = (props: Props) => {
  const threshold = 0.9;
  const node = useRef<HTMLDivElement>(null);
  const [
    setIntersectNode,
    { isBottomIntersecting, isTopIntersecting },
  ] = useIntersect({
    heightOverride: props.intersectHeight,
    once: true,
    threshold,
  });

  const borderRadius = getBorderRadius(props.borderRadius);
  const borderColor = getBorderColor(props.borderColor);
  const backgroundColor = getBackgroundColor(props.hasWhiteBackground);

  const onClickOutside = props.onClickOutside || (() => {});

  const [setClickOutsideNode] = useClickOutside({
    onClickOutside,
  });

  useEffect(() => {
    setIntersectNode(node.current);
    setClickOutsideNode(node.current);
  }, [setClickOutsideNode, setIntersectNode]);

  const derivedState = {
    position:
      props.position ||
      (isTopIntersecting || !isBottomIntersecting ? 'south' : 'north'),
  };

  return (
    <div
      css={Styles.root(props, derivedState)}
      ref={node}
      data-testid="floating-box"
    >
      <div css={Styles.box(backgroundColor, borderRadius, borderColor)}>
        {props.children}
      </div>
    </div>
  );
};

FloatingBox.defaultProps = {
  align: 'left',
};
