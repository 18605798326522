import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavCentral = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <g fill="#2D2E2E" clipPath="url(#navCentral_svg__a)">
      <path d="M3 3h4.5v4.5H3zM16.5 3H21v4.5h-4.5zM16.5 16.5H21V21h-4.5zM3 16.5h4.5V21H3zM7.5 7.5h9v9h-9z" />
    </g>
    <defs>
      <clipPath id="navCentral_svg__a">
        <path fill="#fff" d="M3 3h18v18H3z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgNavCentral;
