type StylesObject = { [key: string]: any };

type StylesEntry = [string, any];

const addLabelToStyles = (label: string, styles: any) => {
  if (typeof styles === 'function') {
    return (...args: any[]) => {
      return [{ label }, styles(...args)];
    };
  }
  return [{ label }, styles];
};

// Takes a `block` string and an `allStyles` object and iterates
// through all of the values in `allStyles`, adding a `label`
// key to them so emotion labels the classes nicely.
export const labelStyles = (
  block: string,
  allStyles: StylesObject
): StylesObject => {
  return (
    Object.entries(allStyles)
      .map(([name, styles]: StylesEntry) => {
        const label = name.startsWith('root') ? block : `${block}__${name}`;
        return [name, addLabelToStyles(label, styles)];
      })
      // @ts-ignore
      .reduce((mapped: StylesObject, [name, styles]: StylesEntry) => {
        mapped[name] = styles;
        return mapped;
      }, {})
  );
};
