import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscHeadset = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M2.52 12a14.3 14.3 0 0 0 2.08 6.61c.14.24.44.72.44.72l.51.82 4.74-2.73L7.9 12H2.52Zm3.72 5.45A13.27 13.27 0 0 1 4.82 14H6.6l1.14 2.58-1.5.87ZM12 2a9.44 9.44 0 0 0-9.4 8h2A7.45 7.45 0 0 1 12 4a7.4 7.4 0 0 1 7.5 7.28V12h-3.37l-2.39 5.42 3 1.71L15.46 21H11v2h5.54l2.86-4.39a14.119 14.119 0 0 0 2.1-7.33A9.4 9.4 0 0 0 12 2Zm5.76 15.45-1.48-.87L17.42 14h1.76a13.476 13.476 0 0 1-1.42 3.45Z"
    />
  </svg>
);
export default SvgMiscHeadset;
