import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgAlertTriangle = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M13 9h-2v5h2V9ZM12 17.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Z"
    />
    <path
      fill="#2D2E2E"
      d="M12 1.03 2.6 17h2.32L12 4.97 20.25 19H1.43L.25 21h23.5L12 1.03Z"
    />
  </svg>
);
export default SvgAlertTriangle;
