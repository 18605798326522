import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavCanvas = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2d2e2e"
      fillRule="evenodd"
      d="M8 2H2v6h6zM6 4H4v2h2zm3.5 0H22v9h-6v-2h4V6H9.5zM22 16h-6v6h6zm-7.5-1L9 12l5.5-3zm0 3h-2v2h2zM9 18h2v2H9zm-1.5 0h-2v2h2zM2 18h2v2H2zm2-3.5H2v2h2zM2 11h2v2H2zm5.5 0h-2v2h2z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNavCanvas;
