import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionZapDetailsFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path fill="#2D2E2E" d="M15.5 2.1v4.4h4.4l-4.4-4.4Z" />
    <path
      fill="#2D2E2E"
      fillRule="evenodd"
      d="M14 2v6h6v14H4V2h10Zm1.559 11.13-4.809 5.728v-4.062H8.442l4.808-5.73v4.063h2.309Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgActionZapDetailsFill;
