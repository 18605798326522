import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscPie = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M12 2C6.11 2 1.46 7.1 2.05 13h7.81l-3.72 4.43A8.25 8.25 0 0 1 4.59 15H2.46c1.28 4.05 5.07 7 9.54 7 5.51 0 10-4.49 10-10S17.51 2 12 2Zm-7.93 9C4.56 7.06 7.93 4 12 4c1.6 0 3.08.48 4.33 1.28L11.53 11H4.07ZM12 20c-1.6 0-3.08-.48-4.33-1.28L17.86 6.57A7.979 7.979 0 0 1 20 12c0 4.41-3.59 8-8 8Z"
    />
  </svg>
);
export default SvgMiscPie;
