import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscWallet = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M5 6c-.41 0-1-.32-1-1V4h16V2H2v17c0 1.65 1.35 3 3 3h17V6H5Zm15 14H5c-.55 0-1-.45-1-1V7.83c.32.11.66.17 1 .17h15v12Z"
    />
    <path
      fill="#2D2E2E"
      d="M16 16.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Z"
    />
  </svg>
);
export default SvgMiscWallet;
