/** @jsx isolateComponent */

import { css } from '@emotion/react';

import { isolateComponent, labelStyles } from '@zapier/style-encapsulation';
import { Colors, Shadows, Typography } from '../../../theme';
import { createUniqueCustomProperties } from '../../../utils/createUniqueCustomProperty';

type Props = {
  /**
   * The content to render. Be sure to handle scrolling if your content
   * won't fit on small screens.
   */
  children: React.ReactNode;
  /**
   * The rendered close button provided by `Modal`.
   */
  closeButton?: React.ReactNode;
  /**
   * The maximum width of `ModalContent`. Any valid CSS value can be used.
   * Only strings are supported to avoid special handling for numbers, so
   * make sure to provide a unit such as `px`.
   */
  maxWidth?: string;
};

const Vars = createUniqueCustomProperties('ModalMediaContent', [
  'spacing',
  'footerMarginBottom',
]);

const Styles = labelStyles('ModalContent', {
  root: css`
    ${Vars.spacing}: 20px;
    ${Vars.footerMarginBottom}: 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 70px);
    background-color: ${Colors.neutral100};
    padding: var(${Vars.spacing});
    // 40px handles bottom toolbar in iOS Safari
    margin: 10px 10px calc(10px + 40px);
    border-radius: 5px;
    border: 1px solid ${Colors.neutral300};
    box-shadow: ${Shadows.elevation5};

    @media screen and (min-width: 650px) {
      // Effectively tighten the bottom padding of ModalContent
      // by pulling it up via margin.
      ${Vars.footerMarginBottom}: -10px;
      min-width: 550px;
      max-width: 970px;
      max-height: 650px;
      padding: calc(var(${Vars.spacing}) * 2);
      margin: 30px;
    }
  `,
  closeButton: css`
    position: absolute;
    top: 8px;
    right: 8px;
  `,
  modalContentHeader: css`
    ${Typography.paragraph1Bold};
    margin-bottom: var(${Vars.spacing});
    color: ${Colors.neutral800};
    // Protect header from close button.
    padding-right: 20px;
    &[data-align='center'] {
      // Ensure header is centered with the padding-right above.
      padding-left: 20px;
      text-align: center;
    }
  `,
  modalContentBody: css`
    color: ${Colors.neutral700};
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    // Support scrolling the child.
    min-height: 0;

    &[data-size='small'] {
      ${Typography.paragraph3};
      color: ${Colors.neutral700};
    }

    &[data-size='medium'] {
      ${Typography.paragraph3};
    }

    &[data-align='center'] {
      text-align: center;
    }

    &[data-scrollable] {
      // This is the gradient fader that renders over content
      // to help indicate that it's scrollable.
      &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        height: 46px;
        pointer-events: none;
        background: linear-gradient(0deg, #fffdf9 0%, #fff0 75%);
      }
    }
  `,
  modalContentBodyInner: css`
    flex: 1;

    &[data-scrollable] {
      overflow: auto;
      // Add padding so end content isn't covered by the gradient fader.
      // Rebrand linear-gradient is a bit taller
      padding-bottom: 26px;
    }
  `,
  modalContentFooter: css`
    display: flex;
    flex-wrap: wrap;
    gap: 10px 20px;
    justify-content: center;
    margin: var(${Vars.spacing}) auto var(${Vars.footerMarginBottom});

    // Always a little 🤢 to use these selectors, but it helps to ensure
    // that they stretch full width on small screens. The attribute qualifiers
    // help with specificity.
    > *[class],
    > *[style] {
      flex: 1 1 auto;
    }
  `,
});

export const ModalContentHeader = (props: {
  align?: 'center';
  children: React.ReactNode;
}) => {
  return (
    <header css={Styles.modalContentHeader} data-align={props.align}>
      {props.children}
    </header>
  );
};

export const ModalContentBody = (props: {
  align?: 'center';
  size?: 'small' | 'medium';
  children: React.ReactNode;
  scrollable?: boolean;
}) => {
  const { size = props.scrollable ? 'small' : 'medium' } = props;
  return (
    <div
      css={Styles.modalContentBody}
      data-align={props.align}
      data-scrollable={props.scrollable === true ? '' : undefined}
      data-size={size}
    >
      <div
        css={Styles.modalContentBodyInner}
        data-scrollable={props.scrollable === true ? '' : undefined}
        tabIndex={props.scrollable === true ? 0 : undefined}
      >
        {props.children}
      </div>
    </div>
  );
};

export const ModalContentFooter = (props: { children: React.ReactNode }) => {
  return <footer css={Styles.modalContentFooter}>{props.children}</footer>;
};

export const ModalContent = (props: Props) => {
  const style = {
    maxWidth: props.maxWidth,
  };
  return (
    <div css={Styles.root} style={style}>
      {props.children}
      {props.closeButton && (
        <div css={Styles.closeButton}>{props.closeButton}</div>
      )}
    </div>
  );
};
