import { readCookie } from './readCookie';

// OneTrust's scripts set this global variable after they load.
declare global {
  interface Window {
    OnetrustActiveGroups?: string;
  }
}

// These cookie category codes are provided by OneTrust
// and map onto corresponding category names.
export type CookieCategoryCode = 'C0001' | 'C0002' | 'C0004';

export type CookieCategoryName = 'essential' | 'analytics' | 'marketing';

type CategoryNameToCodeMap = {
  [key in CookieCategoryName]: CookieCategoryCode;
};

type ActiveCategoriesByName = {
  [key in CookieCategoryName]: boolean;
};

const categoryCodes: Array<CookieCategoryCode> = ['C0001', 'C0002', 'C0004'];

const categoryNameToCodeMap: CategoryNameToCodeMap = {
  essential: 'C0001',
  analytics: 'C0002',
  marketing: 'C0004',
};

type CategoryCodeToNameMap = {
  [key in CookieCategoryCode]: CookieCategoryName;
};

type ActiveCategoriesByCode = {
  [key in CookieCategoryCode]: boolean;
};

const categoryCodeToNameMap = Object.entries(categoryNameToCodeMap).reduce(
  (accum, entry) => {
    // Didn't destructure these because using `as` worked better for typing.
    const name = entry[0] as CookieCategoryName;
    const code = entry[1] as CookieCategoryCode;
    accum[code] = name;
    return accum;
  },
  {} as CategoryCodeToNameMap
);

// Read consent values from `OptanonConsent` cookie. This is used as
// a fallback for when the `OnetrustActiveGroups` global variable
// doesn't exist yet because the scripts that create it haven't
// finished loading and executing. The benefit of using the `OptanonConsent`
// cookie as a fallback is that it's available immediately on page
// load once it's been set at least once. This means that on subsequent
// page loads the consented categories can be read even when `OnetrustActiveGroups`
// doesn't exist yet, which is useful for some app-level code that only
// executes a single time on boot and therefore can't react to a hook
// like `useCookieConsent` which would occur long after the app's initial boot.
const readCookieCategoriesFromCookie = () => {
  const optanonConsent = readCookie('OptanonConsent');
  const groups = new URLSearchParams(optanonConsent).get('groups') || '';
  const activeCodes = groups
    .split(',')
    .map((group) => group.split(':'))
    // Filter out non-existent groups, which occurs when `OptanonConsent`
    // hasn't yet been set.
    .filter(([groupCode]) => !!groupCode)
    // Filter out falsey groups that the user hasn't consented to.
    .filter(([, isConsented]) => isConsented === '1')
    .map(([groupCode]) => groupCode);
  return activeCodes;
};

export const getActiveCategoryCodes = () => {
  const activeGroups =
    (typeof window !== 'undefined' ? window.OnetrustActiveGroups : '') || '';
  // "Live" codes from the `OnetrustActiveGroups` global variable.
  const liveActiveCodes = activeGroups.split(/\s*,\s*/).filter(Boolean);
  // See note at `readCookieCategoriesFromCookie` definition.
  const activeCodes =
    liveActiveCodes.length > 0
      ? liveActiveCodes
      : readCookieCategoriesFromCookie();
  // Use `Set` to dedupe array, being sure that C0001 (essential category)
  // always exists within it.
  return [...new Set(['C0001', ...activeCodes])].sort() as Array<
    CookieCategoryCode
  >;
};

export const readCookieCategories = () => {
  const activeCodes = getActiveCategoryCodes();
  const byCode: ActiveCategoriesByCode = categoryCodes.reduce(
    (accum, catCode) => {
      accum[catCode] = activeCodes.includes(catCode);
      return accum;
    },
    {} as ActiveCategoriesByCode
  );
  const byName: ActiveCategoriesByName = Object.entries(byCode).reduce(
    (accum, [categoryCode, isActive]) => {
      const categoryName =
        categoryCodeToNameMap[categoryCode as CookieCategoryCode];
      accum[categoryName] = isActive;
      return accum;
    },
    {} as ActiveCategoriesByName
  );
  return { byCode, byName };
};

// Indicates whether the cookie categorized as `category` can be
// written based on user consent.
export const canWriteCookieCategory = (category: CookieCategoryName) => {
  // In case `OnetrustActiveGroups` hasn't been set yet, still allow
  // essential cookies to be set.
  if (category === 'essential') {
    return true;
  }
  const cookieCategories = readCookieCategories();
  return !!cookieCategories.byName[category];
};
