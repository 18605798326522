import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionResendFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M18.5 12V9.54L1 1.27l1.46 8.31L9 12.67v2.21L2.86 12 1 22.73 13.09 17a6 6 0 0 1 5.41-5Zm3.75 6A3.25 3.25 0 1 1 19 14.75h1.93l-1.47 1.75h2l2.03-2.5-2.1-2.5h-2l1.47 1.75H19A4.75 4.75 0 1 0 23.75 18h-1.5Z"
    />
  </svg>
);
export default SvgActionResendFill;
