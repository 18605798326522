import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgTeam = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m12.47 5-1.68-2H2v14h2V5h5.86l1.67 2H20v12H2v2h20V5h-9.53Z"
    />
    <path
      fill="#2D2E2E"
      d="M10.25 12.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM15.75 12.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM14.91 16h3.73l-.73-2h-3.73l.73 2ZM7.36 16h5.96l-.73-2h-4.5l-.73 2Z"
    />
  </svg>
);
export default SvgTeam;
