import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgAppApiBuilder = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      fillRule="evenodd"
      d="m9 1.523-7 2.8v15.354l7 2.8V1.523Zm-5 16.8V5.677l3-1.2v15.046l-3-1.2ZM15 1.523l7 2.8v15.354l-7 2.8V1.523Zm5 16.8V5.677l-3-1.2v15.046l3-1.2Z"
      clipRule="evenodd"
    />
    <path fill="#2D2E2E" d="M13.5 19.68h-3v-2h3v2ZM10.5 6.32h3v-2h-3v2Z" />
  </svg>
);
export default SvgAppApiBuilder;
