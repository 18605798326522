import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgFormXCircle = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M19.07 4.93a10 10 0 0 0-14.15 0c-3.9 3.9-3.9 10.24 0 14.14a10 10 0 0 0 14.15 0c3.91-3.9 3.91-10.24 0-14.14Zm-2.83 9.81-1.41 1.41L12 13.33l-2.83 2.83-1.41-1.41 2.83-2.83-2.83-2.84 1.41-1.41L12 10.5l2.83-2.83 1.41 1.41-2.83 2.83 2.83 2.83Z"
    />
  </svg>
);
export default SvgFormXCircle;
