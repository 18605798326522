import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgCommentFilled = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path fill="#2D2E2E" d="M17 7H7v2h10V7ZM15 11H7v2h8v-2Z" />
    <path
      fill="#2D2E2E"
      d="M2 2v16h8v-2H4V4h16v12h-5.47L9.5 22h2.61l3.36-4H22V2H2Z"
    />
  </svg>
);
export default SvgCommentFilled;
