import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionShareFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M18 10a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM18 14c-1.2 0-2.27.54-3 1.38l-3.63-1.82-.89 1.79 3.62 1.81C13.51 19.86 15.67 22 18 22c2.21 0 4-1.79 4-4s-1.79-4-4-4ZM13.52 9.36l-.89-1.79L9 9.38C8.27 8.54 7.2 8 6 8c-2.21 0-4 1.79-4 4s1.79 4 4 4c2.73 0 4.4-2.52 3.91-4.84l3.61-1.8Z"
    />
  </svg>
);
export default SvgActionShareFill;
