import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavConnectedAssetsFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path fill="#2D2E2E" d="M21 7a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" />
    <path
      fill="#2D2E2E"
      fillRule="evenodd"
      d="M17 21a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM7 11a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
      clipRule="evenodd"
    />
    <path
      fill="#2D2E2E"
      d="M10.452 14.978a4 4 0 1 1-1.41-1.418l3.423-3.423 1.414 1.414-3.427 3.427Z"
    />
  </svg>
);
export default SvgNavConnectedAssetsFill;
