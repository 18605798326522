import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgArrowLineLeft = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M6 18h2V6H6v12ZM18.34 6h-2.61l-5 6 5 6h2.61l-5-6 5-6Z"
    />
  </svg>
);
export default SvgArrowLineLeft;
