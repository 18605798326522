import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionEmail = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M2 20h20V4H2v4.58l10 5.77 8-4.62V18H4v-6l-2-1.11V20ZM4 7.42V6h16v1.42L12 12 4 7.42Z"
    />
  </svg>
);
export default SvgActionEmail;
