/** @jsx isolateComponent */
import { forwardRef } from 'react';
import { css } from '@emotion/react';

import { useDesignSystem } from '@zapier/design-system-context';
import { AnchorTarget, ReferrerPolicy } from '../../../ts-types';
import { Animation, Colors } from '../../../theme';
import { isolateComponent, labelStyles } from '@zapier/style-encapsulation';

import { fromEntries } from '../../../utils/fromEntries';

// TODO breaking changes ZIN-477. Remove LinkColorsV4
type LinkColorsV4 = 'ocean' | 'white';
type LinkColorsV5 = 'primary' | 'secondary';

export type Props = {
  /**
   * Indicates the element represents the current item within a set.
   */
  ariaCurrent?: 'page' | 'step' | 'location' | false;
  /**
   * Optional `aria-label` attribute to describe the `Link`.
   */
  ariaLabel?: string;
  /**
   * The content of the `Link`.
   */
  children: React.ReactNode;
  /**
   * The string provided by the `css` prop via emotion.
   * This should only ever be provided by the `css` prop!
   * Note that when this prop is passed, no styles will be
   * applied to `Link` except those defined by `className`;
   * `color` and `weight` props will have no effect.
   */
  className?: string;
  /**
   * The color of the `Link`. Note that this has no effect if
   * the `className` prop is passed. When a custom color is needed,
   * supply a custom `css` / `className` prop.
   * @default 'primary'
   */
  color?: LinkColorsV4 | LinkColorsV5 | 'inherit' | null;
  /** A Component to pass to the Link if you'd like to render a different kind of link,
   * like a Router Link, for example.
   * Using the component prop overrides the `LinkComponent` passed down via `DesignSystemContext`.
   */
  component?: React.ElementType;
  /**
   * Where the `Link` links to.
   */
  href?: string;
  /**
   * Optional ID to identify the link.
   */
  id?: string;
  /**
   * Optional `name` attribute allows consumers to create named anchor links.
   */
  name?: string;
  /**
   * Optional `blur` handler.
   */
  onBlur?: React.ReactEventHandler;
  /**
   * Optional `click` handler.
   */
  onClick?: React.MouseEventHandler;
  /**
   * Optional `focus` handler.
   */
  onFocus?: React.ReactEventHandler;
  /**
   * Optional `keyDown` handler.
   */
  onKeyDown?: React.KeyboardEventHandler;
  /**
   * Optional `mousedown` handler.
   */
  onMouseDown?: React.MouseEventHandler;
  /**
   * Optional `mouseenter` handler.
   */
  onMouseEnter?: React.MouseEventHandler;
  /**
   * Optional `mouseleave` handler.
   */
  onMouseLeave?: React.MouseEventHandler;
  /**
   * Optional `mouseup` handler.
   */
  onMouseUp?: React.MouseEventHandler;
  /**
   * The `rel` attribute of the `Link`, which is the relationship of
   * the `Link` to its target.
   */
  rel?: string;
  /**
   * The `role` attribute of the `Link`, which specifies the function
   * of the `Link`.
   */
  role?: string;
  /**
   * Inline styles for `Link`. Mostly used to pass custom CSS properties
   * from `Button`.
   */
  style?: {};
  /**
   * Optional `tabindex` attribute to apply to the link. Should only be
   * used in rare circumstances!
   */
  tabIndex?: number;
  /**
   * The `target` attribute of the `Link`, which specifies how
   * the `Link` should open.
   */
  target?: AnchorTarget;
  /**
   * The `referrerpolicy` attribute of the `Link`, which specifies how
   * much of the `referrer` to send when following the `Link`.
   */
  referrerPolicy?: ReferrerPolicy;
  /**
   * The `title` attribute of the `Link`, which provides additional
   * text describing the `Link`.
   */
  title?: string;
  /**
   * The `font-weight` to use for `Link`. Note that this has no
   * effect if the `className` prop is passed.
   * @default 'inherit'
   */
  weight?: 'normal' | 'bold' | 'inherit' | null;
};

const Styles = labelStyles('Link', {
  root: () => [
    css`
      // Need to manually unset here since these styles may
      // be passed to a component and not directly to an 'a'.
      all: unset;
      box-sizing: border-box;
      text-decoration: underline;
      cursor: pointer;
      transition: all ${Animation.transitionDuration} ease-in-out;
      outline-offset: 1px;
      // Fix Safari 13 issue with 'color' and 'all: unset'
      // (https://bugs.webkit.org/show_bug.cgi?id=158782).
      -webkit-text-fill-color: currentColor;

      outline: 1px solid transparent;

      &[data-color='ocean'] {
        color: ${Colors.blue};

        &:hover {
          color: ${Colors.night};
        }

        &:focus {
          color: ${Colors.blue};
          outline-color: ${Colors.blue};
        }
      }

      &[data-color='white'] {
        color: ${Colors.neutral100};

        &:hover {
          color: ${Colors.neutral500};
        }

        &:focus {
          color: ${Colors.neutral100};
          outline-color: ${Colors.neutral100};
        }
      }

      &[data-color='primary'] {
        color: ${Colors.blue};

        &:hover {
          color: ${Colors.night};
        }

        &:focus {
          color: ${Colors.blue};
          outline-color: ${Colors.blue};
        }
      }

      &[data-color='secondary'] {
        color: ${Colors.neutral100};

        &:hover {
          color: ${Colors.neutral500};
        }

        &:focus {
          color: ${Colors.neutral100};
          outline-color: ${Colors.neutral100};
        }
      }

      &[data-weight='inherit'] {
        font-weight: inherit;
      }

      &[data-weight='normal'] {
        font-weight: 400;
      }

      &[data-weight='bold'] {
        font-weight: 700;
      }
    `,
  ],
});

// tries to guess if a link's href is external or not
// currently supports these domains (and any sub-domains)
const DOMAIN_WHITELIST = ['zapier.com', 'zapier-staging.com', 'localhost'];
const isLinkExternal = (href?: string) => {
  if (!href || href.length === 0) {
    return false;
  }

  /*
   * This regex tests if an href needs to have a protocol prepended to it or not.
   * We must prepend the protocol for links that are missing it when needed (e.g. example.com),
   * otherwise URL's constructor defaults to using the baseUrl, reporting an incorrect `host`.
   * There are two cases where we know we *don't* need to add it, so we detect those here:
   *    1. starts with ?, /, #, or http
   *    2. a slash occurs before any periods (e.g. apps/foo, but not example.com/apps)
   */
  const matcher = /(^[?#/]|http)|^[^.]+(\/)/;
  let hrefWithProtocol = href;

  if (!matcher.test(href)) {
    hrefWithProtocol = `https://${href}`;
  }

  // default base url to zapier.com allowing `URL`'s constructor to parse relative/fragment links
  const baseUrl = 'https://zapier.com';
  // URL may blow up on invalid URLs
  let url;
  try {
    url = new URL(hrefWithProtocol, baseUrl);
  } catch (_) {
    return true; // well, if it's invalid it at least is not internal
  }
  const [topLevelDomain, secondLevelDomain] = url.host
    .split(':')[0]
    .split('.')
    .reverse();
  const domain = secondLevelDomain
    ? `${secondLevelDomain}.${topLevelDomain}`
    : topLevelDomain;
  return !DOMAIN_WHITELIST.includes(domain);
};

const buildRel = (rel: string | undefined | null, isExternal: boolean) => {
  // Links to other websites should default to security best practices for the `rel` attribute
  // which fixes click-jacking vulnerabilities and other security/privacy issues
  // https://medium.com/@jitbit/target-blank-the-most-underestimated-vulnerability-ever-96e328301f4c
  // https://www.techwyse.com/blog/search-engine-optimization/what-you-need-to-know-about-rel-noreferrer-attribute/
  const externalLinkRel = isExternal ? ['noopener', 'noreferrer'] : [];
  const propsRel = (rel && rel.split(/\s+/)) || []; // merge together any `rel` values passed in with any `rel` values required for external links

  return [...new Set([...propsRel, ...externalLinkRel])].join(' ') || undefined;
};

/**
 * Standard link component. Use only when routing somewhere!
 */

// Note about forcing `React.FC` type here:
// It would be more appropriate to let TypeScript infer the type
// but when generating the flow definition file it's a huge hassle.

// Note, using `function` keyword to facilitate naming the component
// since it's wrapped in `forwardRef`, and assigning `displayName` to
// the `Link` constant doesn't work.
export const Link = forwardRef(function Link(
  props: Props,
  ref: React.Ref<HTMLAnchorElement>
) {
  const { color = 'primary', component: Component, weight = 'inherit' } = props;
  const colorMaybeNull = color === 'inherit' ? null : color;
  const trimmedHref = props.href ? props.href.trim() : props.href;
  const { LinkComponent: ContextLink } = useDesignSystem();

  // Default to the link component from the `component` prop
  const LinkComponent = Component || ContextLink;

  const dataAttributes = fromEntries(
    Object.entries(props).filter(([key]) => {
      return key.startsWith('data-');
    })
  );

  return (
    <LinkComponent
      aria-current={props.ariaCurrent}
      aria-label={props.ariaLabel}
      className={props.className}
      css={props.className ? undefined : Styles.root()}
      data-color={props.className ? undefined : colorMaybeNull}
      data-weight={props.className ? undefined : weight}
      href={trimmedHref}
      id={props.id}
      name={props.name}
      onBlur={props.onBlur}
      onClick={props.onClick}
      onFocus={props.onFocus}
      onKeyDown={props.onKeyDown}
      onMouseDown={props.onMouseDown}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onMouseUp={props.onMouseUp}
      ref={ref}
      rel={buildRel(props.rel, isLinkExternal(props.href))}
      role={props.role}
      style={props.style}
      tabIndex={props.tabIndex}
      target={props.target}
      referrerPolicy={props.referrerPolicy}
      title={props.title}
      {...dataAttributes}
    >
      {props.children}
    </LinkComponent>
  );
});
