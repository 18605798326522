/** @jsx isolateComponent */

import {
  ReactNode,
  useState,
  SyntheticEvent,
  MouseEvent,
  KeyboardEvent,
  FocusEvent,
  ChangeEvent,
} from 'react';
import { css } from '@emotion/react';
import { Tooltip } from '../../display/Tooltip';
import { createUniqueCustomProperties } from '../../../utils/createUniqueCustomProperty';
import { Animation, Colors, Typography } from '../../../theme';
import { isolateComponent, labelStyles } from '@zapier/style-encapsulation';
import { CssProp } from '../../../ts-types';
import { uniqueId } from '../../../utils/uniqueId';

type InputElement = HTMLInputElement | HTMLTextAreaElement;

export type InputEvent = SyntheticEvent<InputElement>;

type RenderIconProps = {
  iconSize: number;
  isFocused: boolean;
  isHovered: boolean;
};

export type Props = {
  /**
   * Content to render after the input value but before `renderIconAfter`.
   * Use the `layout` prop to control how this renders.
   */
  appendContent?: ReactNode;

  /**
   * Identifies the currently active descendant of a composite component.
   * This is used when `TextInput` is part of a composite component,
   * such as `Typeahead`. Without this, some functionality doesn't
   * work well for screen readers.
   */
  ariaActiveDescendant?: string;

  /**
   * Identifies the element whose contents or presence are controlled by
   * this component.
   * This is used when `TextInput` is part of a composite component,
   * such as `Typeahead`. Without this, some functionality doesn't
   * work well for screen readers.
   */
  ariaControls?: string;

  /**
   * Accessibility label that describes this component. This should be supplied
   * if no corresponding `label` element is used.
   */
  ariaLabel?: string;

  /**
   * Accessibility label referencing the ID of an element which labels this
   * component.
   */
  ariaLabelledBy?: string;

  /**
   * Accessibility label referencing the ID of an element which describes this
   * component.
   */
  ariaDescribedBy?: string;

  /**
   * Indicates whether the input should be hidden from screenreaders.
   * Use when there is a separate control, like when the input is disabled.
   */
  ariaHidden?: boolean;

  /**
   * Accessibility label used to indicate that the value entered into an input field does not conform to the format expected by the application
   */
  ariaInvalid?: boolean;

  /**
   * True if user input is required before a form can be submitted.
   */
  ariaRequired?: boolean;

  /**
   * Whether the field can be autocompleted by the browser,
   * and if so what type of information should be autocompleted.
   * See https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
   */
  autoComplete?: string;

  /**
   * Whether the input should be focused when it's initially rendered.
   */
  autoFocus?: boolean;

  /**
   * Indicates whether the icon that renders on the left of the `Input`
   * can be clicked. Set to `false` if clicking it should pass through
   * to the underlying rendered `children`.
   */
  canClickIconBefore?: boolean;

  /**
   * Indicates whether the icon that renders on the right of the `Input`
   * can be clicked. Set to `false` if clicking it should pass through
   * to the underlying rendered `children`.
   */
  canClickIconAfter?: boolean;

  /**
   * Function to render the input itself.
   */
  children: (b: InputChildrenAttrs, a: DerivedState) => ReactNode;

  /**
   * The text that renders in a `Tooltip` when the input `isDisabled`.
   * Only renders when `isDisabled`.
   *
   * If empty (`""`), `null`, or `undefined`, then no `Tooltip` is rendered when
   * the input `isDisabled`.
   */
  disabledText?: string;

  /**
   * The text that renders in a `Tooltip` when the input `isReadonly`. Only
   * renders when `isDisabled`.
   *
   * If empty string or null, then no `Tooltip` is rendered when the input
   * `isReadonly`.
   */
  readOnlyText?: string;

  /**
   * HTML `id` attribute for the element, used to associate it with a `label`.
   */
  id?: string;

  /**
   * Disables the input and makes it non-interactive.
   */
  isDisabled?: boolean;

  /**
   * Is the input full width?
   * @default false
   */
  isFullWidth?: boolean;

  /**
   * Indicates whether the field has an error.
   */
  isErrored?: boolean;

  /**
   * Whether the input can only be read from. Contrast this with
   * `isDisabled`, which completely disables the input, while
   * `isReadOnly` leaves the input functional.
   */
  isReadonly?: boolean;

  /**
   * Whether the input can be focused or not
   * @default true
   */
  isFocusable?: boolean;

  /**
   * Indicates whether the field is required.
   */
  isRequired?: boolean;

  /**
   * How to render content within `Input`, specifically its
   * `appendContent` and `prependContent` props.
   */
  layout?: 'inline' | 'block';

  /** Optional max length for text inputs */
  maxLength?: number;

  /**
   * HTML name attribute, typically used for the key that will
   * be sent to the API.
   */
  name?: string;

  /**
   * Callback executed when the input is blurred.
   */
  onBlur?: (event: FocusEvent<HTMLElement>, meta?: any) => void;

  /**
   * Callback executed when the input's value is changed.
   */
  onChange?: React.ChangeEventHandler;

  /**
   * Callback executed when the input is clicked.
   */
  onClick?: (event?: MouseEvent) => void;

  /**
   * Callback executed when the input is focused.
   */
  onFocus?: (event: FocusEvent<HTMLElement>) => void;

  /**
   * Callback executed when a key is pressed and the input is focused.
   */
  onKeyDown?: (event: KeyboardEvent<HTMLElement>) => void;

  /**
   * A hint to the user of what can be entered in the control.
   * **Note that this is not a sufficient label for accessibility!**
   * Be sure to either associate this input with a `label` element
   * or use the `ariaLabel` prop!
   */
  placeholder?: string;

  /**
   * Content to render before the input value but after `renderIconBefore`.
   * Use the `layout` prop to control how this renders.
   */
  prependContent?: ReactNode;

  /**
   * Function that optionally returns an icon to render
   * on the left side of the input.
   */
  renderIconBefore?: (options: RenderIconProps) => ReactNode | undefined | null;

  /**
   * Function that optionally returns an icon to render
   * on the right side of the input.
   */
  renderIconAfter?: (options: RenderIconProps) => ReactNode | undefined | null;

  /**
   * Indicates role for the field, typically empty.
   */
  role?: string;

  /**
   * The rendered size of the input.
   * @deprecated Only size 'small' is supported.
   */
  //TODO breaking changes remove size medium ZIN-477
  size: 'small' | 'medium';

  /**
   * The actual value of the input.
   */
  value?: string;
};

export type InputChildrenAttrs = {
  'aria-activedescendant'?: string;
  'aria-controls'?: string;
  'aria-describedby'?: string;
  'aria-errormessage'?: string;
  'aria-hidden'?: boolean;
  'aria-invalid'?: boolean;
  'aria-label'?: string;
  'aria-labelledby'?: string;
  'aria-required'?: boolean;
  'data-size': 'small' | 'medium';
  tabIndex?: number;
  autoComplete?: string;
  autoFocus?: boolean;
  css?: CssProp;
  disabled?: boolean;
  id?: string;
  maxLength?: number;
  name?: string;
  onBlur?: (event: FocusEvent<HTMLElement>) => void;
  onChange?: (event: ChangeEvent<HTMLElement>) => void;
  onFocus?: (event: FocusEvent<HTMLElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLElement>) => void;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  role?: string;
  value?: string;
};

type DerivedState = {
  hasIconBefore: boolean;
  hasIconAfter: boolean;
  isFocused: boolean;
  isHovered: boolean;
};

const Vars = createUniqueCustomProperties('Input', [
  'borderRadius',
  'iconColor',
  'inputValueColor',
  'inputHeight',
  'inputPaddingLeft',
  'inputPaddingRight',
  'inputPaddingTop',
  'inputPaddingBottom',
  'prependPaddingLeft',
  'appendPaddingRight',
  'outlineColor',
  'placeholderColor',
  'prependAppendColor',
  'tooltipOpacity',
]);

const Styles = labelStyles('Input', {
  root: css`
    ${Vars.borderRadius}: 3px;
    ${Vars.iconColor}: ${Colors.neutral600};
    // Input is 44px height including border
    ${Vars.inputHeight}: 42px;
    ${Vars.inputPaddingLeft}: 15px;
    ${Vars.inputPaddingRight}: 15px;
    ${Vars.inputPaddingTop}: 9px;
    ${Vars.inputPaddingBottom}: 9px;
    ${Vars.placeholderColor}: ${Colors.neutral600};
    ${Vars.inputValueColor}: ${Colors.neutral800};
    ${Vars.prependPaddingLeft}: 15px;
    ${Vars.appendPaddingRight}: 15px;
    ${Vars.outlineColor}: ${Colors.neutral600};
    ${Vars.prependAppendColor}: ${Colors.neutral800};
    ${Vars.tooltipOpacity}: 0;

    border: 1px solid var(${Vars.outlineColor});
    // Box-shadow only for focus state
    box-shadow: none;
    // For Tooltip positioning
    position: relative;
    border-radius: var(${Vars.borderRadius});
    background-color: ${Colors.neutral100};

    // Only transition specific properties because some,
    // like 'padding', will change but shouldn't transition.
    transition-duration: ${Animation.transitionDuration};
    transition-property: background-color, color, box-shadow;
    transition-timing-function: ease;

    &[data-errored] {
      ${Vars.outlineColor}: ${Colors.error400};
    }

    &:not([data-disabled]):hover {
      ${Vars.outlineColor}: ${Colors.blue};
    }

    &:not([data-disabled]):focus-within {
      ${Vars.outlineColor}: ${Colors.blue};
      box-shadow: 0 0 0 1px var(${Vars.outlineColor});
    }

    &[data-disabled] {
      ${Vars.prependAppendColor}: ${Colors.neutral500};
      ${Vars.outlineColor}: ${Colors.neutral400};
      ${Vars.iconColor}: ${Colors.neutral400};
      ${Vars.inputValueColor}: ${Colors.neutral500};
      ${Vars.placeholderColor}: ${Colors.neutral500};
      cursor: not-allowed;
      background-color: ${Colors.neutral200};
    }

    &[data-readonly]:hover,
    &[data-disabled]:hover {
      ${Vars.tooltipOpacity}: 1;
    }

    &[data-full-width] {
      width: 100%;
      max-width: 100%;
    }

    &[data-icon-before] {
      ${Vars.prependPaddingLeft}: 0;
      ${Vars.inputPaddingLeft}: 0;
    }

    &[data-prepend][data-layout='inline'] {
      ${Vars.inputPaddingLeft}: 0;
    }

    &[data-icon-after] {
      ${Vars.appendPaddingRight}: 0;
      ${Vars.inputPaddingRight}: 0;
    }

    &[data-append][data-layout='inline'] {
      ${Vars.inputPaddingRight}: 0;
    }
  `,
  inputLayout: css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  `,
  prependAppend: css`
    color: var(${Vars.prependAppendColor});

    &[data-layout='inline'] {
      ${Typography.paragraph3Medium};

      &[data-type='prepend'] {
        margin-left: var(${Vars.prependPaddingLeft});
        margin-right: 10px;
      }

      &[data-type='append'] {
        margin-left: 10px;
        margin-right: var(${Vars.appendPaddingRight});
      }
    }

    &[data-layout='block'] {
      ${Typography.smallPrint1};
      background-color: ${Colors.neutral300};
      display: flex;
      align-items: center;
      width: 100%;
      height: 30px;
      padding: 0 var(${Vars.inputPaddingLeft});

      &[data-type='prepend'] {
        // Ensure prepend renders before icon.
        order: -1;
        border-top-left-radius: var(${Vars.borderRadius});
        border-top-right-radius: var(${Vars.borderRadius});
      }

      &[data-type='append'] {
        // Ensure append renders after icon.
        // The value is a contrived number that needs to be
        // greater than the number of siblings it has.
        order: 10;
        border-bottom-left-radius: var(${Vars.borderRadius});
        border-bottom-right-radius: var(${Vars.borderRadius});
      }
    }
  `,
  input: css`
    flex: 1;
    ${Typography.paragraph3Medium};
    color: var(${Vars.inputValueColor});
    height: 100%;
    padding-top: var(${Vars.inputPaddingTop});
    padding-bottom: var(${Vars.inputPaddingBottom});
    padding-left: var(${Vars.inputPaddingLeft});
    padding-right: var(${Vars.inputPaddingRight});

    &:not(textarea) {
      height: var(${Vars.inputHeight});
    }

    // Style placeholder. Selectors cannot be combined,
    // so keep 'em separated.
    &::placeholder {
      color: var(${Vars.placeholderColor});
      // Necessary for Safari.
      -webkit-text-fill-color: var(${Vars.placeholderColor});
    }
    &::-moz-placeholder {
      color: var(${Vars.placeholderColor});
      // Firefox has <1 'opacity' on its placeholders,
      // so it has to be overridden.
      opacity: 1;
    }

    // Bump font size for devices that don't support hovering
    // to prevent mobile browsers from zooming in on inputs
    // when they're focused. 16px is defined at the OS level
    // of these devices, specifically iOS.
    @media (hover: none) {
      font-size: 16px;
    }

    // Hide "clear selection" X icon in Edge.
    // https://zappy.zapier.com/F693DF53-79B7-4857-AADE-73E9BF3011DB.png
    &::-ms-clear {
      display: none;
    }
    // Hide "clear selection" X icon in Safari.
    &::-webkit-search-cancel-button {
      display: none;
    }

    // Hide selection when '[readonly]' since the text can't
    // be edited, but the selection makes it look like it can be.
    &[readonly]::selection {
      background-color: transparent;
    }

    &[disabled] {
      cursor: not-allowed;
      color: ${Colors.neutral500};
      -webkit-text-fill-color: ${Colors.neutral500};
      white-space: pre-wrap;
      ${Vars.placeholderColor}: ${Colors.neutral500};
    }
  `,
  icon: css`
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(${Vars.iconColor});
    position: relative;
    z-index: 1;
    pointer-events: none;
    margin: 0 8px;
    height: var(${Vars.inputHeight});

    &[data-interactive] {
      pointer-events: auto;
    }
  `,
  tooltip: css`
    opacity: var(${Vars.tooltipOpacity});
    transition: ${Animation.transitionValue};
    transform: translateY(-15px);
  `,
});

/**
 * Renders a wrapper node that may render icons and tooltips. Within
 * that node `children` are rendered as a function which should return
 * some sort of input or input-like element.
 *
 * `Input` isn't intended to be used directly, but is exposed so that
 * its internal workings can be shared among other components
 * like `TextInput` and `Dropdown`.
 */
export const Input = (props: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [tooltipId] = useState(
    props.id ? `${props.id}-tooltip` : uniqueId(`Input__tooltip-`)
  );
  const shouldRenderTooltip =
    (props.isDisabled && !!props.disabledText) ||
    (props.isReadonly && !!props.readOnlyText);

  const renderIconProps = {
    iconSize: 24,
    isFocused,
    isHovered,
  };

  const iconBefore =
    props.renderIconBefore && props.renderIconBefore(renderIconProps);

  const iconAfter =
    props.renderIconAfter && props.renderIconAfter(renderIconProps);

  const derivedState = {
    hasIconBefore: !!iconBefore,
    hasIconAfter: !!iconAfter,
    isFocused,
    isHovered,
  };

  return (
    <div
      css={Styles.root}
      data-append={props.appendContent ? '' : undefined}
      data-disabled={props.isDisabled ? '' : undefined}
      data-readonly={props.isReadonly ? '' : undefined}
      data-errored={props.isErrored ? '' : undefined}
      data-full-width={props.isFullWidth ? '' : undefined}
      data-icon-after={iconAfter ? '' : undefined}
      data-icon-before={iconBefore ? '' : undefined}
      data-layout={props.layout}
      data-prepend={props.prependContent ? '' : undefined}
      data-size={props.size}
      onBlur={() => setIsFocused(false)}
      onClick={props.onClick}
      onFocus={() => setIsFocused(true)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      role="presentation"
    >
      <div css={Styles.inputLayout}>
        {iconBefore && (
          <div
            css={Styles.icon}
            data-interactive={props.canClickIconBefore ? '' : undefined}
            data-size={props.size}
          >
            {iconBefore}
          </div>
        )}
        {props.prependContent && (
          <div
            css={Styles.prependAppend}
            data-layout={props.layout}
            data-size={props.size}
            data-type="prepend"
          >
            {props.prependContent}
          </div>
        )}
        {props.children(
          {
            'aria-activedescendant': props.ariaActiveDescendant,
            'aria-controls': props.ariaControls,
            'aria-describedby': shouldRenderTooltip
              ? tooltipId
              : props.ariaDescribedBy,
            'aria-errormessage': props.isErrored
              ? props.ariaDescribedBy
              : undefined,
            'aria-hidden': props.ariaHidden,
            'aria-invalid': props.isErrored,
            'aria-label': props.ariaLabel,
            'aria-labelledby': props.ariaLabelledBy,
            'aria-required': props.ariaRequired,
            'data-size': props.size,
            autoComplete: props.autoComplete,
            autoFocus: props.autoFocus,
            css: Styles.input,
            tabIndex: props.isFocusable ? undefined : -1,
            disabled: props.isDisabled,
            id: props.id,
            maxLength: props.maxLength,
            name: props.name,
            onBlur: props.onBlur,
            onChange: props.onChange,
            onFocus: props.onFocus,
            onKeyDown: props.onKeyDown,
            placeholder: props.placeholder,
            readOnly: props.isReadonly,
            required: props.isRequired,
            role: props.role,
            value: props.value,
          },
          derivedState
        )}
        {props.appendContent && (
          <div
            css={Styles.prependAppend}
            data-layout={props.layout}
            data-type="append"
            data-size={props.size}
          >
            {props.appendContent}
          </div>
        )}
        {iconAfter && (
          <div
            css={Styles.icon}
            data-interactive={props.canClickIconAfter ? '' : undefined}
            data-size={props.size}
          >
            {iconAfter}
          </div>
        )}
      </div>
      {shouldRenderTooltip && (
        <div css={Styles.tooltip}>
          <Tooltip id={tooltipId} position="south">
            {props.isReadonly ? props.readOnlyText : props.disabledText}
          </Tooltip>
        </div>
      )}
    </div>
  );
};

Input.defaultProps = {
  canClickIconBefore: true,
  canClickIconAfter: true,
  disabledText: 'Disabled',
  readOnlyText: 'Read-Only',
  isFocusable: true,
  layout: 'inline',
  isFullWidth: false,
  size: 'small',
};
