import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgArrowExit = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M3 21h2V3H3v18ZM17.27 6h-2.61l4.2 5H7v2h11.86l-4.2 5h2.61l5-6-5-6Z"
    />
  </svg>
);
export default SvgArrowExit;
