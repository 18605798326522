import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgPersonCircleFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2Zm0 18c-2.6 0-4.9-1.25-6.36-3.17L6.3 15h11.4l.66 1.83C16.9 18.75 14.6 20 12 20Zm-.01-13.96c2.06 0 3.74 1.68 3.74 3.74 0 2.06-1.68 3.74-3.74 3.74-2.06 0-3.74-1.68-3.74-3.74 0-2.06 1.68-3.74 3.74-3.74Z"
    />
  </svg>
);
export default SvgPersonCircleFill;
