import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscFingerprint = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M13 16h-2v5h2v-5ZM21 16c0-1.77-.51-3.48-1.48-4.95l-1.67 1.1A7.032 7.032 0 0 1 19 16v5h2v-5Z"
    />
    <path
      fill="#2D2E2E"
      d="M16.06 7.97A8.928 8.928 0 0 0 12 7c-4.96 0-9 4.04-9 9v5h6v-5c0-.84.36-1.65.98-2.22.63-.57 1.45-.85 2.32-.76 1.52.14 2.7 1.53 2.7 3.16V21h2v-4.83c0-2.65-1.98-4.91-4.51-5.15-1.4-.13-2.81.33-3.85 1.28A5.014 5.014 0 0 0 7 16v3H5v-3a7.008 7.008 0 0 1 10.16-6.25c3.41 1.72 6.03-3.31 2.81-5.16A11.987 11.987 0 0 0 12 3C8.69 3 5.61 4.32 3.32 6.72L4.77 8.1C6.67 6.1 9.24 5 12 5c1.75 0 3.47.46 4.97 1.32.99.57.22 2.22-.91 1.65Z"
    />
  </svg>
);
export default SvgMiscFingerprint;
