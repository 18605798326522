import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgPersonOneFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M18.7 14H5.3l-2.91 8h14.96l-.73-1.99h-5.29l.73-2h5.96L19.48 22h2.13l-2.91-8ZM12 12a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
    />
  </svg>
);
export default SvgPersonOneFill;
