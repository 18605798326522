import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionCompare = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      fillRule="evenodd"
      d="M11 22v-2H2V4h9V2h2v20h-2Zm0-16v12H4V6h7Zm3 0h2.75V4H14v2Zm5.25-2h-1v2H20v1.75h2V4h-2.75ZM22 10.25v-1h-2v5.5h2v-4.5Zm0 7v-1h-2V18h-1.75v2H22v-2.75Zm-6.25.75H14v2h2.75v-2h-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgActionCompare;
