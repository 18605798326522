import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionLockFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M12 1C9.24 1 7 3.24 7 6v2H4v14h16V8h-3V6c0-2.76-2.24-5-5-5Zm0 16.25c-1.24 0-2.25-1.01-2.25-2.25s1.01-2.25 2.25-2.25 2.25 1.01 2.25 2.25-1.01 2.25-2.25 2.25ZM15 8H9V6c0-1.65 1.35-3 3-3s3 1.35 3 3v2Z"
    />
  </svg>
);
export default SvgActionLockFill;
