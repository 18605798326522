import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionTrash = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M15.98 20H8.02L7.19 9h-2l.97 13h11.68l.97-13h-2l-.83 11ZM20.41 4.55l-.34-1.97-5.12.89-1-.84-4.86.86-.65 1.13-5.12.89.35 1.98 16.74-2.94Z"
    />
  </svg>
);
export default SvgActionTrash;
