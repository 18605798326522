import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionMoveToFolder = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M6 13h8.86l-2.52 3h2.61l3.36-4-3.37-4h-2.61l2.52 3H6v2Z"
    />
    <path
      fill="#2D2E2E"
      d="m12.47 4-1.26-1.5H2V16h2V4.5h6.27L11.53 6H20v12H2v2h20V4h-9.53Z"
    />
  </svg>
);
export default SvgActionMoveToFolder;
