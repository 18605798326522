import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscBoltAlt = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M11 12H7.75L13 5.75V12h3.25L11 18.25V16H9v7.75L20.54 10H15V.25L3.46 14H11v-2Z"
    />
  </svg>
);
export default SvgMiscBoltAlt;
