import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscList = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M4.5 8a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM4.5 13.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM4.5 19a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM21 5.5H8v2h13v-2ZM21 11H8v2h13v-2ZM21 16.5H8v2h13v-2Z"
    />
  </svg>
);
export default SvgMiscList;
