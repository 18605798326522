import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionSendFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M23.63 12 .97 1.27l1.46 8.31L9 12.67v2.21l-6.14-2.89V12L.97 22.73 23.63 12Z"
    />
  </svg>
);
export default SvgActionSendFill;
