import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgRatingSadAlt = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M7.5 9.75a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5ZM16.5 9.75a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5ZM12 13a9.94 9.94 0 0 0-7.84 3.81l1.57 1.24A7.936 7.936 0 0 1 12 15c2.46 0 4.74 1.11 6.28 3.05l1.57-1.24C17.93 14.39 15.07 13 12 13Z"
    />
  </svg>
);
export default SvgRatingSadAlt;
