import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavDashboard = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m13.85 4-3.28 9H4V6H2v16h20V2H2v2h11.85ZM4 15h5.84L8 20H4v-5Zm6.15 5 3.28-9H20v9h-9.85ZM20 9h-5.84l1.82-5h4L20 9Z"
    />
  </svg>
);
export default SvgNavDashboard;
