import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgPartnerDashboard = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      fillRule="evenodd"
      d="M2 3v18h20v-2H4V3H2Zm4.043 13.04 3.645-3.645 3.976 3.726 8.278-8.277V5.016l-8.277 8.277-3.977-3.727-3.645 3.645v2.828Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPartnerDashboard;
