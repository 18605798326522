import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgPartnerEmbed = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M17.47 3H2v2h18v14H4V7H2v14h20V3h-4.53Zm-1.64 12 2.52-3-2.52-3h-2.61l2.52 3-2.52 3h2.61ZM8.17 9l-2.52 3 2.52 3h2.61l-2.52-3 2.52-3H8.17Z"
    />
  </svg>
);
export default SvgPartnerEmbed;
