import React from 'react';
import { jsx } from '@emotion/react';
import type { SerializedStyles } from '@emotion/react';

import { buildResetStyles, isHtmlNode, isSvgNode } from './buildResetStyles';

// Function and `class` components will be functions,
// whereas HTML elements like `div` will be strings.
// Treat forwarded refs as components, too.
const isComponent = (type: any) => {
  return (
    typeof type === 'function' ||
    !!type?.$$typeof?.toString().includes('forward')
  );
};

// Selector for component styles. Repeats `[class]` which will
// increase specificity, concatenated onto `&`, which `emotion`
// will transform into a CSS class name.
const componentSelector = '&' + '[class]'.repeat(5);

// Returns a styles object comprised of the passed in `styles`
// and a CSS reset, whose selector is specific enough to
// override (essentially) any external styles that may
// conflict with the component's own styles.
const isolateCss = (nodeType: any, styles: Array<SerializedStyles>): any => {
  return {
    [componentSelector]: [
      // Don't add reset styles for components since that'll result
      // in a double reset and potentially unset intentional styles.
      !isComponent(nodeType) && buildResetStyles(nodeType),
      // `map` over `styles` so that each of its items renders as
      // a separate CSS chunk in the dev tools, facilitating debugging.
      styles.map((stylesItem) => ({
        '&': stylesItem,
      })),
    ],
  };
};

type PropsWithCSS = {
  css?: SerializedStyles;
};

const flatten = (arr: Array<any>): Array<any> => {
  return arr.reduce((acc, item) => {
    return acc.concat(Array.isArray(item) ? flatten(item) : item);
  }, []);
};

export const isolateComponent = (
  type: Parameters<typeof React.createElement>[0],
  props: Parameters<typeof React.createElement>[1] & PropsWithCSS,
  ...children: Array<React.ReactNode>
): React.ReactNode => {
  props = props || {};
  if (!type || isSvgNode(type)) {
    return jsx(type, props, ...children);
  }
  if (props.css || isHtmlNode(type)) {
    props.css = isolateCss(type as any, flatten([props.css]).filter(Boolean));
  }
  return jsx(type, props, ...children);
};
