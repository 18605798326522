import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionBookmark = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M4 6v16.73l8-4.62 8 4.62V2H4v2h14v15.27l-6-3.47-6 3.47V6H4Z"
    />
  </svg>
);
export default SvgActionBookmark;
