import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgPersonCircle = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M12 2C6.49 2 2 6.49 2 12c0 3.48 1.79 6.54 4.49 8.34L7.7 17h8.6l.5 1.38C15.46 19.39 13.8 20 12 20c-1.08 0-2.1-.22-3.04-.6l-.68 1.88c1.15.46 2.4.72 3.72.72 5.51 0 10-4.49 10-10S17.51 2 12 2Zm6.36 14.83L17.7 15H6.3l-.66 1.83A7.93 7.93 0 0 1 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.81-.61 3.48-1.64 4.83Z"
    />
    <path
      fill="#2D2E2E"
      d="M11.99 6.04c-2.06 0-3.74 1.68-3.74 3.74 0 2.06 1.68 3.74 3.74 3.74 2.06 0 3.74-1.68 3.74-3.74 0-2.06-1.68-3.74-3.74-3.74Zm0 5.48a1.741 1.741 0 0 1 0-3.48 1.741 1.741 0 0 1 0 3.48Z"
    />
  </svg>
);
export default SvgPersonCircle;
