import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscSteps2 = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M21 5H11v2h10V5ZM21 11H11v2h10v-2ZM21 17H11v2h10v-2ZM5.49 10h1.14V5.44l-2.09.88v1.1L5.49 7v3ZM6.75 15.33c.4-.52.65-.93.65-1.45 0-.77-.61-1.33-1.47-1.33-.57 0-1.08.23-1.41.5v1.08c.27-.23.78-.56 1.22-.56.35 0 .52.17.52.45 0 .34-.39.83-.77 1.34L4.25 17h3.27v-.94H6.19l.56-.73Z"
    />
  </svg>
);
export default SvgMiscSteps2;
