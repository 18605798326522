import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgFormAddSquareOutlined = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path fill="#2D2E2E" d="M11 17h2v-4h4v-2h-4V7h-2v4H7v2h4v4Z" />
    <path fill="#2D2E2E" d="M3 3v18h18V3H3Zm16 16H5V5h14v14Z" />
  </svg>
);
export default SvgFormAddSquareOutlined;
