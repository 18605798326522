import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionInsert = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M17 4H3v2h14V4ZM13 9H3v2h10V9ZM9 14H3v2h6v-2ZM17 14v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4Z"
    />
  </svg>
);
export default SvgActionInsert;
