import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscSteps1 = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m3 10.78 2-.5V17h2V7.72l-4 1v2.06ZM21 5h-9v2h9V5ZM21 11h-9v2h9v-2ZM21 17h-9v2h9v-2Z"
    />
  </svg>
);
export default SvgMiscSteps1;
