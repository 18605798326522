// Borrowed from https://bit.ly/2VupzKY

import { useEffect, useRef, useState } from 'react';

type Options = {
  heightOverride?: number;
  once?: boolean;
  root?: HTMLElement;
  rootMargin?: string;
  threshold?: number;
};

type Metadata = {
  isTopIntersecting?: boolean;
  isBottomIntersecting?: boolean;
  isLeftIntersecting?: boolean;
  isRightIntersecting?: boolean;
};

type EntryData = {
  entry: IntersectionObserverEntry | null;
};

export type UseIntersectReturn = [
  (element: Element | null) => void,
  Metadata & EntryData
];

export const useIntersect = ({
  heightOverride,
  once = false,
  root,
  rootMargin = '0px',
  threshold = 1,
}: Options = {}): UseIntersectReturn => {
  // Hold a reference to the `IntersectionObserver` entry.
  const [entry, updateEntry] = useState<IntersectionObserverEntry | null>(null);
  // Hold a reference for our own metadata about the entry.
  const [meta, updateMeta] = useState<Metadata | null>(null);
  // Hold a reference to the DOM node being observed.
  const [node, setNode] = useState<Element | null>(null);
  // Hold a reference to the `IntersectionObserver` itself.
  const observer = useRef<IntersectionObserver>(null);
  useEffect(() => {
    const disconnect = () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
    // Disconnect since something changed and therefore
    // the node needs to be reobserved.
    disconnect();
    // calculates the intersection metadata for an entry
    const getMetadata = ({
      intersectionRatio,
      intersectionRect,
      rootBounds,
    }: IntersectionObserverEntry) => {
      const isIntersecting =
        !!intersectionRect && !!rootBounds && intersectionRatio < threshold;

      // allow for height override in calculations
      const height = heightOverride ?? intersectionRect.height;
      const bottom = intersectionRect.top + height;
      const top = intersectionRect.top - height;

      return {
        isTopIntersecting:
          isIntersecting &&
          (top <= rootBounds!.top ||
            intersectionRect.height > rootBounds!.height),
        isBottomIntersecting: isIntersecting && bottom >= rootBounds!.bottom,
        isLeftIntersecting:
          isIntersecting && intersectionRect.left <= rootBounds!.left,
        isRightIntersecting:
          isIntersecting && intersectionRect.right >= rootBounds!.right,
      };
    };
    // Update the entry and metadata when the observation changes.
    const callback: IntersectionObserverCallback = ([updatedEntry]) => {
      updateEntry(updatedEntry);
      updateMeta(getMetadata(updatedEntry));
      if (once) {
        disconnect();
      }
    };
    // Create the observer.
    const options = {
      root,
      rootMargin,
      threshold: Array.isArray(threshold) ? threshold : [threshold],
    };
    if (window.IntersectionObserver) {
      // @ts-ignore
      observer.current = new window.IntersectionObserver(callback, options);
    }
    // Actually start observing `node`.
    if (node && observer.current) {
      observer.current.observe(node);
    }
    return disconnect;
  }, [once, node, root, rootMargin, threshold, heightOverride]);
  return [setNode, { entry, ...meta }];
};
