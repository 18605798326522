import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscLearn = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M12 3.35 1 9.7l11 6.35 5-2.89v3.3l-5 2.89-5-2.89v-.99l-2-1.15v3.29l7 4.04 7-4.04V12l2-1.15V19h2V9.7L12 3.35Zm0 10.39L5 9.7l7-4.04 7 4.04-7 4.04Z"
    />
  </svg>
);
export default SvgMiscLearn;
