/**
 * `react-docgen` will throw on an `import` statement inside of a component:
 * `"did not recognize object of type \"ImportExpression\""`
 * To workaround this, we just move it to another file side-by-side the component
 *
 * Note: the partial import path is required to be inside this import statement,
 * otherwise webpack will complain that it's too dynamic.
 * `Critical dependency: the request of a dependency is an expression`
 **/
export const asyncImportSVG = async (name: string) =>
  // Note that this `.svg` is replaced by `.js` during the build
  // since `.svg` files will be transpiled to `.js`.
  await import(`./icons/${name}.svg`);
