import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionRenameFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M13.09 5 2 16.09V21h4.91L18 9.91 13.09 5Zm7.74 2.08-4.91-4.91-1.42 1.42 4.91 4.91 1.42-1.42Zm.6 12.35A2.57 2.57 0 0 1 20 20c-.4 0-.54-.12-.94-1.26-.4-1.14-.83-2.36-2.42-2.69-2.56-.51-4.69 1.78-7.39 4.69L9 21h2.74c1.79-1.89 3.27-3.23 4.49-3 .43.09.62.49.93 1.39.31.9.92 2.61 2.84 2.61a4.77 4.77 0 0 0 2.86-1.15l-1.43-1.42Z"
    />
  </svg>
);
export default SvgActionRenameFill;
