import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgRatingStar = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m22.49 10.26-.65-1.98-6.08-.89-2.67-5.41h-2.18L8.24 7.39l-6.08.89-.69 2.12 8.1-1.18L12 4.29l2.43 4.93 5.45.79-3.94 3.84.93 5.42L12 16.71l-4.87 2.56.93-5.42-2.14-2.08-2.49.36 2.48 2.42-1.07 6.23 1.56 1.13 5.6-2.94 5.6 2.94 1.56-1.13-1.07-6.23 4.4-4.29Z"
    />
  </svg>
);
export default SvgRatingStar;
