import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscPieFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M2.05 12.91c.23 2.31 1.26 4.39 2.79 5.97l5.01-5.97h-7.8ZM19.15 4.94 6.37 20.17c1.6 1.1 3.54 1.74 5.63 1.74 5.51 0 10-4.49 10-10 0-2.71-1.09-5.17-2.85-6.97ZM17.63 3.65A9.902 9.902 0 0 0 12 1.91c-5.18 0-9.45 3.95-9.95 9h9.48l6.1-7.26Z"
    />
  </svg>
);
export default SvgMiscPieFill;
