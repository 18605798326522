import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionSend = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M23.63 12 .97 1.27l1.63 9.24L9 13.52v-2.21L4.39 9.14l-.78-4.41L18.96 12 3.61 19.27l.99-5.61-1.88-.88-1.75 9.95L23.63 12Z"
    />
  </svg>
);
export default SvgActionSend;
