import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgRatingCool = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2Zm0 2c2.74 0 5.17 1.39 6.61 3.5H15c-.72 0-1.32.38-1.67.93-.85-.31-1.8-.31-2.65 0-.36-.55-.96-.93-1.68-.93H5.39A8.002 8.002 0 0 1 12 4Zm0 16c-4.41 0-8-3.59-8-8 0-.97.18-1.9.5-2.76v.55c0 .47.11.93.32 1.34l.38.76A2 2 0 0 0 6.99 13H8.7a2 2 0 0 0 1.96-1.61l.29-1.46a2.33 2.33 0 0 1 2.09 0l.29 1.46c.2.94 1.02 1.61 1.97 1.61h1.71a2 2 0 0 0 1.79-1.11l.38-.76c.21-.42.32-.88.32-1.34v-.55c.32.86.5 1.79.5 2.76 0 4.41-3.59 8-8 8Z"
    />
    <path
      fill="#2D2E2E"
      d="M12 16c-1.43 0-2.72-.75-3.45-2.01l-1.73 1C7.9 16.85 9.88 18 12 18c2.12 0 4.1-1.15 5.18-3.01l-1.73-1C14.72 15.25 13.43 16 12 16Z"
    />
  </svg>
);
export default SvgRatingCool;
