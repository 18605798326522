import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgArrowBigLeft = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M7.14 13H20v-2H7.14l5.04-6H9.56l-5.87 7 5.87 7h2.62l-5.04-6Z"
    />
    <path
      fill="#2D2E2E"
      d="M7.14 13H20v-2H7.14l5.04-6H9.56l-5.87 7 5.87 7h2.62l-5.04-6Z"
    />
  </svg>
);
export default SvgArrowBigLeft;
