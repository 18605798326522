/** @jsx isolateComponent */
import { css } from '@emotion/react';
import { isolateComponent, labelStyles } from '@zapier/style-encapsulation';
import { useHeadingLevel } from '@zapier/heading-level-context';
import { clamp } from '../../../utils/clamp';

type Props = {
  /**
   * The content to render for the heading.
   */
  children: React.ReactNode;
  /**
   * The string provided by the `css` prop via emotion.
   * This should only ever be provided by the `css` prop!
   */
  className?: string;
  /**
   * The `css` prop for emotion. Enumerated
   * here to keep typechecker from complaining.
   */
  css?: any;
};

const Styles = labelStyles('Heading', {
  root: css`
    display: block;
  `,
});

/**
 * Renders an `h*` tag of the appropriate level, based on how
 * deeply nested within the `HeadingLevel` hierarchy the component is.
 */
export const Heading = (props: Props) => {
  const { level } = useHeadingLevel();
  const headingLevel = clamp(level, 1, 6);
  const Tag = `h${headingLevel}` as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  return (
    <Tag css={Styles.root} className={props.className}>
      {props.children}
    </Tag>
  );
};
