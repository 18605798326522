import { readCookie } from '@zapier/cookies';
import { useState, useCallback, useEffect } from 'react';

type Policy = {
  content: number;
  slug: string;
  title: string;
  tldr: string;
  published_at: string;
  url: string;
};

type Result = {
  isLoading: boolean;
  isError: boolean;
  policy: Policy | null;
  acceptPolicy: () => Promise<boolean>;
};

const queryPolicy = async (zapierUrl: string): Promise<Policy | null> => {
  const searchParams = new URLSearchParams();
  searchParams.append('limit', '1');
  searchParams.append('offset', '0');

  const url = `${zapierUrl}/api/v4/policies/agreements/pending/?${searchParams.toString()}`;
  const response = await fetch(url, {
    credentials: 'include',
  });

  if (!response.ok) {
    throw new Error('Response was not OK');
  }

  const json = await response.json();
  const policy = json.results[0] || null;

  return policy;
};

const submitPolicyAcceptance = async (
  zapierUrl: string,
  slug: string,
  contentId: number
): Promise<boolean> => {
  const response = await fetch(
    `${zapierUrl}/api/v4/policies/${encodeURIComponent(slug)}/agreements/`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': readCookie('csrftoken'),
      },
      body: JSON.stringify({
        policy_content: contentId,
      }),
    }
  );

  return response.ok;
};

export const useQueryPolicyPending = (zapierUrl: string = ''): Result => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [policy, setPolicy] = useState<Policy | null>(null);

  useEffect(() => {
    queryPolicy(zapierUrl)
      .then((p) => {
        setPolicy(p);
        setIsLoading(false);
      })
      .catch((_e) => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [zapierUrl]);

  const acceptPolicy = useCallback(async () => {
    if (!policy) return false;

    const success = await submitPolicyAcceptance(
      zapierUrl,
      policy.slug,
      policy.content
    );

    setPolicy(null);
    return success;
  }, [policy, zapierUrl]);

  return {
    isLoading,
    isError,
    policy,
    acceptPolicy,
  };
};
