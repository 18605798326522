import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgUpAndDownArrows = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M12 3.18 7 7.5v2.64l5-4.32 5 4.31V7.49l-5-4.31ZM7 13.37v2.64l5 4.31L17 16v-2.64l-5 4.32-5-4.31Z"
    />
  </svg>
);
export default SvgUpAndDownArrows;
