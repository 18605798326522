import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscCreditCard = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path fill="#2D2E2E" d="M2 4v12h2v-5h16v7H2v2h20V4H2Zm18 4H4V6h16v2Z" />
    <path fill="#2D2E2E" d="M11 14H6v2h5v-2ZM15 14h-2v2h2v-2Z" />
  </svg>
);
export default SvgMiscCreditCard;
