import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavBlogFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M22 4h-8v16h8V4ZM12 4H2v8h10V4ZM12 14H2v2h10v-2ZM12 18H2v2h10v-2Z"
    />
  </svg>
);
export default SvgNavBlogFill;
