/** @jsx isolateComponent */

import { forwardRef } from 'react';
import type { Ref } from 'react';

import { Input } from '../../forms/Input';
import type { InputProps } from '../../forms/Input';
import {
  isolateComponent,
  labelStyles,
  withModifier,
} from '@zapier/style-encapsulation';

// This type can be removed when the forwardedRef prop gets removed
type InputElement =
  | HTMLInputElement
  | HTMLTextAreaElement
  | { focus: () => void };

type TextInputRef = HTMLInputElement | HTMLTextAreaElement;

export type Props = Omit<InputProps, 'children' | 'size'> & {
  /** Controls how text is automatically capitalized as it's entered
   * by the user
   */
  autoCapitalize?: 'on' | 'off' | 'words' | 'characters';
  /**
   * Browser `autocomplete` form field attribute.
   */
  autoComplete?: string;
  /** Activates automatic correct while the user is editing the field
   * (used by Safari)
   */
  autoCorrect?: 'on' | 'off';
  /**
   * @deprecated Use `ref` to obtain access to underlying HTML `input` or `textarea`.
   * `forwardedRef` will **not** actually be forwarded to the underlying `input` or `textarea`.
   */
  forwardedRef?: Ref<InputElement>;
  /**
   * Should this component render in its full width?
   */
  isFullWidth?: boolean;
  /**
   * Whether the input accepts multiple lines of text.
   * Renders an `input` if `false` and a `textarea` if true`.
   */
  isMultiline?: boolean;
  /**
   * HTML inputmode for controlling which keyboard shows on mobile
   * https://css-tricks.com/everything-you-ever-wanted-to-know-about-inputmode/
   * https://html.spec.whatwg.org/multipage/interaction.html#input-modalities:-the-inputmode-attribute
   */
  inputMode?:
    | 'decimal'
    | 'email'
    | 'none'
    | 'numeric'
    | 'search'
    | 'tel'
    | 'text'
    | 'url';
  /**
   * Limits the maximum number of characters within the input.
   */
  maxLength?: number;
  /**
   * Indicates in which directions a `textarea` can be resized.
   * Only works when `isMultiline={true}`!
   */
  resize?: 'horizontal' | 'vertical' | 'both' | 'none';
  /**
   * Indicates how many rows should render by default for a `textarea`s.
   * Only works when `isMultiline={true}`!
   */
  rows?: number;
  /**
   * The rendered size of the input.
   * @deprecated 'small' (44px) is now the only supported size
   */
  size?: 'small' | 'medium';
  /** Use spelling and grammar checks in the text input */
  spellCheck?: 'true' | 'false';
  /**
   * Indicates the type of the input so the browser can provide a better
   * experience to users. Only used for `input` fields and *not* `textarea`s,
   * so is affected by `isMultiline`.
   */
  type?: 'email' | 'password' | 'search' | 'tel' | 'text';
};

const Styles = labelStyles('TextInput', {
  input: (props: Props) => [
    // FireFox puts an arbitrary min-width on the input otherwise
    { minWidth: 1 },
    props.isMultiline &&
      withModifier('multiline', {
        height: 'auto',
      }),

    props.isFullWidth &&
      withModifier('fullwidth', {
        width: '100%',
      }),

    props.resize &&
      withModifier(`resize-${props.resize}`, {
        resize: props.resize,
      }),
  ],
});

/**
 * Renders either an `input` or `textarea` element, depending on the
 * `isMultiline` prop. This is a bare-bones implementation without
 * any special handling for events.
 */
export const TextInput = forwardRef<TextInputRef, Props>((props, ref) => {
  const Tag = props.isMultiline ? 'textarea' : 'input';
  return (
    <Input
      {...props}
      renderIconAfter={props.isMultiline ? undefined : props.renderIconAfter}
      renderIconBefore={props.isMultiline ? undefined : props.renderIconBefore}
    >
      {(inputProps) => (
        <Tag
          {...inputProps}
          autoCapitalize={props.autoCapitalize}
          autoCorrect={props.autoCorrect}
          css={[inputProps.css, Styles.input(props)]}
          inputMode={props.inputMode}
          ref={ref as Ref<any>}
          rows={props.isMultiline ? props.rows : undefined}
          spellCheck={props.spellCheck}
          type={!props.isMultiline ? props.type : undefined}
          value={inputProps.value}
          onChange={inputProps.onChange}
        />
      )}
    </Input>
  );
});

TextInput.defaultProps = {
  isMultiline: false,
  type: 'text',
  isFullWidth: false,
  size: 'small',
};
