/** @jsx isolateComponent */

import React from 'react';
import { css } from '@emotion/react';

import { isolateComponent, labelStyles } from '@zapier/style-encapsulation';

import { Colors, Typography } from '../../../theme';
import { FormLabelRequiredText } from './FormLabelRequiredText';

type Props = {
  /**
   * How to vertically align the items.
   */
  alignItems?: 'center' | 'start';
  /**
   * How to horizontally justify the content.
   */
  justifyContent?: 'start' | 'stretch';
  /**
   * The content to render within `FormLabel`. May be text or nodes containing
   * text, an `input`, etc.
   */
  children: React.ReactNode;
  /**
   * The `for` attribute to attach to the `label` node, associating it to
   * an external input by its `id`.
   */
  htmlFor?: string;
  /**
   * Indicates whether the input the `FormLabel` controls is disabled.
   */
  isDisabled?: boolean;
  /**
   * Indicates whether the input the `FormLabel` controls is errored.
   */
  isErrored?: boolean;
  /**
   * Indicates whether the input the `FormLabel` controls is required.
   */
  isRequired?: boolean;
  /**
   * Indicates whether the input the `FormLabel` controls is selected.
   * Typically used in conjunction with `BooleanInput`.
   */
  isSelected?: boolean;
  /**
   * Optional text to render inside the required label.
   */
  requiredText?: React.ReactNode;
  /**
   * Size of the `FormLabel`.
   */
  size?: 'small' | 'medium';
};

const Styles = labelStyles('FormLabel', {
  root: css`
    display: grid;
    justify-content: start;
    grid-gap: 5px;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    cursor: pointer;
    color: ${Colors.neutral700};

    &[data-align-items='center'] {
      align-items: center;
    }

    &[data-align-items='start'] {
      align-items: start;
    }

    &[data-justify-content='start'] {
      justify-content: start;
    }

    &[data-justify-content='stretch'] {
      justify-content: stretch;
    }

    &[data-state~='selected'] {
      color: ${Colors.blue};
    }

    &[data-state~='errored'] {
      color: ${Colors.error400};
    }

    &[data-state~='disabled'] {
      cursor: not-allowed;
    }

    &[data-state~='disabled'] {
      color: ${Colors.neutral500};
    }

    &[data-size='small'] {
      ${Typography.smallPrint1Semibold};
    }

    &[data-size='medium'] {
      ${Typography.paragraph3Medium};
    }

    &[data-size='medium'][data-state='selected'] {
      ${Typography.paragraph3Semibold}
    }
  `,
});

const defaultProps = {
  alignItems: 'center',
  justifyContent: 'start',
  size: 'medium',
};

/**
 * `FormLabel` associates text to input fields.
 */
export const FormLabel = (_props: Props) => {
  const props = {
    ...defaultProps,
    ..._props,
  };
  return (
    <label
      css={Styles.root}
      data-align-items={props.alignItems}
      data-justify-content={props.justifyContent}
      data-size={props.size}
      data-state={
        [
          props.isSelected && 'selected',
          props.isErrored && 'errored',
          props.isDisabled && 'disabled',
        ]
          .filter(Boolean)
          .join(' ') || undefined
      }
      htmlFor={props.htmlFor}
    >
      {props.isRequired && '*'}
      {props.children}
      {props.isRequired ? (
        props.requiredText ? (
          <FormLabelRequiredText isDisabled={props.isDisabled}>
            {props.requiredText}
          </FormLabelRequiredText>
        ) : (
          <FormLabelRequiredText isDisabled={props.isDisabled} />
        )
      ) : null}
    </label>
  );
};
