import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgFileGuideFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M13 3.89v17.53l9-1.59V2.31l-9 1.58Zm6.5 10.85-4 .71v-2.03l4-.71v2.03Zm0-4.06-4 .71V9.35l4-.71v2.04ZM2 19.84l9 1.59V3.89L2 2.31v17.53Z"
    />
  </svg>
);
export default SvgFileGuideFill;
