import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavVersions = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M6 2h12v2H6V2ZM4 6h16v2H4V6ZM3 10v8h2v-6h14v8H3v2h18V10H3Z"
    />
  </svg>
);
export default SvgNavVersions;
