import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionRename = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M16.59 8.5 6.09 19H4v-2.09L15.92 5l3.49 3.5 1.42-1.42-4.91-4.91L2 16.09V21h4.91L18 9.91 16.59 8.5ZM21.43 19.43c-.65.46-1.12.58-1.43.58-.4 0-.54-.12-.94-1.26-.35-1-.83-2.37-2.42-2.69-2.51-.5-4.51 1.59-7.63 4.94h2.74c1.79-1.89 3.26-3.23 4.49-2.98.43.09.61.49.93 1.39.36 1.04.91 2.6 2.83 2.6.91 0 1.85-.39 2.86-1.16l-1.43-1.42Z"
    />
  </svg>
);
export default SvgActionRename;
