import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavHomeFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m21 10.35 2 1.15V9.2l-2-1.16-.07-.04H21V5h-2v1.88l-7-4.03L1 9.2v2.3l2-1.15V21h18V10.35Z"
    />
  </svg>
);
export default SvgNavHomeFill;
