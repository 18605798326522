import { useEffect, useState } from 'react';

const focusableNodeSelector = [
  'a[href]',
  'button',
  'input',
  'textarea',
  'select',
  'details',
  '[tabindex]',
].join(', ');

const enabledSelector = ':not([disabled]):not([tabindex="-1"])';

const findFocusableNodesWithin = (contextNode: HTMLDivElement | Document) => {
  return Array.from(
    contextNode.querySelectorAll<HTMLElement>(focusableNodeSelector)
  ).filter((node) => {
    return node.matches(enabledSelector);
  });
};

// Find the first or last node within the modal that can receive focus
// based on whether `focusTarget` appears before or after the modal node
// within the DOM.
const findModalNodeToFocus = (
  modalNode: HTMLDivElement,
  focusTarget: HTMLElement
) => {
  const allFocusableNodes = findFocusableNodesWithin(document);
  const focusableNodesWithinModal = findFocusableNodesWithin(modalNode);
  const firstFocusableModalNode = focusableNodesWithinModal[0];
  const lastFocusableModalNode =
    focusableNodesWithinModal[focusableNodesWithinModal.length - 1];
  if (!firstFocusableModalNode) {
    return undefined;
  }
  const focusTargetIndex = allFocusableNodes.indexOf(focusTarget);
  const firstFocusableModalNodeIndex = allFocusableNodes.indexOf(
    firstFocusableModalNode
  );
  return focusTargetIndex < firstFocusableModalNodeIndex
    ? lastFocusableModalNode
    : firstFocusableModalNode;
};

export const useFocusTrap = (modalRef: React.RefObject<HTMLDivElement>) => {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    if (!hasMounted && modalRef.current) {
      const firstFocusableModalNode = findFocusableNodesWithin(
        modalRef.current
      )[0];
      if (firstFocusableModalNode) {
        firstFocusableModalNode.focus();
      }
      setHasMounted(true);
    }
    const onFocusIn = (e: FocusEvent) => {
      const focusTarget = e.target as HTMLElement;
      const modalNode = modalRef.current;
      if (focusTarget && modalNode && !modalNode.contains(focusTarget)) {
        const modalNodeToFocus = findModalNodeToFocus(modalNode, focusTarget);
        if (modalNodeToFocus) {
          modalNodeToFocus.focus();
        }
      }
    };
    document.addEventListener('focusin', onFocusIn);
    return () => {
      document.removeEventListener('focusin', onFocusIn);
    };
  }, [hasMounted, setHasMounted, modalRef]);
};
