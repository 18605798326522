/** @jsx isolateComponent */
import { Colors, Typography } from '../../../theme';
import { isolateComponent, labelStyles } from '@zapier/style-encapsulation';

type Props = {
  /**
   * The content to render within `FormLabelRequiredText`.
   *
   * @default Required
   */
  children?: React.ReactNode;

  /**
   * Whether or not this should render with "disabled" styling.
   *
   * @default false
   */
  isDisabled?: boolean;
};

const Styles = labelStyles('FormLabelRequiredText', {
  root: {
    ...Typography.smallPrint1,
    color: Colors.neutral700,
    textTransform: 'lowercase',
    '&::before': {
      content: '"("',
    },
    '&::after': {
      content: '")"',
    },

    '&[data-state="disabled"]': {
      color: Colors.neutral500,
    },
  },
});

const defaultProps = {
  children: 'Required',
  isDisabled: false,
};

/**
 * `FormLabelRequiredText` indicates whether a field is required.
 * Should always be a descendant of a `Label`.
 */
export const FormLabelRequiredText = (_props: Props) => {
  const props = {
    ...defaultProps,
    ..._props,
  };
  return (
    // `aria-hidden` to prevent it from being read redundantly.
    // The associated `input` field should indicate whether it's
    // required to screenreaders.
    <span
      aria-hidden="true"
      css={Styles.root}
      data-state={(props.isDisabled && 'disabled') || undefined}
    >
      {props.children}
    </span>
  );
};
