import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavInterfacesFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      fillRule="evenodd"
      d="M22 2H2v20h20V2Zm0 16.786L12 10v12l3.636-3.074.196-.15 6.168.01ZM8 6H6v2h2V6Zm2 0h8v2h-8V6Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNavInterfacesFill;
