import { CookieCategoryName, canWriteCookieCategory } from './categories';

export type WriteCookieOptions = {
  // This is a special option that describes what the cookie is
  // used for, which determines whether the cookie can be set
  // based on user consent.
  category: CookieCategoryName;
  domain?: string;
  expires?: Date | string | number;
  maxAge?: number;
  path?: string;
  sameSite?: 'lax' | 'strict' | 'none';
  secure?: boolean;
};

export type WriteCookieOptionsKey = keyof WriteCookieOptions;

export type WriteCookieOptionsValue = WriteCookieOptions[WriteCookieOptionsKey];

const optionNamesMap = {
  category: null,
  domain: 'domain',
  expires: 'expires',
  maxAge: 'max-age',
  path: 'path',
  sameSite: 'samesite',
  secure: 'secure',
};

const formatCookieOptionName = (name: WriteCookieOptionsKey) => {
  return optionNamesMap[name] || name;
};

// Bunch of `undefined`s to satisfy TS.
const optionValuesMap = {
  category: null,
  domain: undefined,
  expires: (date: NonNullable<WriteCookieOptions['expires']>) =>
    new Date(date).toUTCString(),
  maxAge: undefined,
  path: undefined,
  sameSite: undefined,
  secure: (_isSecure: WriteCookieOptions['secure']) => undefined,
};

const formatCookieOptionValue = (name: WriteCookieOptionsKey, value: any) => {
  // Separate condition instead of `||` because `optionValuesMap[name]`
  // could intentionally return a falsey value, and `||` would cause it
  // to be ignored and replaced with `value`.
  if (optionValuesMap[name]) {
    return optionValuesMap[name]!(value);
  }
  return value;
};

const stringifyOptions = (options: WriteCookieOptions) => {
  return Object.entries(options)
    .filter(([name, value]) => {
      return name !== 'category' && value !== false;
    })
    .map(([name, value]) => {
      const formattedName = formatCookieOptionName(
        name as WriteCookieOptionsKey
      );
      const formattedValue = formatCookieOptionValue(
        name as WriteCookieOptionsKey,
        value as WriteCookieOptionsValue
      );
      return formattedValue === undefined
        ? `; ${formattedName}`
        : `; ${formattedName}=${formattedValue}`;
    }, [])
    .join('')
    .trim();
};

export const writeCookie = (
  name: string,
  value: string,
  options: WriteCookieOptions
) => {
  if (typeof document === 'undefined') {
    return false;
  }
  try {
    const encodedName = encodeURIComponent(name);
    const encodedValue = encodeURIComponent(value);
    const stringifiedOptions = stringifyOptions(options);
    if (canWriteCookieCategory(options.category)) {
      document.cookie = `${encodedName}=${encodedValue}${stringifiedOptions}`.trim();
      return true;
    }
    console.warn(
      `writeCookie :: Could not write cookie ${name} in unconsented category ${options.category}.`
    );
    return false;
  } catch (e) {
    console.error(`writeCookie :: Could not write cookie ${name}.`, e);
  }
  return false;
};
