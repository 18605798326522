import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscChatBot = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#201515"
      fillRule="evenodd"
      d="M13.5 2h-2v2h-9v5h2V6h16v12h-16v-7h-2v9h20V4h-9V2ZM8.9 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm9 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-5.396 6.416c1.881 0 3.625-.88 4.803-2.416l1.193.982C17.04 15.9 14.852 17 12.504 17c-2.348 0-4.536-1.101-6.004-3.018L7.7 13c1.179 1.537 2.922 2.416 4.804 2.416ZM14.89 9.89l-3.366 4.01v-2.843H9.909l3.366-4.01V9.89h1.616Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMiscChatBot;
