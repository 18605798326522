import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscMicFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path fill="#2D2E2E" d="M16 5.91c0-2.21-1.79-4-4-4s-4 1.79-4 4v9h8v-9Z" />
    <path
      fill="#2D2E2E"
      d="M18 10.91v3.76c0 1.23-1 2.24-2.24 2.24H8.24C7 16.91 6 15.91 6 14.67v-3.76H4v3.76c0 2.34 1.9 4.24 4.24 4.24H11v3h2v-3h2.76c2.34 0 4.24-1.9 4.24-4.24v-3.76h-2Z"
    />
  </svg>
);
export default SvgMiscMicFill;
