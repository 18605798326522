import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscAiFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M6.159 7.143 9.15 1.997h1.729l2.992 5.146 5.146 2.993v1.729l-5.146 2.992-2.992 5.146H9.15L6.16 14.857l-5.146-2.992v-1.73l5.146-2.992ZM20.797 18.194l-1.28-2.203-1.282 2.203-2.202 1.28 2.202 1.282 1.281 2.203 1.281-2.203L23 19.475l-2.203-1.281Z"
    />
  </svg>
);
export default SvgMiscAiFill;
