import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscHand = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M3 14.3c0 1.97.68 3.9 1.91 5.44A8.657 8.657 0 0 0 11.7 23H15c3.31 0 6-2.69 6-6V7h-2v4.81l-2-.35V3h-2v8.1l-2-.35V1h-2v9.4l-2-.35V2H7v13h2v-2.92l10 1.76V17c0 2.21-1.79 4-4 4h-3.3a6.66 6.66 0 0 1-5.23-2.52A6.696 6.696 0 0 1 5 14.3V11H3v3.3Z"
    />
  </svg>
);
export default SvgMiscHand;
