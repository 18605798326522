import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionPhotoFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M21 21V3H3v18h18ZM15.25 6.5c1.24 0 2.25 1.01 2.25 2.25S16.49 11 15.25 11 13 9.99 13 8.75s1.01-2.25 2.25-2.25ZM5 12h4.98l2.52 3H19v4H5v-7Z"
    />
  </svg>
);
export default SvgActionPhotoFill;
