import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionExploreFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m6.56 6.56-4 14.85 14.85-4 4-14.85-14.85 4ZM12 14.25a2.25 2.25 0 1 1 0-4.501 2.25 2.25 0 0 1 0 4.501Z"
    />
  </svg>
);
export default SvgActionExploreFill;
