import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgRatingWinkAlt = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M16.5 9.75a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5ZM18.28 12.95C16.74 14.89 14.46 16 12 16c-2.46 0-4.74-1.11-6.28-3.05l-1.57 1.24C6.07 16.61 8.93 18 12 18a9.94 9.94 0 0 0 7.84-3.81l-1.56-1.24ZM10 7H6v2h4V7Z"
    />
  </svg>
);
export default SvgRatingWinkAlt;
