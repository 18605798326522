/** @jsx isolateComponent */

import { css } from '@emotion/react';
import { isolateComponent, labelStyles } from '@zapier/style-encapsulation';
import { Icon, IconName } from '../../display/Icon';
import { Spinner } from '../../display/Spinner';
import { TextInput } from '../../forms/TextInput';
import { camelCaseAriaAttrs } from '../../../utils/formatAriaAttrKeys';
import { Animation, Colors } from '../../../theme';

import { GetInputPropsOptions } from 'downshift';

type Props<T = any> = {
  /**
   * Browser `autocomplete` form field attribute.
   */
  autoComplete?: string;
  /**
   * Accessibility label that describes this component. This should be supplied
   * if no corresponding <label> element is used.
   */
  ariaLabel?: string;
  /**
   * Accessibility label referencing the ID of an element which describes this
   * component.
   */
  ariaLabelledBy: string | null;
  /**
   * Callback provided by Downshift which clears the typeahead selection.
   */
  clearSelection: () => void;
  /**
   * The text that renders in a `Tooltip` when the input `isDisabled`.
   * Only renders when `isDisabled`.
   */
  disabledText?: string;
  /**
   * Callback provided by Downshift which provides props which must be spread
   * into the `input` element.
   */
  getInputProps: (options: GetInputPropsOptions) => GetInputPropsOptions;
  /**
   * An optional icon name to render on the right side of the input element.
   * Only visible when the X clear selection is not shown.
   */
  iconEndName?: IconName;
  /**
   * The current value of the input, provided by Downshift.
   */
  inputValue: string | null;
  /**
   * Displays an inline loading spinner in the place of the left icon if set to true.
   */
  isBusy?: boolean;
  /**
   * Disables the input and makes it non-interactive.
   */
  isDisabled?: boolean;
  /**
   * Indicates whether the field has an error.
   */
  isErrored?: boolean;
  /**
   * Optional callback that may be provided by Downshift to open the menu.
   */
  onFocus?: () => void;
  /**
   * Placeholder text to render inside the `input` element toggle when no item
   * has been selected.
   */
  placeholder: string;
  /**
   * The currently selected item.
   */
  selectedItem: T | null;
  /**
   * Callback that will be used to generate the string label to be rendered for
   * the currently selected item. Provided by Downshift.
   */
  getLabelForItem: (item: T) => string;
  /**
   * A render prop which receives a menu `item` and the `getLabelForItem`
   * function and returns a React element, which is an icon illustrating the
   * given menu item. The natural size for the icon is 30x30px. If not
   * provided, no icons will be rendered.
   */
  renderIcon?: (
    item: T,
    getLabelForItem: (item: T) => string
  ) => React.ReactElement | null;
  /**
   * The rendered size of the input.
   */
  size?: 'small' | 'medium';
};

const Styles = labelStyles('TypeaheadInput', {
  clearSelectionButton: css`
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;

    &:hover,
    &:focus {
      color: ${Colors.neutral600};
      transition: ${Animation.transitionValue};
    }
  `,
});

/**
 * The icon which sits inside the input field.
 */
const ClearSelectionButton = ({
  clearSelection,
}: {
  clearSelection: () => void;
  isFocused: boolean;
  isHovered: boolean;
}) => {
  return (
    <button
      type="button"
      css={Styles.clearSelectionButton}
      onClick={clearSelection}
      title="Clear Selection"
    >
      <Icon canAcceptPointerEvents={false} name="x" size={20} />
    </button>
  );
};

type InputIconProps = Props & {
  iconSize: number;
};
/**
 * The icon which sits inside the input field on the left side.
 */
const InputIcon = (props: InputIconProps) => {
  return props.renderIcon && props.selectedItem ? (
    props.renderIcon(props.selectedItem, props.getLabelForItem)
  ) : (
    // Search icon also handles the use case where an item has been
    // selected but props.renderIcon has not been specified.
    <Icon name="magnifyingGlass" size={props.iconSize} />
  );
};

export const TypeaheadInput = ({ size = 'small', ...props }: Props) => {
  const showClearSelectionButton = props.selectedItem && !props.isDisabled;

  return (
    <TextInput
      {...camelCaseAriaAttrs(
        props.getInputProps({
          // @ts-ignore this is valid, but downshift TS types are wrong
          ariaLabelledBy: props.ariaLabelledBy,
          autoComplete: props.autoComplete,
          onFocus: props.onFocus,
        })
      )}
      ariaLabel={props.ariaLabel}
      autoComplete="off"
      canClickIconAfter={showClearSelectionButton}
      disabledText={props.disabledText}
      isDisabled={props.isDisabled}
      isErrored={props.isErrored}
      placeholder={props.placeholder}
      renderIconAfter={({ iconSize, isFocused, isHovered }) =>
        showClearSelectionButton ? (
          <ClearSelectionButton
            clearSelection={props.clearSelection}
            isFocused={isFocused}
            isHovered={isHovered}
          />
        ) : (
          props.iconEndName && <Icon name={props.iconEndName} size={iconSize} />
        )
      }
      renderIconBefore={({ iconSize }: { iconSize: number }) => {
        if (props.isBusy) {
          return (
            <span style={{ width: iconSize }}>
              <Spinner />
            </span>
          );
        }
        return <InputIcon {...props} iconSize={iconSize} />;
      }}
      size={size}
    />
  );
};
