import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgArrowUndo = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      fillRule="evenodd"
      d="M9.97 12 7.45 9h7.05a4.5 4.5 0 1 1 0 9H14v2h.5a6.5 6.5 0 0 0 0-13H7.45l2.52-3H7.36L4 8l3.36 4h2.61Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowUndo;
