/** @jsx isolateComponent */
import { css } from '@emotion/react';
import { isolateComponent, labelStyles } from '@zapier/style-encapsulation';

import { createUniqueCustomProperties } from '../../../utils/createUniqueCustomProperty';
import { Animation, Colors, Shadows, Typography } from '../../../theme';
import { Link } from '../Link';

type Props = {
  /**
   * The content to render within `InteractiveTag`.
   */
  children: React.ReactNode;
  /**
   * The color of `InteractiveTag`.
   */
  color: 'primary' | 'secondary';
  /**
   * Optional `href` attribute to apply to `InteractiveTag`, which
   * will make it render as a link.
   */
  href?: string;
  /**
   * Optional function to call when `InteractiveTag` is clicked.
   */
  onClick?: React.MouseEventHandler;
};

const Vars = createUniqueCustomProperties('InteractiveTag', [
  'backgroundColor',
  'backgroundColorHover',
  'backgroundColorFocus',
  'color',
  'colorHover',
  'colorFocus',
  'outlineColor',
  'outlineOpacity',
]);

const Styles = labelStyles('InteractiveTag', {
  root: css`
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 11px 20px;
    height: 44px;
    border-radius: 30px;
    ${Typography.paragraph3Semibold};
    // Explicitly set 'line-height' to improve vertical alignment
    // in Safari.
    line-height: 44px;
    white-space: nowrap;
    transition: ${Animation.transitionValue};
    color: var(${Vars.color});
    background-color: var(${Vars.backgroundColor});
    text-decoration: none;

    &:hover {
      color: var(${Vars.colorHover});
      background-color: var(${Vars.backgroundColorHover});
      box-shadow: ${Shadows.elevation6};
    }

    &:focus {
      color: var(${Vars.colorFocus});
      background-color: var(${Vars.backgroundColorFocus});
      ${Vars.outlineOpacity}: 1;
      outline: none;
    }

    &[data-color='primary'] {
      border: 1px solid ${Colors.neutral600};
      ${Vars.color}: ${Colors.neutral800};
      ${Vars.colorHover}: ${Colors.blue};
      ${Vars.colorFocus}: ${Colors.blue};
      ${Vars.outlineColor}: ${Colors.blue};
      ${Vars.backgroundColor}: ${Colors.neutral200};
      ${Vars.backgroundColorHover}: ${Colors.neutral200};
      ${Vars.backgroundColorFocus}: ${Colors.neutral200};
      &:hover {
        border: 1px solid ${Colors.blue};
      }
      &:focus {
        border: none;
      }
    }

    &[data-color='secondary'] {
      ${Vars.color}: ${Colors.neutral100};
      ${Vars.colorHover}: ${Colors.neutral100};
      ${Vars.colorFocus}: ${Colors.neutral100};
      ${Vars.backgroundColor}: ${Colors.neutral800};
      ${Vars.outlineColor}: ${Colors.neutral800};
      ${Vars.backgroundColorHover}: ${Colors.neutral700};
      ${Vars.backgroundColorFocus}: ${Colors.neutral800};
    }

    &::before {
      content: '';
      opacity: var(${Vars.outlineOpacity}, 0);
      position: absolute;
      top: -4px;
      right: -4px;
      bottom: -4px;
      left: -4px;
      border: 2px solid var(${Vars.outlineColor});
      border-radius: inherit;
      transition: inherit;
    }
  `,
});

/**
 * A clickable tag-like component used to denote information under a headline or subheadline.
 */
export const InteractiveTag = (props: Props) => {
  const sharedProps = {
    css: Styles.root,
    'data-color': props.color,
    onClick: props.onClick,
  };
  if (props.href) {
    return (
      <Link {...sharedProps} color={null} href={props.href} weight={null}>
        {props.children}
      </Link>
    );
  }
  return (
    <button {...sharedProps} type="button">
      {props.children}
    </button>
  );
};
