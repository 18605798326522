import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscUptimeFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M18 11h3.95c-.5-5.05-4.77-9-9.95-9C6.49 2 2 6.49 2 12s4.49 10 10 10c.34 0 .67-.02 1-.05v-6.63L18 11Zm-8.82 4.76-1.31-1.51 3.13-2.7V7h2v5.46l-3.82 3.3Z"
    />
    <path
      fill="#2D2E2E"
      d="M22 11a10 10 0 1 0-10 11h1v-6.75l5-4.2 4-.05Zm-9 1.47-3.93 3.3-1.29-1.53L11 11.53V7h2v5.47Zm6 .55-4 3.36v2l3.25-2.73V22h1.5v-6.39L23 18.34v-2l-4-3.32Z"
    />
  </svg>
);
export default SvgMiscUptimeFill;
