import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavInterfacesV2 = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path fill="#2D2E2E" d="M7.5 7.5v9h9V21H3V3h18v13.5h-4.5v-9h-9Z" />
  </svg>
);
export default SvgNavInterfacesV2;
