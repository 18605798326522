import { useEffect, useState } from 'react';
import { readCookieCategories } from './categories';

export const useCookieConsent = () => {
  const [categories, setCategories] = useState(() => {
    return readCookieCategories();
  });
  useEffect(() => {
    // This event is dispatched on `window` by OneTrust whenever
    // a user changes their consent.
    const eventName = 'consent.onetrust';
    const onConsentChanged = () => {
      setCategories(readCookieCategories());
    };
    window.addEventListener(eventName, onConsentChanged);
    return () => {
      window.removeEventListener(eventName, onConsentChanged);
    };
  });
  return categories;
};
