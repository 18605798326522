import { useEffect, useState } from 'react';

type ReturnValue = [boolean, (node: HTMLElement | null) => void];

export const useOverflow = (): ReturnValue => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [node, setNode] = useState<HTMLElement | null>(null);
  useEffect(() => {
    if (node) {
      const offsetHeight = node.offsetHeight;
      const scrollHeight = node.scrollHeight;
      // The 2 is for Firefox which adds height for code tag styling
      if (scrollHeight - offsetHeight > 2) {
        setIsOverflowing(true);
      }
    }
  }, [node]);
  return [isOverflowing, setNode];
};
