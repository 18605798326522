import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscMic = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M16 6c0-2.21-1.79-4-4-4S8 3.79 8 6v9h8V6Zm-2 7h-4V6c0-1.1.9-2 2-2s2 .9 2 2v7Z"
    />
    <path
      fill="#2D2E2E"
      d="M18 11v3.76C18 16 17 17 15.76 17H8.24C7 17 6 16 6 14.76V11H4v3.76C4 17.1 5.9 19 8.24 19H11v3h2v-3h2.76C18.1 19 20 17.1 20 14.76V11h-2Z"
    />
  </svg>
);
export default SvgMiscMic;
