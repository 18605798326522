import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgRatingThumbsDownFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M20 4v11h2V4h-2ZM2 10.6V14a3 3 0 0 0 3 3h4v3a2 2 0 0 0 2 2h1.43L18 15.36V4H7.53L2 10.6Z"
    />
  </svg>
);
export default SvgRatingThumbsDownFill;
