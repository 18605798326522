import { useEffect, useState } from 'react';

type OutsideEvent = MouseEvent | TouchEvent;

export type OnClickOutside = (event: OutsideEvent) => void;

type UseClickOutsideReturn = [(element: Element | null) => void];

export const useClickOutside = ({
  onClickOutside,
}: {
  onClickOutside: OnClickOutside;
}): UseClickOutsideReturn => {
  const [node, setNode] = useState<Node | null>(null);
  useEffect(() => {
    const onClick = (event: OutsideEvent) => {
      if (onClickOutside && node && !node.contains(event.target as Node)) {
        onClickOutside(event);
      }
    };
    // Checks for SSR even though `useEffect` won't run server-side.
    // Better safe than lo siento.
    const body = document && document.body;
    if (body) {
      // Binding to `document.body` so clicks that initiated rendering
      // a component that uses `useEffect` aren't still bubbling and then
      // fire `onClick` above.
      body.addEventListener('click', onClick, { capture: true });
      body.addEventListener('touchend', onClick, { capture: true });
      return () => {
        body.removeEventListener('click', onClick, { capture: true });
        body.removeEventListener('touchend', onClick, { capture: true });
      };
    }

    return () => {};
  }, [node, onClickOutside]);
  return [setNode];
};
