/** @jsx isolateComponent */

import React from 'react';
import { FloatingBox } from '../../display/FloatingBox';
import { Colors, Typography } from '../../../theme';
import {
  isolateComponent,
  labelStyles,
  withModifier,
} from '@zapier/style-encapsulation';

import {
  FloatingMenuGetLabelForItem,
  FloatingMenuItem,
  GetMenuPropsReturn,
  GetItemPropsReturn,
  GetInputPropsOptions,
} from './types';
import { css } from '@emotion/react';

export type Props = {
  align?: 'right' | 'left' | 'stretch';
  /**
   * Accessibility label that describes the options available inside the select
   * menu, e.g. "List of items", "List of Countries", etc.
   */
  ariaLabel: string;
  /**
   * Callback that should be passed to the menu root element. Provided by
   * Downshift.
   */
  getMenuProps: (options: { 'aria-label': string }) => GetMenuPropsReturn;
  /**
   * Callback that should be passed to each menu item. Provided by Downshift.
   */
  getItemProps: (options: {
    item: FloatingMenuItem;
    disabled: boolean;
  }) => GetItemPropsReturn;

  getInputProps?: <T>(options?: T | undefined) => T & GetInputPropsOptions;
  /**
   * Callback that will be used to generate the react `key` to be used for each
   * item on the menu list.
   */
  getKeyForItem?: (item: FloatingMenuItem) => string | number;
  /**
   * Callback that will be used to generate the string label to be rendered for each item on the menu list.
   * Defaults to a `label` property on each item object.
   */
  getLabelForItem: FloatingMenuGetLabelForItem;
  /**
   * The menu item index that should be highlighted. Provided by Downshift.
   */
  highlightedIndex: number | null;
  /**
   * The list of items to display in the menu.
   */
  items: Array<FloatingMenuItem>;
  /** Optional `maxWidth` for `FloatingBox`. */
  maxWidth?: string;

  /** Optional `minWidth` for `FloatingBox` */
  minWidth?: string;
  /**
   * A render prop which receives a menu `item` and the `getLabelForItem`
   * function and returns a React element, which is an icon illustrating the
   * given menu item. The natural size for the icon is 30x30px. If not
   * provided, no icons will be rendered.
   */
  renderIcon?: (
    item: FloatingMenuItem,
    getLabelForItem: FloatingMenuGetLabelForItem
  ) => React.ReactNode;
  /**
   * Total number of menu items to show before scroll.
   * Will set height of menu.
   */
  visibleItems?: number;
};

const MENU_ITEM_HEIGHT = 40;

const Styles = labelStyles('FloatingMenu', {
  list: ({ visibleItems }: Props) => [
    {
      maxHeight: ((visibleItems || 0) + 0.5) * MENU_ITEM_HEIGHT + 10, // add extra height to suggest scrollability for overflow
      overflow: 'auto',
      padding: 10,
    },
  ],

  menuItem: ({
    isDisabled,
    isHighlighted,
  }: {
    isDisabled: boolean;
    isHighlighted: boolean;
  }) => [
    {
      ...Typography.paragraph3Medium,
      alignItems: 'center',
      borderRadius: 3,
      cursor: 'pointer',
      display: 'flex',
      height: MENU_ITEM_HEIGHT,
      padding: '0 10px',
      color: Colors.neutral700,
    },

    isDisabled &&
      withModifier('disabled', {
        ...Typography.paragraph3Medium,
        color: Colors.neutral500,
      }),

    isHighlighted &&
      withModifier('enabled-highlighted', {
        ...Typography.paragraph3Medium,
        backgroundColor: Colors.blue10,
        color: Colors.blue,
      }),
  ],

  menuItemIcon: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  }),

  menuItemText: css({
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
});

export const FloatingMenu = (_props: Props) => {
  const props = {
    align: 'stretch' as 'stretch',
    visibleItems: 4,
    ..._props,
  };

  const {
    align,
    ariaLabel,
    getItemProps,
    getKeyForItem,
    getLabelForItem,
    getMenuProps,
    highlightedIndex,
    items,
    maxWidth,
    minWidth,
  } = props;

  return (
    <FloatingBox align={align} maxWidth={maxWidth} minWidth={minWidth}>
      <ul
        css={Styles.list(props)}
        {...getMenuProps({ 'aria-label': ariaLabel })}
      >
        {items.map((item: FloatingMenuItem, index: number) => (
          <li
            {...getItemProps({ item, disabled: item.disabled })}
            css={Styles.menuItem({
              isDisabled: item.disabled,
              isHighlighted: highlightedIndex === index,
            })}
            key={getKeyForItem ? getKeyForItem(item) : index}
          >
            {props.renderIcon && (
              <span css={Styles.menuItemIcon}>
                {props.renderIcon(item, props.getLabelForItem)}
              </span>
            )}

            <span css={Styles.menuItemText}>{getLabelForItem(item)}</span>
          </li>
        ))}
      </ul>
    </FloatingBox>
  );
};
