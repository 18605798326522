import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionSwitchAccountFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M21.67 9.5A10 10 0 1 0 12 22h.5v-2c-.167.01-.334.01-.5 0a8 8 0 0 1-6.36-3.17L6.3 15h6.2v-1.55a4.214 4.214 0 0 1-.5.05 3.75 3.75 0 1 1 3.72-4h5.95Zm.59 8A3.26 3.26 0 0 1 19 20.75h-1.93L18.54 19h-2l-2.1 2.5 2.1 2.5h2l-1.47-1.75H19a4.75 4.75 0 0 0 4.75-4.75h-1.49ZM19 14.25h1.94L19.46 16h2l2.1-2.5-2.1-2.5h-2l1.47 1.75H19a4.75 4.75 0 0 0-4.75 4.75h1.5A3.26 3.26 0 0 1 19 14.25Z"
    />
  </svg>
);
export default SvgActionSwitchAccountFill;
