import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavExploreFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M2.05 12.97c.39 4 3.14 7.31 6.84 8.53l-3.1-8.52-3.74-.01ZM7.92 12.98l3.27 8.98c.67.05.94.06 1.62 0l3.26-8.97-8.15-.01ZM15.11 21.5c3.69-1.21 6.45-4.51 6.84-8.5H18.2l-3.09 8.5ZM21.95 11c-.4-3.99-3.15-7.29-6.84-8.5L18.2 11h3.75ZM16.07 10.99l-3.26-8.95c-.67-.05-.94-.06-1.62 0l-3.25 8.94 8.13.01ZM8.89 2.5C5.21 3.71 2.46 7 2.05 10.97H5.8L8.89 2.5Z"
    />
  </svg>
);
export default SvgNavExploreFill;
