// Based on https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
// Simple hashing function to produce a short, unique value
// from `str`. Don't use for anything secure, use for things
// like avoiding collisions.
export const hashString = (str: string) => {
  let hash = 0;
  let i = 0;
  let chr: number | null = null;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash).toString(16);
};
