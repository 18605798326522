import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgArrowRefresh = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M12 4H7.1l2.52-3H7L3.65 5 7 9h2.62L7.1 6H12a7 7 0 1 1-7 7H3a9 9 0 1 0 9-9Z"
    />
  </svg>
);
export default SvgArrowRefresh;
