import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgFileRemoveFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      fillRule="evenodd"
      d="M14 2v6h6v14H4V2h10ZM8 15h8v-2H8v2Zm7.5-8.5V2.09l4.41 4.41H15.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFileRemoveFill;
