import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgArrowZoom = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m16 2-2 2h4.59l-4.3 4.29 1.42 1.42L20 5.41V10l2-2V2h-6ZM2 2v6l2 2V5.41l4.29 4.3 1.42-1.42L5.41 4H10L8 2H2ZM20 18.59l-4.29-4.3-1.42 1.42 4.3 4.29H14l2 2h6v-6l-2-2v4.59ZM9.71 15.71l-1.42-1.42L4 18.59V14l-2 2v6h6l2-2H5.41l4.3-4.29Z"
    />
  </svg>
);
export default SvgArrowZoom;
