import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionEditFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M3 16.09V21h4.91L19 9.91 14.09 5 3 16.09ZM16.92 2.17l-1.41 1.41 4.91 4.91 1.41-1.41-4.91-4.91Z"
    />
  </svg>
);
export default SvgActionEditFill;
