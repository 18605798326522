/** @jsx isolateComponent */
import { Children, useState, useRef, useEffect } from 'react';
import { css, keyframes } from '@emotion/react';

import { isolateComponent, labelStyles } from '@zapier/style-encapsulation';

import { Animation, Colors, Zindexes } from '../../../theme';
import { Icon } from '../../display/Icon';

import { useUnscrollableBody } from './useUnscrollableBody';
import { useFocusTrap } from './useFocusTrap';

type Props = {
  /**
   * The description of the modal modal to be read by screen readers
   * when `Modal` opens.
   */
  ariaLabel?: string;
  /**
   * Indicates whether `Modal` can be closed. When `false`,
   * no close button will render and clicking the overlay will not
   * close `Modal`. The code rendering `Modal` must manually
   * close it.
   */
  canClose?: boolean;
  /**
   * Indicates whether `document.body` can still be scrolled.
   */
  canScrollBody?: boolean;
  /**
   * The content to render inside of `Modal`.
   */
  children: JSX.Element;
  /**
   * Function called when the modal has finished closing.
   */
  onClosed: () => void;
};

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const Styles = labelStyles('Modal', {
  root: css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    // Setting height is redundant in most browsers since top/bottom are set,
    // but necessary for Safari to properly apply align-items.
    height: 100%;
    z-index: ${Zindexes.modalOverlay};
    opacity: 0;
    display: grid;
    align-items: center;
    justify-items: center;
  `,

  rootIn: css`
    animation: ${fadeIn} ${Animation.transitionDuration}
      ${Animation.transitionTimingFunction} forwards;
  `,

  rootOut: css`
    animation: ${fadeOut} ${Animation.transitionDuration}
      ${Animation.transitionTimingFunction} forwards;
  `,

  overlay: css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${Colors.neutral800};
    opacity: 0.8;
    z-index: 1;
  `,

  closeButton: css`
    display: block;
    color: ${Colors.neutral600};
    transition: ${Animation.transitionValue};
    &:hover,
    &:focus {
      color: ${Colors.blue};
    }
  `,

  modal: css`
    position: relative;
    z-index: 2;
    max-height: 100vh;
    // Prevent margin collapsing of children
    padding: 1px;
    // Ensure children shink by default
    display: flex;
  `,
});

export const Modal = (props: Props) => {
  // Normalize `true`, `undefined`, `null`.
  const canClose = props.canClose !== false;
  const [isOpen, setIsOpen] = useState(true);
  const modalRef = useRef<HTMLDivElement>(null);

  useUnscrollableBody({ shouldAllowScroll: props.canScrollBody === true });

  // Handle close request and kick off closing animation.
  const onClose = () => {
    if (canClose) {
      setIsOpen(false);
    }
  };

  // Once the closing animation has completed, call `onClosed`.
  const onAnimationEnd = () => {
    if (isOpen === false) {
      props.onClosed();
    }
  };

  // Close `Modal` when escape key is pressed. No need
  // to worry about `canClose` here since it's handled elsewhere.
  useEffect(() => {
    const onEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keyup', onEscape, { capture: true });
    return () => {
      window.removeEventListener('keyup', onEscape, { capture: true });
    };
  });

  // Keep focus to nodes within the modal.
  useFocusTrap(modalRef);

  const overlay = (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div css={Styles.overlay} data-testid="ModalOverlay" onClick={onClose} />
  );

  const closeButton = !!canClose && (
    <button
      css={Styles.closeButton}
      aria-label="Close modal"
      onClick={onClose}
      type="button"
    >
      <Icon isBlock={true} name="formX" size={32} />
    </button>
  );

  // Pass `closeButton` as a prop to the only child. This allows
  // the child to render `closeButton` wherever it wants while still
  // managing styling and behavior within `Modal`, and it sidesteps
  // requiring a function as a child.
  const onlyChild = props.children && Children.only(props.children);
  const children = {
    ...onlyChild,
    props: {
      ...(props.children.props || {}),
      closeButton,
    },
  };

  const modal = (
    <div
      aria-label={props.ariaLabel}
      aria-live="polite"
      aria-modal="true"
      css={Styles.modal}
      ref={modalRef}
      role="dialog"
    >
      {children}
    </div>
  );

  return (
    <div
      css={[Styles.root, isOpen ? Styles.rootIn : Styles.rootOut]}
      data-testid="Modal"
      onAnimationEnd={onAnimationEnd}
    >
      <div>
        {overlay}
        {modal}
      </div>
    </div>
  );
};
