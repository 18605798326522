import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionMoveToFolderFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M22 4h-9.53l-1.26-1.5H2V20h20V4Zm-7.05 12h-2.61l2.52-3H6v-2h8.85l-2.52-3h2.61l3.36 4-3.35 4Z"
    />
  </svg>
);
export default SvgActionMoveToFolderFill;
