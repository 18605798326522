import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavBellFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M12 22.5a2.5 2.5 0 0 0 2.45-2h-4.9a2.5 2.5 0 0 0 2.45 2ZM19.73 14.94l-.46-6.19C18.99 4.96 15.79 2 12 2 8.21 2 5.01 4.96 4.73 8.75l-.46 6.19-1.77 2.1V19h19v-1.96l-1.77-2.1Z"
    />
  </svg>
);
export default SvgNavBellFill;
