// Takes a `name` for a modifier and a `styles` object
// and returns a wrapper object with the `&` key, which
// causes `emotion` to split the style definitions apart,
// thereby making them easier to diff in the dev tools.
// This function doesn't change specificity of the styles,
// it only separates them.
export const withModifier = (name: string | null, styles: {}) => ({
  '&[class]': {
    label: name && `-${name}`,
    ...styles,
  },
});
