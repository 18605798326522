import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscCookie = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M18 11.01v-5h-5v-4c-.53 0-4.77-.46-8.17 3.03A9.942 9.942 0 0 0 2 12.29c.14 5.21 4.5 9.57 9.71 9.71 1.01.03 4.36-.01 7.25-2.83A9.998 9.998 0 0 0 21.53 15h-2.15a8.13 8.13 0 0 1-1.81 2.74c-1.56 1.52-3.63 2.31-5.8 2.26-4.17-.11-7.65-3.59-7.77-7.76-.06-2.18.74-4.24 2.26-5.81A7.938 7.938 0 0 1 11 4.07v2.44c0 .83.67 1.5 1.5 1.5H16v3.5c0 .83.67 1.5 1.5 1.5h4.45l.05-2h-4Z"
    />
    <path
      fill="#2D2E2E"
      d="M12 13.26a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM7 14.51a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM10.75 18.26a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM15.5 16.76a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM8.5 9.76a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Z"
    />
  </svg>
);
export default SvgMiscCookie;
