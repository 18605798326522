import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgPersonAdd = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M9 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4Zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2ZM20 10V7h-2v3h-3v2h3v3h2v-3h3v-2h-3ZM3.3 14 .75 21h12.24l-.72-2H3.61l1.09-3h8.6l1.82 5h2.13l-2.55-7H3.3Z"
    />
  </svg>
);
export default SvgPersonAdd;
