import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgCommentConvoFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M18.5 7v9.5h-6.33L9.23 20h3.8l1.68 2H17v-2h6V7h-4.5Z"
    />
    <path fill="#2D2E2E" d="M17 15V2H1v13h6v3h1.95l2.52-3H17Z" />
  </svg>
);
export default SvgCommentConvoFill;
