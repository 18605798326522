import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgRatingStarFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m21.84 8.28-6.08-.89-2.67-5.41h-2.18L8.24 7.39l-6.08.89-.65 1.98 4.4 4.29-1.07 6.23 1.56 1.13 5.6-2.94 5.6 2.94 1.56-1.13-1.07-6.23 4.4-4.29-.65-1.98Z"
    />
  </svg>
);
export default SvgRatingStarFill;
