const defaultFont =
  'var(--zds-typography-base, "Inter", Helvetica, arial, sans-serif)';
const headingFont =
  'var(--zds-typography-heading, "Degular", Helvetica, arial, sans-serif)';
const displayFont =
  'var(--zds-typography-heading-display, "Degular Display", Helvetica, arial, sans-serif)';

/**
 * These styles should exactly match those in the Zapier Design System.
 * That said, this list is not exhaustive for some properties, so not every
 * permutation (e.g. fontWeight) is covered.
 *
 * It is completely acceptable to do something like this in your styles:
 *
 * myTextElement: {
 *   ...Typography.sectionHeader3,
 *   fontWeight: 400,
 * },
 */

export const Typography = {
  // For use cases where styles are unique but the same
  // `fontFamily` should be used.
  base: {
    fontFamily: defaultFont,
  },
  heading: {
    fontFamily: headingFont,
  },

  // Page headers
  pageHeader1: {
    fontFamily: displayFont,
    fontSize: 'var(--zds-typography-pageheader1-fontsize, 82px)',
    lineHeight: 'var(--zds-typography-pageheader1-lineheight, 84px)',
    fontWeight: 'var(--zds-typography-pageheader-weight, 700)' as 'bold',
    letterSpacing: 'var(--zds-typography-large-letter-spacing, 1px)',
  },
  pageHeader3: {
    fontFamily: displayFont,
    fontSize: 'var(--zds-typography-pageheader3-fontsize, 62px)',
    lineHeight: 'var(--zds-typography-pageheader3-lineheight, 66px)',
    fontWeight: 'var(--zds-typography-pageheader-weight, 700)' as 'bold',
    letterSpacing: 'var(--zds-typography-large-letter-spacing, 1px)',
  },
  pageHeader4: {
    fontFamily: displayFont,
    fontSize: 'var(--zds-typography-pageheader4-fontsize, 52px)',
    lineHeight: 'var(--zds-typography-pageheader4-lineheight, 60px)',
    fontWeight: 'var(--zds-typography-pageheader-weight, 700)' as 'bold',
    letterSpacing: 'var(--zds-typography-large-letter-spacing, 1px)',
  },
  pageHeader5: {
    fontFamily: headingFont,
    fontSize: 'var(--zds-typography-pageheader5-fontsize, 42px)',
    lineHeight: 'var(--zds-typography-pageheader5-lineheight, 50px)',
    fontWeight:
      'var(--zds-typography-semibold-weight, var(--zds-typography-pageheader-weight, 600))' as 'bold',
    letterSpacing: 'var(--zds-typography-large-letter-spacing, 1px)',
  },
  pageHeader7: {
    fontFamily: headingFont,
    fontSize: 'var(--zds-typography-pageheader7-fontsize, 34px)',
    lineHeight: 'var(--zds-typography-pageheader7-lineheight, 40px)',
    fontWeight: 'var(--zds-typography-semibold-weight, 600)' as 'normal',
    letterSpacing: 'var(--zds-typography-large-letter-spacing, 1px)',
  },
  pageHeader9: {
    fontFamily: headingFont,
    fontSize: 'var(--zds-typography-pageheader9-fontsize, 26px)',
    lineHeight: 'var(--zds-typography-pageheader9-lineheight, 32px)',
    fontWeight: 'var(--zds-typography-semibold-weight, 600)' as 'normal',
    letterSpacing: 'var(--zds-typography-large-letter-spacing, 1px)',
  },

  // Section headers
  sectionHeader1: {
    fontFamily: defaultFont,
    fontSize: 32,
    lineHeight: '35px',
    fontWeight: 700,
    letterSpacing: 'var(--zds-typography-small-letter-spacing, normal)',
  },
  sectionHeader1Extrabold: {
    fontFamily: defaultFont,
    fontSize: 32,
    lineHeight: '35px',
    fontWeight: 800,
    letterSpacing: 'var(--zds-typography-small-letter-spacing, normal)',
  },
  sectionHeader1Medium: {
    fontFamily: defaultFont,
    fontSize: 32,
    lineHeight: '35px',
    fontWeight: 500,
    letterSpacing: 'var(--zds-typography-small-letter-spacing, normal)',
  },
  sectionHeader1Bold: {
    fontFamily: defaultFont,
    fontSize: 32,
    lineHeight: '35px',
    fontWeight: 700,
    letterSpacing: 'var(--zds-typography-small-letter-spacing, normal)',
  },

  sectionHeader3: {
    fontFamily: defaultFont,
    fontSize: 24,
    lineHeight: '30px',
    fontWeight: 700,
    letterSpacing: 'var(--zds-typography-small-letter-spacing, normal)',
  },
  sectionHeader3Extrabold: {
    fontFamily: defaultFont,
    fontSize: 24,
    lineHeight: '30px',
    fontWeight: 800,
    letterSpacing: 'var(--zds-typography-small-letter-spacing, normal)',
  },
  sectionHeader3Semibold: {
    fontFamily: defaultFont,
    fontSize: 24,
    lineHeight: '30px',
    fontWeight: 600,
    letterSpacing: 'var(--zds-typography-small-letter-spacing, normal)',
  },
  sectionHeader3Bold: {
    fontFamily: defaultFont,
    fontSize: 24,
    lineHeight: '30px',
    fontWeight: 700,
    letterSpacing: 'var(--zds-typography-small-letter-spacing, normal)',
  },

  // Subheaders
  subHeader1: {
    fontFamily: defaultFont,
    fontSize: 24,
    lineHeight: '30px',
    fontWeight: 400,
  },
  subHeader1Semibold: {
    fontFamily: defaultFont,
    fontSize: 24,
    lineHeight: '30px',
    fontWeight: 600,
  },
  subHeader1Bold: {
    fontFamily: defaultFont,
    fontSize: 24,
    lineHeight: '30px',
    fontWeight: 700,
  },

  subHeader3: {
    fontFamily: defaultFont,
    fontSize: 22,
    lineHeight: '28px',
    fontWeight: 400,
  },
  subHeader3Medium: {
    fontFamily: headingFont,
    fontSize: 22,
    lineHeight: '28px',
    fontWeight: 500,
    letterSpacing: 'var(--zds-typography-large-letter-spacing, 1px)',
  },
  subHeader3Bold: {
    fontFamily: headingFont,
    fontSize: 22,
    lineHeight: '28px',
    fontWeight: 'var(--zds-typography-semibold-weight, 600)' as 'bold',
    letterSpacing: 'var(--zds-typography-large-letter-spacing, 1px)',
  },

  subHeader5: {
    fontFamily: defaultFont,
    fontSize: 20,
    lineHeight: '26px',
    fontWeight: 400,
  },
  subHeader5Medium: {
    fontFamily: headingFont,
    fontSize: 20,
    lineHeight: '26px',
    fontWeight: 500,
    letterSpacing: 'var(--zds-typography-large-letter-spacing, 1px)',
  },
  subHeader5Bold: {
    fontFamily: headingFont,
    fontSize: 20,
    lineHeight: '26px',
    fontWeight: 'var(--zds-typography-semibold-weight, 600)' as 'bold',
    letterSpacing: 'var(--zds-typography-large-letter-spacing, 1px)',
  },

  // Supertitles
  superTitle1: {
    fontFamily: defaultFont,
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  superTitle3: {
    fontFamily: defaultFont,
    fontSize: 12,
    lineHeight: '15px',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  superTitle5: {
    fontFamily: defaultFont,
    fontSize: 10,
    lineHeight: '15px',
    fontWeight: 700,
    textTransform: 'uppercase',
  },

  // Paragraphs
  paragraph1: {
    fontFamily: defaultFont,
    fontSize: 18,
    lineHeight: '31px',
    fontWeight: 400,
  },
  paragraph1Medium: {
    fontFamily: defaultFont,
    fontSize: 18,
    lineHeight: '31px',
    fontWeight: 500,
  },
  paragraph1Semibold: {
    fontFamily: defaultFont,
    fontSize: 18,
    lineHeight: '31px',
    fontWeight: 600,
  },
  paragraph1Bold: {
    fontFamily: defaultFont,
    fontSize: 18,
    lineHeight: '31px',
    fontWeight: 700,
  },
  paragraph1Short: {
    fontFamily: defaultFont,
    fontSize: 18,
    lineHeight: 'var(--zds-typography-paragraph1short-lineheight, 24px)',
    fontWeight: 400,
  },
  paragraph1ShortMedium: {
    fontFamily: defaultFont,
    fontSize: 18,
    lineHeight: 'var(--zds-typography-paragraph1short-lineheight, 24px)',
    fontWeight: 500,
  },
  paragraph1ShortSemibold: {
    fontFamily: defaultFont,
    fontSize: 18,
    lineHeight: 'var(--zds-typography-paragraph1short-lineheight, 24px)',
    fontWeight: 600,
  },
  paragraph1ShortBold: {
    fontFamily: defaultFont,
    fontSize: 18,
    lineHeight: 'var(--zds-typography-paragraph1short-lineheight, 24px)',
    fontWeight: 700,
  },
  paragraph3: {
    fontFamily: defaultFont,
    fontSize: 16,
    lineHeight: 'var(--zds-typography-paragraph3-lineheight, 24px)',
    fontWeight: 400,
  },
  paragraph3Medium: {
    fontFamily: defaultFont,
    fontSize: 16,
    lineHeight: 'var(--zds-typography-paragraph3-lineheight, 24px)',
    fontWeight: 500,
  },
  paragraph3Semibold: {
    fontFamily: defaultFont,
    fontSize: 16,
    lineHeight: 'var(--zds-typography-paragraph3-lineheight, 24px)',
    fontWeight: 600,
  },
  paragraph3Bold: {
    fontFamily: defaultFont,
    fontSize: 16,
    lineHeight: 'var(--zds-typography-paragraph3-lineheight, 24px)',
    fontWeight: 700,
  },

  // Small print
  smallPrint1: {
    fontFamily: defaultFont,
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 400,
  },
  smallPrint1Medium: {
    fontFamily: defaultFont,
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
  },
  smallPrint1Semibold: {
    fontFamily: defaultFont,
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 600,
  },
  smallPrint1Bold: {
    fontFamily: defaultFont,
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 700,
  },
  smallPrint3: {
    fontFamily: defaultFont,
    fontSize: 12,
    lineHeight: '15px',
    fontWeight: 400,
  },
  smallPrint3Semibold: {
    fontFamily: defaultFont,
    fontSize: 12,
    lineHeight: '15px',
    fontWeight: 600,
  },
  smallPrint3Bold: {
    fontFamily: defaultFont,
    fontSize: 12,
    lineHeight: '15px',
    fontWeight: 700,
  },
} as const;

export type TypographyName = keyof typeof Typography;

export const TypographyNameV5 = [
  'base',
  'heading',
  'pageHeader1',
  'pageHeader3',
  'pageHeader4',
  'pageHeader5',
  'pageHeader7',
  'pageHeader9',
  'sectionHeader1Medium',
  'sectionHeader1Bold',
  'sectionHeader3Semibold',
  'sectionHeader3Bold',
  'subHeader1',
  'subHeader3',
  'subHeader5',
  'subHeader1Semibold',
  'subHeader1Bold',
  'subHeader3Medium',
  'subHeader3Bold',
  'subHeader5Medium',
  'subHeader5Bold',
  'paragraph1',
  'paragraph1Medium',
  'paragraph1Semibold',
  'paragraph1Bold',
  'paragraph1Short',
  'paragraph1ShortMedium',
  'paragraph1ShortSemibold',
  'paragraph1ShortBold',
  'paragraph3',
  'paragraph3Medium',
  'paragraph3Semibold',
  'paragraph3Bold',
  'smallPrint1',
  'smallPrint1Medium',
  'smallPrint1Semibold',
  'smallPrint1Bold',
];

export const TypographyName = [
  'base',
  'heading',
  'pageHeader1',
  'pageHeader3',
  'pageHeader4',
  'pageHeader5',
  'pageHeader7',
  'pageHeader9',
  'sectionHeader1Medium',
  'sectionHeader1Bold',
  'sectionHeader3Semibold',
  'sectionHeader3Bold',
  'subHeader1',
  'subHeader1Semibold',
  'subHeader1Bold',
  'subHeader3Medium',
  'subHeader3Bold',
  'subHeader5Medium',
  'subHeader5Bold',
  'paragraph1',
  'paragraph1Medium',
  'paragraph1Semibold',
  'paragraph1Bold',
  'paragraph1Short',
  'paragraph1ShortMedium',
  'paragraph1ShortSemibold',
  'paragraph1ShortBold',
  'paragraph3',
  'paragraph3Medium',
  'paragraph3Semibold',
  'paragraph3Bold',
  'smallPrint1',
  'smallPrint1Medium',
  'smallPrint1Semibold',
  'smallPrint1Bold',
];
