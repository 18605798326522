import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionFlagFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m9.05 15 .73-2h6.88l-2.91 8h2.13l6.55-18H11.3l-.73 2H5.3L1.66 15h7.39Z"
    />
  </svg>
);
export default SvgActionFlagFill;
