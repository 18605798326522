import {
  AccountPlan,
  AccountPlanTier,
  PlanTier,
} from '@zapier/design-system-revenue/esm/types';

export const formatPlanTiers = (plan: AccountPlan): PlanTier[] =>
  plan?.metered_pricing_tiers?.map(
    ([numTasks, pricePerTask]: AccountPlanTier) => ({
      numTasks,
      pricePerTask,
    })
  ) ?? [];
