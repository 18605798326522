import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavHelpFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M2 12c0 5.51 4.49 10 10 10s10-4.49 10-10S17.51 2 12 2 2 6.49 2 12Zm10.01 6.25a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5Zm.35-12.12c2.08.02 3.7 1.27 3.86 2.96.13 1.34-.7 2.97-3.34 3.73v1.68h-1.75l-.01-3.08.7-.14c1.69-.35 2.74-1.14 2.65-2.01-.06-.66-.83-1.37-2.14-1.38h-.05c-1.27 0-2.29.5-2.92.9V6.8c.78-.36 1.76-.66 2.92-.66.03-.01.05-.01.08-.01Z"
    />
  </svg>
);
export default SvgNavHelpFill;
