import { css } from '@emotion/react';
import { labelStyles } from '@zapier/style-encapsulation';
import { Animation, Colors, Shadows, Typography } from '../../../theme';
import { createUniqueCustomProperties } from '../../../utils/createUniqueCustomProperty';

const Vars = createUniqueCustomProperties('Button', [
  'baseBackgroundColor',
  'baseBorderColor',
  'baseColor',
  'disabledBackgroundColor',
  'disabledBorderColor',
  'disabledColor',
  'disabledOutlineColor',
  'hoverBackgroundColor',
  'hoverBorderColor',
  'hoverColor',
  'hoverOutlineColor',
  'iconMargin',
  'focusBackgroundColor',
  'focusBorderColor',
  'focusColor',
  'focusOutlineColor',
  'selectedBackgroundColor',
  'selectedBorderColor',
  'selectedColor',
  'selectedOutlineColor',
  'buttonContentPadding',
]);

export const Styles = labelStyles('BaseButton', {
  root: css`
    // All of the CSS Custom Properties that are used by this
    // component, ensured to be unique. Most are color related
    // and are set below via '[data-color]' selectors.
    ${Vars.baseBackgroundColor}: transparent;
    ${Vars.baseBorderColor}: transparent;
    ${Vars.baseColor}: #000;
    ${Vars.disabledBackgroundColor}: ${Colors.neutral200};
    ${Vars.disabledBorderColor}: ${Colors.neutral400};
    ${Vars.disabledColor}: ${Colors.neutral500};
    ${Vars.disabledOutlineColor}: transparent;
    ${Vars.hoverBackgroundColor}: var(${Vars.baseBackgroundColor});
    ${Vars.hoverBorderColor}: var(${Vars.baseBorderColor});
    ${Vars.hoverColor}: var(${Vars.baseColor});
    ${Vars.hoverOutlineColor}: transparent;
    ${Vars.iconMargin}: 6px;
    ${Vars.focusBackgroundColor}: var(${Vars.baseBackgroundColor});
    ${Vars.focusBorderColor}: transparent;
    ${Vars.focusColor}: var(${Vars.baseColor});
    ${Vars.focusOutlineColor}: var(${Vars.focusBackgroundColor});
    ${Vars.selectedBackgroundColor}: var(${Vars.baseBackgroundColor});
    ${Vars.selectedBorderColor}: transparent;
    ${Vars.selectedColor}: var(${Vars.baseColor});
    ${Vars.selectedOutlineColor}: transparent;
    ${Vars.buttonContentPadding}: 0 10px;

    background-color: var(${Vars.baseBackgroundColor});
    border-color: var(${Vars.baseBorderColor});
    color: var(${Vars.baseColor});

    align-items: center;
    appearance: none;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    // Safari applies 2px margin to buttons this clears it
    margin: 0;
    min-width: 0;
    opacity: 1;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    transition: all 200ms ease-in-out;
    vertical-align: middle;
    white-space: nowrap;

    &::-moz-focus-inner {
      padding: 0;
      border: 0;
    }

    // This is the outline that renders around the button when
    // its focused. Its a pseudo element to preserve transparency;
    // between the edge of the button and the edge of the outline.
    // Its defined here vs only in ':focus' so that it can transition
    // between states.
    &::before {
      content: '';
      position: absolute;
      pointer-events: none;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 2px solid transparent;
      border-radius: 5px;
      transition-property: all;
      transition-timing-function: ${Animation.transitionTimingFunction};
      transition-duration: 200ms;
    }

    &:hover:not([disabled]),
    a:hover & {
      background-color: var(${Vars.hoverBackgroundColor});
      border-color: var(${Vars.hoverBorderColor});
      box-shadow: ${Shadows.elevation6};
      color: var(${Vars.hoverColor});
    }

    &:focus:not([disabled]),
    a:focus & {
      background-color: var(${Vars.focusBackgroundColor});
      border-color: var(${Vars.focusBorderColor});
      color: var(${Vars.focusColor});
      outline: none;
      &::before {
        border-color: var(${Vars.focusOutlineColor});
        top: -5px;
        right: -5px;
        bottom: -5px;
        left: -5px;
      }
    }

    &[data-selected]:not([data-color='button-nav']) {
      background-color: var(${Vars.selectedBackgroundColor});
      border-color: var(${Vars.selectedBorderColor});
      color: var(${Vars.selectedColor});
      // Hide :focus outline on selected buttons
      &::before {
        content: none;
      }
    }

    &[data-color='primary'] {
      ${Vars.baseBackgroundColor}: ${Colors.blue};
      ${Vars.baseColor}: ${Colors.neutral100};
      ${Vars.hoverBackgroundColor}: ${Colors.night};
    }

    &[data-color='primary-alt'] {
      ${Vars.baseBackgroundColor}: ${Colors.neutral800};
      ${Vars.baseColor}: ${Colors.neutral100};
      ${Vars.hoverBackgroundColor}: ${Colors.neutral700};
    }

    &[data-color='danger'] {
      ${Vars.baseBackgroundColor}: ${Colors.error400};
      ${Vars.baseColor}: ${Colors.neutral100};
      ${Vars.hoverBackgroundColor}: ${Colors.error500};
      ${Vars.hoverColor}: ${Colors.neutral100};
      ${Vars.focusColor}: ${Colors.neutral100};
      ${Vars.focusOutlineColor}: ${Colors.error400};
    }

    &[data-color='secondary'] {
      ${Vars.baseBackgroundColor}: transparent;
      ${Vars.baseColor}: ${Colors.blue};
      ${Vars.hoverBackgroundColor}: ${Colors.blue10};
      ${Vars.hoverColor}: ${Colors.blue};
      ${Vars.focusBackgroundColor}: ${Colors.blue10};
      ${Vars.focusColor}: ${Colors.blue};
      ${Vars.baseBorderColor}: ${Colors.blue};
      ${Vars.focusOutlineColor}: ${Colors.blue};
    }

    &[data-color='icon-primary'] {
      ${Vars.baseBackgroundColor}: ${Colors.neutral100};
      ${Vars.baseBorderColor}: ${Colors.neutral600};
      ${Vars.baseColor}: ${Colors.neutral800};
      ${Vars.hoverColor}: ${Colors.blueJeans};
      ${Vars.focusBorderColor}: ${Colors.blueJeans};
      ${Vars.focusColor}: ${Colors.blueJeans};
      ${Vars.focusOutlineColor}: ${Colors.blueJeans};

      &[disabled] {
        background-color: var(${Vars.disabledBackgroundColor});
        border-color: ${Colors.neutral400};
        color: ${Colors.neutral500};
        outline-color: var(${Vars.disabledOutlineColor});
      }
    }

    &[data-color='icon-alt'] {
      &[data-color='icon-alt'] {
        ${Vars.baseBackgroundColor}: ${Colors.neutral100};
        ${Vars.baseBorderColor}: ${Colors.blue};
        ${Vars.baseColor}: ${Colors.blue};
        ${Vars.hoverColor}: ${Colors.blue};
        ${Vars.hoverBackgroundColor}: ${Colors.blue10};
        ${Vars.focusBackgroundColor}: ${Colors.blue10};
        ${Vars.focusBorderColor}: ${Colors.blue};
        ${Vars.focusColor}: ${Colors.blue};
        ${Vars.focusOutlineColor}: ${Colors.blue};

        &[disabled] {
          background-color: var(${Vars.disabledBackgroundColor});
          border-color: ${Colors.neutral400};
          color: ${Colors.neutral500};
          outline-color: var(${Vars.disabledOutlineColor});
        }
      }
    }

    &[data-color='icon-secondary'] {
      ${Vars.baseBackgroundColor}: ${Colors.neutral100};
      ${Vars.baseBorderColor}: ${Colors.neutral600};
      ${Vars.baseColor}: ${Colors.neutral600};
      ${Vars.hoverColor}: ${Colors.neutral700};
      ${Vars.focusBorderColor}: ${Colors.blueJeans};
      ${Vars.focusColor}: ${Colors.blueJeans};
      ${Vars.focusOutlineColor}: ${Colors.blueJeans};

      &[disabled] {
        background-color: var(${Vars.disabledBackgroundColor});
        border-color: ${Colors.neutral400};
        color: ${Colors.neutral500};
        outline-color: var(${Vars.disabledOutlineColor});
      }
    }
    &[data-color='number'] {
      ${Vars.baseBackgroundColor}: ${Colors.neutral100};
      ${Vars.baseBorderColor}: ${Colors.neutral600};
      ${Vars.baseColor}: ${Colors.neutral700};
      ${Vars.hoverBackgroundColor}: ${Colors.blueJeans};
      ${Vars.hoverBorderColor}: ${Colors.blueJeans};
      ${Vars.hoverColor}: ${Colors.neutral100};
      ${Vars.focusBorderColor}: ${Colors.blueJeans};
      ${Vars.focusColor}: ${Colors.blueJeans};
      ${Vars.focusOutlineColor}: ${Colors.blueJeans};

      &[disabled] {
        background-color: var(${Vars.disabledBackgroundColor});
        border-color: ${Colors.neutral400};
        color: ${Colors.neutral500};
        outline-color: var(${Vars.disabledOutlineColor});
      }
    }
    &[data-color='primary-dark'],
    &[data-color='icon-primary-dark'] {
      ${Vars.baseBackgroundColor}: ${Colors.neutral100};
      ${Vars.baseColor}: ${Colors.blue};
      ${Vars.hoverColor}: ${Colors.night};
      ${Vars.hoverBackgroundColor}: ${Colors.neutral100};
      ${Vars.focusColor}: ${Colors.blue};
      ${Vars.focusOutlineColor}: ${Colors.neutral100};
    }
    &[data-color='secondary-dark'],
    &[data-color='icon-secondary-dark'] {
      ${Vars.baseBackgroundColor}: transparent;
      ${Vars.baseColor}: ${Colors.neutral100};
      ${Vars.baseBorderColor}: ${Colors.neutral100};
      ${Vars.hoverColor}: ${Colors.neutral800};
      ${Vars.hoverBackgroundColor}: ${Colors.blue10};
      ${Vars.hoverBorderColor}: ${Colors.blue10};
      ${Vars.focusColor}: ${Colors.neutral800};
      ${Vars.focusOutlineColor}: ${Colors.blue10};
      ${Vars.focusBackgroundColor}: ${Colors.blue10};
    }
    &[data-color='button-nav'] {
      ${Vars.baseBackgroundColor}: ${Colors.neutral200};
      ${Vars.baseColor}: ${Colors.neutral800};
      ${Vars.baseBorderColor}: ${Colors.neutral600};
      ${Vars.hoverColor}: ${Colors.neutral100};
      ${Vars.hoverBackgroundColor}: ${Colors.blue};
      ${Vars.hoverBorderColor}: ${Colors.blue};
      ${Vars.focusBackgroundColor}: ${Colors.neutral200};
      ${Vars.focusBorderColor}: ${Colors.neutral200};
      ${Vars.focusOutlineColor}: ${Colors.blue};
      border-radius: 30px;
      &::before {
        border-radius: 30px;
      }
      &[data-selected] {
        ${Vars.baseBackgroundColor}: ${Colors.neutral700};
        ${Vars.baseColor}: ${Colors.neutral100};
        ${Vars.baseBorderColor}: ${Colors.neutral700};
        ${Vars.hoverBackgroundColor}: ${Colors.neutral700};
        ${Vars.hoverBorderColor}: ${Colors.neutral700};
        ${Vars.focusBorderColor}: ${Colors.neutral700};
        ${Vars.focusBackgroundColor}: ${Colors.neutral700};
        ${Vars.focusOutlineColor}: ${Colors.neutral700};
      }
    }
    &[data-color='brand'],
    &[data-color='brand-nav'] {
      ${Vars.baseBackgroundColor}: ${Colors.zapier};
      ${Vars.baseColor}: ${Colors.cream};
      ${Vars.baseBorderColor}: transparent;
      ${Vars.hoverColor}: ${Colors.cream};
      ${Vars.hoverBackgroundColor}: ${Colors.zapier};
      ${Vars.focusColor}: ${Colors.cream};
      ${Vars.focusOutlineColor}: ${Colors.zapier};
      ${Vars.focusBackgroundColor}: ${Colors.zapier};
      ${Vars.focusBorderColor}: transparent;
    }
    &[data-color='brand'] {
      &[data-size='small'] {
        ${Typography.paragraph1ShortBold};
        border-radius: 18px;
        &::before {
          border-radius: 20px;
        }
      }
      &[data-size='medium'] {
        ${Typography.paragraph1ShortBold};
        border-radius: 18px;
        &::before {
          border-radius: 20px;
        }
      }
      &[data-size='large'] {
        ${Typography.paragraph1ShortBold};
        border-radius: 24px;
        &::before {
          border-radius: 26px;
        }
      }
    }
    &[data-color='brand-nav'] {
      ${Vars.iconMargin}: 0;
      &[data-size='medium'] {
        ${Typography.paragraph1ShortBold};
        border-radius: 18px;
        &::before {
          border-radius: 22px;
        }
      }
    }

    &[disabled] {
      cursor: not-allowed;
      background-color: var(${Vars.disabledBackgroundColor});
      border-color: var(${Vars.disabledBorderColor});
      color: var(${Vars.disabledColor});
      outline-color: var(${Vars.disabledOutlineColor});
    }

    &[data-full-width] {
      width: 100%;
    }

    &[data-shadowed] {
      box-shadow: ${Shadows.elevation5};
    }

    &[data-size='xsmall'] {
      ${Typography.paragraph3Semibold};
      ${Vars.buttonContentPadding}: 0 15px;
      height: 30px;
      border-radius: 3px;

      &[data-icon] {
        ${Vars.buttonContentPadding}: 0 8px 0 3px;
      }

      &[data-square] {
        width: 30px;
      }
    }

    &[data-size='small'] {
      ${Typography.paragraph3Semibold};
      ${Vars.buttonContentPadding}: 0 20px;
      height: 44px;

      &[data-icon] {
        ${Vars.buttonContentPadding}: 0 10px;
      }

      &[data-square] {
        width: 44px;
      }
      &[data-color='button-nav'] {
        ${Vars.buttonContentPadding}: 0 20px;
      }
    }

    &[data-size='medium'] {
      ${Typography.paragraph3Semibold};
      ${Vars.buttonContentPadding}: 0 20px;
      height: 50px;

      &[data-icon] {
        ${Vars.buttonContentPadding}: 0 17px;
        &[data-color='brand-nav'] {
          ${Vars.buttonContentPadding}: 0 12px;
        }
      }

      &[data-square] {
        width: 50px;
      }
    }

    &[data-size='large'] {
      ${Typography.paragraph1ShortSemibold};
      ${Vars.buttonContentPadding}: 0 20px;
      height: 60px;

      &[data-icon] {
        ${Vars.buttonContentPadding}: 0 17px;
      }

      &[data-square] {
        width: 60px;
      }
    }

    &[data-unpadded],
    &[data-square] {
      ${Vars.buttonContentPadding}: 0 !important;
    }

    &[data-pill] {
      border-radius: 100px !important;

      &::before {
        border-radius: 100px !important;
      }
    }
  `,
  buttonContent: css`
    display: flex;
    align-items: center;
    min-width: 0;
    padding: var(${Vars.buttonContentPadding});
  `,
  buttonText: css`
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
  `,
  buttonIcon: (hasSibling: boolean) => css`
    display: block;
    flex: none;
    ${hasSibling && `margin-right: var(${Vars.iconMargin});`}
  `,
});
