import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavTableFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#FF4F00"
      d="M3 21h8v-4H3v4Zm10-6h8v-4h-8v4ZM3 3v6h18V3H3Zm0 12h8v-4H3v4Zm10 6h8v-4h-8v4Z"
    />
  </svg>
);
export default SvgNavTableFill;
