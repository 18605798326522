import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscSteps = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M3 17.09v4h18v-18h-8v5H8v5H3v2h7v-5h5v-5h4v14H5v-2H3Z"
    />
  </svg>
);
export default SvgMiscSteps;
