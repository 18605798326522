import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgFileGuide = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M12 4.07 2 2.31v2.03l9 1.59V19.4l-7-1.23V6.72l-2-.35v13.47l10 1.76 10-1.76V2.31L12 4.07Zm8 14.09-7 1.23V5.93l7-1.23v13.46Z"
    />
    <path
      fill="#2D2E2E"
      d="m18.5 8.65-4 .7v2.03l4-.7V8.65ZM18.5 12.71l-4 .7v2.03l4-.7v-2.03Z"
    />
  </svg>
);
export default SvgFileGuide;
