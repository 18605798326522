// These colors should exactly match those in the Zapier Design System.
//
// 000 is the lightest variant of a color.
// 500 is the "normal" variant of a color. When we say "blue", we mean "blue500".
// 900 is the darkest variant of a color.
export const BaseColors = {
  // Reds
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  red000: '#ffe1d5',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  red100: '#ffd2bf',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  red200: '#ffa38c',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  red500: '#ff4a00',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  red600: '#e52f00',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  red700: '#c63a00',
  // Blues
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  blue100: '#f3f8ff',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  blue300: '#00bfff',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  blue500: '#136bf5',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  blue700: '#2655bd',
  // Oranges
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  orange100: '#feddc8',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  orange200: '#fec19b',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  orange500: '#fd7622',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  orange600: '#e46a1f',
  // Yellows
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  yellow100: '#fff0cf',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  yellow200: '#fff7e0',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  yellow400: '#ffcf61',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  yellow500: '#ffc43e',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  yellow600: '#e5b038',
  // Greens
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  green000: '#daf5e6',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  green100: '#c4f3ea',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  green200: '#95ead9',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  green300: '#daf5e6',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  green400: '#62d493',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  green500: '#13d0ab',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  green600: '#11bb9a',
  // Aquamarines
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  aquamarine100: '#d1f2fb',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  aquamarine200: '#ace7f8',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  aquamarine500: '#46caef',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  aquamarine600: '#3fb6d7',
  // Purples
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  purple100: '#d7d7fa',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  purple200: '#b7b8f7',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  purple500: '#6061ed',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  purple600: '#5657d5',
  // Pinks
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  pink100: '#fec6dc',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  pink200: '#fd99c0',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  pink500: '#fc1c74',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  pink600: '#e31968',
  // Greys
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  grey000: '#FAFAFA',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  grey100: '#F2F2F2',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  grey200: '#E6E6E6',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  grey300: '#D9D9D9',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  grey400: '#CCCCCC',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  grey500: '#999999',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  grey700: '#666666',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  grey900: '#333333',
  // Special colors
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  black: '#000000',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  white: '#ffffff',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  transparent: 'transparent',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  inherit: 'inherit',
  /**
   * @deprecated Will be replaced by new Zinnia colors.
   */
  currentColor: 'currentColor',
} as const; // casts the values of Colors to a union of specific values rather than string

/** Alias common color assignments for consistency in implementations */
const ColorAliases = {
  brand: BaseColors.red500,
};

export const ZinniaColors = {
  // Lava (oranges / brand)
  lava: '#ff4a00',
  lava120: '#d93f00',
  lava80: '#ff6e33',
  lava60: '#ff9266',
  lava40: '#ffb799',
  lava20: '#ffdbcc',
  lava10: '#ffede6',
  lava5: '#fff6f2',
  // Ocean (blues)
  ocean: '#136bf5',
  ocean120: '#2655bd',
  ocean80: '#4289f7',
  ocean60: '#71a6f9',
  ocean40: '#a1c4fb',
  ocean20: '#d0e1fd',
  ocean10: '#e7f0fe',
  ocean5: '#f3f8ff',
  // Sun (yellows)
  sun: '#ffc43e',
  sun120: '#e6b038',
  sun80: '#ffd065',
  sun60: '#ffdc8b',
  sun40: '#ffe7b2',
  sun20: '#fff3d8',
  sun10: '#fff9ec',
  sun5: '#fffcf5',
  // Error
  error: '#e02d00',
  error10: '#ffe1d5',
  error120: '#b92602',
  // Warning
  warning: '#ffc43e',
  warning10: '#ffe7b2',
  // Success
  success: '#62d493',
  success10: '#daf5e6',
  // Grays
  gray: '#333333',
  gray80: '#424242',
  gray60: '#757575',
  gray40: '#cccccc',
  gray20: '#e6e6e6',
  gray10: '#f2f2f2',
  gray5: '#fafafa',
  white: '#ffffff',
  // Special colors
  transparent: 'transparent',
  inherit: 'inherit',
  currentColor: 'currentColor',
} as const;

export const Colors = { ...BaseColors, ...ColorAliases, ...ZinniaColors };

export type ColorName = keyof typeof Colors;
export type ColorValue = typeof Colors[ColorName];
