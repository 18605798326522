import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgCommentFilledFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M2 18h8v4h2.11l3.36-4H22V2H2v16ZM7 7h10v2H7V7Zm0 4h8v2H7v-2Z"
    />
  </svg>
);
export default SvgCommentFilledFill;
