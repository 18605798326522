import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavBriefcaseFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m17.47 6-2.52-3h-5.9L6.53 6H2v8h9v-2h2v2h9V6h-4.53ZM9.14 6l.84-1h4.03l.84 1H9.14ZM13 17h-2v-1H2v5h20v-5h-9v1Z"
    />
  </svg>
);
export default SvgNavBriefcaseFill;
