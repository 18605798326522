/** @jsx isolateComponent */
import { ReactNode } from 'react';

import { ColorName, Colors, Typography, TypographyName } from '../../../theme';
import {
  ContentSectioningElement,
  InlineTextElement,
  TextContentElement,
} from '../../../ts-types';
import {
  isolateComponent,
  labelStyles,
  withModifier,
} from '@zapier/style-encapsulation';
import { Heading } from '../Heading';

export type TextElement =
  | ContentSectioningElement
  | TextContentElement
  | InlineTextElement;

type TextProps = {
  /** The color of text to render. */
  color?: ColorName;
  /**
   * The type (effectively the "style") of text to render. One of a list of
   * preset types, which correspond exactly to the Zapier Design System.
   */
  type?: TypographyName;
  /**
   * Text to be rendered. Accepts a Node to support minor variations, e.g., a
   * link within a heading, or an `<em>` tag for semantic emphasis.
   */
  children: ReactNode;
  /**
   * Weight override for `type`. There are a finite number of `type`s
   * but there are weight variations for each which can be handled here
   * rather than increasing the overall number of `type`s.
   */
  fontWeight?: 400 | 600 | 700 | 800;
  /** Applies a `margin` to the text. */
  margin?: string;
  /** The HTML tag that the component will render. */
  tag?: TextElement | typeof Heading;
  /**
   * Alignment of the text. Values are intended to match CSS `text-align`
   * values. Intentionally omitting many options until use cases arise, for
   * simplicity. One of: 'inherit', 'left', 'center', 'right'.
   */
  textAlign?: 'inherit' | 'left' | 'center' | 'right';
  /** Applies transition to the text. */
  hasTransition?: boolean;
};

const defaultProps = {
  color: 'neutral800' as NonNullable<TextProps['color']>,
  tag: 'span' as NonNullable<TextProps['tag']>,
  textAlign: 'inherit' as NonNullable<TextProps['textAlign']>,
  type: 'paragraph3' as NonNullable<TextProps['type']>,
  hasTransition: true,
};

type TextPropsWithDefaults = TextProps & typeof defaultProps;

const Styles = labelStyles('Text', {
  root: (props: TextPropsWithDefaults) => [
    withModifier(props.type, Typography[props.type]),
    withModifier(props.color, { color: Colors[props.color] }),
    props.fontWeight &&
      withModifier(`fontWeight-${props.fontWeight}`, {
        fontWeight: props.fontWeight,
      }),
    {
      margin: props.margin,
      maxWidth: '100%',
      textAlign: props.textAlign,
    },
    props.hasTransition && {
      transition: 'color 0.2s ease-in-out',
    },
  ],
});

/**
 * This component precisely implements the Zapier Design System
 * for text.
 *
 * If you feel the need to add a variation on its styles, your design is likely
 * violating Zapier's style guide.
 */
export const Text = ({
  color,
  hasTransition = true,
  tag = 'span',
  textAlign = 'inherit',
  type = 'paragraph3',
  ...props
}: TextProps) => {
  const interpolatedProps: TextPropsWithDefaults = {
    color: color ?? 'neutral800',
    hasTransition,
    tag,
    textAlign,
    type,
    ...props,
  };
  return (
    <interpolatedProps.tag css={Styles.root(interpolatedProps)}>
      {interpolatedProps.children}
    </interpolatedProps.tag>
  );
};
