import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionPinFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m13.92.81-2.98 6.4-5.21.45-1.67 1.67 10.61 10.61 1.67-1.67.45-5.21 6.4-2.98L13.92.81ZM7.566 15.014l-5.274 5.275 1.414 1.414 5.275-5.275-1.415-1.414Z"
    />
  </svg>
);
export default SvgActionPinFill;
