import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavCog = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M19.9 8.9a2.516 2.516 0 0 1-1.26-2.18l.01-2.42-3.29-1.9-2.09 1.22c-.78.45-1.74.45-2.52 0l-2.1-1.23-3.29 1.9.01 2.42c0 .9-.48 1.73-1.26 2.18L2 10.1v3.81l2.1 1.2c.78.45 1.26 1.28 1.26 2.18l-.01 1.63 2.01-1.18v-.44c.01-1.62-.86-3.12-2.27-3.93L4 12.74v-1.48l1.1-.63c1.41-.8 2.28-2.31 2.27-3.93V5.44l1.27-.74 1.09.64c1.4.82 3.14.82 4.54 0l1.09-.64 1.28.74V6.7c-.01 1.62.86 3.12 2.27 3.93l1.1.63v1.48l-1.1.63c-1.41.8-2.28 2.31-2.27 3.93v1.26l-1.28.74-1.09-.64a4.47 4.47 0 0 0-2.27-.61c-.79 0-1.57.2-2.27.61l-3.07 1.8 1.99 1.15 2.09-1.22a2.52 2.52 0 0 1 2.52 0l2.09 1.22 3.29-1.9-.01-2.42c0-.9.48-1.73 1.26-2.18l2.1-1.2V10.1L19.9 8.9Z"
    />
    <path
      fill="#2D2E2E"
      d="M12 16.5c2.48 0 4.5-2.02 4.5-4.5S14.48 7.5 12 7.5 7.5 9.52 7.5 12s2.02 4.5 4.5 4.5Zm0-7a2.5 2.5 0 0 1 0 5 2.5 2.5 0 0 1 0-5Z"
    />
  </svg>
);
export default SvgNavCog;
