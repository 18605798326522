import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavCustomAction = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M12.002 2.03c-5.493 0-9.97 4.477-9.97 9.97 0 5.492 4.477 9.968 9.97 9.968a9.9 9.9 0 0 0 4.136-.9l-.069-.125-2.086-1.218a7.96 7.96 0 0 1-1.982.25c-4.396 0-7.975-3.58-7.975-7.976s3.58-7.974 7.976-7.974 7.975 3.578 7.975 7.974c0 .635-.075 1.252-.216 1.844l1.258 2.17.085.047A9.903 9.903 0 0 0 21.97 12c0-5.493-4.476-9.97-9.968-9.97Z"
    />
    <path
      fill="#2D2E2E"
      d="M13.004 15.987v-3h3.01v-2h-3.01v-3h-2.006v3h-3.01v2h3.01v3h2.006ZM19.799 17.198l-1.281-2.202-1.28 2.202-2.203 1.281 2.202 1.28 1.28 2.203L19.8 19.76 22 18.48l-2.203-1.282Z"
    />
  </svg>
);
export default SvgNavCustomAction;
