// These values represent different depths of shadows used in the Zapier
// Design System. This concept is borrowed from Material Design. The greater
// the number, the further the element applying the shadow will appear from
// the screen.
// For more information about what an shadow is, check out the Material
// Design's documentation here:
// https://material.io/design/environment/elevation.html

export enum Shadows {
  elevation5 = '0px 5px 10px 0px rgba(0, 0, 0, 0.1)',
  elevation6 = '0px 5px 10px 0px rgba(0, 0, 0, 0.2)',
  elevation10 = '0px 20px 30px 0px rgba(0, 0, 0, 0.1)',
  elevation20 = '0px 10px 30px 0px rgba(0, 0, 0, 0.2)',
  elevation30 = '0px 10px 20px 0px rgba(0, 0, 0, 0.3)',
}
export type ShadowName = keyof typeof Shadows;
export type ShadowValue = Shadows;
