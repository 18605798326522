import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavIntegrationsFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M16.21 1.5H7.79L6.53 3H2v18h20V3h-4.53l-1.26-1.5ZM18.35 12l-2.52 3h-2.61l2.52-3-2.52-3h2.61l2.52 3Zm-7.57-3-2.52 3 2.52 3H8.17l-2.52-3 2.52-3h2.61Z"
    />
  </svg>
);
export default SvgNavIntegrationsFill;
