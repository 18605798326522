import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionDownload = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M18 13.27v-2.61l-5 4.2V5h-2v9.86l-5-4.2v2.61l6 5 6-5ZM3 20v2h18v-2H3Z"
    />
  </svg>
);
export default SvgActionDownload;
