import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscCloudFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M19.43 9.54A7.463 7.463 0 0 0 12 3a7.46 7.46 0 0 0-6.63 4H9v2H7c-.87 0-1.69.19-2.43.53v.01A5.951 5.951 0 0 0 1 15c0 3.31 2.69 6 6 6h10c3.31 0 6-2.69 6-6 0-2.39-1.41-4.51-3.57-5.46Z"
    />
  </svg>
);
export default SvgMiscCloudFill;
