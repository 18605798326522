import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgFormDashSquareOutlined = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path fill="#2D2E2E" d="M3 3v18h18V3H3Zm16 16H5V5h14v14Z" />
    <path fill="#2D2E2E" d="M17 11H7v2h10v-2Z" />
  </svg>
);
export default SvgFormDashSquareOutlined;
