import { fromEntries } from './fromEntries';

type AriaAttrs = { [k: string]: any };

// Mapping of `aria-` attributes for HTML to JS.
// Since only about half of these could be handled via `camelCase` and `kebabCase`,
// might as well map all of the attributes.
const allAriaAttrs = {
  'aria-activedescendant': 'ariaActiveDescendant',
  'aria-atomic': 'ariaAtomic',
  'aria-autocomplete': 'ariaAutoComplete',
  'aria-busy': 'ariaBusy',
  'aria-checked': 'ariaChecked',
  'aria-colcount': 'ariaColCount',
  'aria-colindex': 'ariaColIndex',
  'aria-colspan': 'ariaColSpan',
  'aria-controls': 'ariaControls',
  'aria-current': 'ariaCurrent',
  'aria-describedby': 'ariaDescribedBy',
  'aria-details': 'ariaDetails',
  'aria-disabled': 'ariaDisabled',
  'aria-dropeffect': 'ariaDropEffect',
  'aria-errormessage': 'ariaErrorMessage',
  'aria-expanded': 'ariaExpanded',
  'aria-flowto': 'ariaFlowTo',
  'aria-grabbed': 'ariaGrabbed',
  'aria-haspopup': 'ariaHasPopup',
  'aria-hidden': 'ariaHidden',
  'aria-invalid': 'ariaInvalid',
  'aria-keyshortcuts': 'ariaKeyShortcuts',
  'aria-label': 'ariaLabel',
  'aria-labelledby': 'ariaLabelledBy',
  'aria-level': 'ariaLevel',
  'aria-live': 'ariaLive',
  'aria-modal': 'ariaModal',
  'aria-multiline': 'ariaMultiline',
  'aria-multiselectable': 'ariaMultiselectable',
  'aria-orientation': 'ariaOrientation',
  'aria-owns': 'ariaOwns',
  'aria-placeholder': 'ariaPlaceholder',
  'aria-posinset': 'ariaPosInset',
  'aria-pressed': 'ariaPressed',
  'aria-readonly': 'ariaReadOnly',
  'aria-relevant': 'ariaRelevant',
  'aria-required': 'ariaRequired',
  'aria-roledescription': 'ariaRoleDescription',
  'aria-rowcount': 'ariaRowCount',
  'aria-rowindex': 'ariaRowIndex',
  'aria-rowspan': 'ariaRowSpan',
  'aria-selected': 'ariaSelected',
  'aria-setsize': 'ariaSetSize',
  'aria-sort': 'ariaSort',
  'aria-valuemax': 'ariaValueMax',
  'aria-valuemin': 'ariaValueMin',
  'aria-valuenow': 'ariaValueNow',
  'aria-valuetext': 'ariaValueText',
};

const invertedAriaAttrs = fromEntries(
  Object.entries(allAriaAttrs).map(([key, value]) => [value, key])
);

export const camelCaseAriaAttrs = (kebabCasedAttrs: AriaAttrs) => {
  return fromEntries(
    Object.entries(kebabCasedAttrs).map(([key, val]) => {
      return [allAriaAttrs[key as keyof typeof allAriaAttrs] || key, val];
    })
  );
};

export const kebabCaseAriaAttrs = (camelCasedAttrs: AriaAttrs) => {
  return fromEntries(
    Object.entries(camelCasedAttrs).map(([key, val]) => {
      return [
        invertedAriaAttrs[key as keyof typeof invertedAriaAttrs] || key,
        val,
      ];
    })
  );
};
