import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMagnifyingGlass = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m5.134 17.452-2.843 2.842 1.415 1.415 2.842-2.843-1.414-1.414ZM13 3c-4.41 0-8 3.59-8 8 0 3.75 2.98 8 8 8 4.41 0 8-3.59 8-8s-3.59-8-8-8Zm0 14c-3.8 0-6-3.21-6-6 0-3.31 2.69-6 6-6s6 2.69 6 6-2.69 6-6 6Z"
    />
  </svg>
);
export default SvgMagnifyingGlass;
