/** @jsx isolateComponent */

import { css } from '@emotion/react';
import {
  isolateComponent,
  withModifier,
  labelStyles,
} from '@zapier/style-encapsulation';
import { Icon } from '../../display/Icon';
import type { IconName } from '../../display/Icon';
import { Animation, Colors } from '../../../theme';

export type BooleanInputType = 'radio' | 'checkbox';
export type Props = {
  /**
   * Optional accessibility label for the underlying `input`.
   * Using a `label` tag is generally preferred for more clickable area.
   */
  ariaLabel?: string;

  /**
   * Indicates whether `BooleanInput` is checked.
   */
  checked: boolean;

  /**
   * Indicates whether `BooleanInput` is disabled.
   */
  disabled?: boolean;

  /**
   * `id` that is attached to the underlying `input`, used to attach
   * an external `label` to it via the `for` attribute.
   */
  id?: string;

  /**
   * Indicates whether `BooleanInput` is errored.
   */
  isErrored?: boolean;

  /**
   * Indicates whether the `input` is required.
   * For checkboxes, this means the checkbox itself must be checked.
   * For radios, this means one of the radios with the same `name`
   * must be checked.
   */
  isRequired?: boolean;

  /**
   * `name` that is attached to the underlying `input`, used to group
   * multiple `BooleanInput` values together.
   */
  name?: string;

  /**
   * Handler for when the `input` changes between checked and unchecked.
   */
  onChange: (e: React.SyntheticEvent) => void;

  /**
   * The `tabIndex`. The default is almost always correct unless this
   * `BooleanInput` is being used inside a larger control that manages
   * focus state and keyboard interactions. e.g. a dropdown.
   */
  tabIndex: number;

  /**
   * The type of `input` to render.
   */
  type: BooleanInputType;

  /**
   * `value` that is attached to the underlying `input`, used to differentiate
   * one `input` from another.
   */
  value?: string;
};
const borderWidth = 2;
const Styles = labelStyles('BooleanInput', {
  root: () => [
    css({
      position: 'relative',
      display: 'inline-block',
    }),
  ],
  inner: (props: Props) => [
    css({
      display: 'block',
      position: 'relative',
      zIndex: 1,
      border: `${borderWidth}px solid ${Colors.neutral600}`,
      backgroundColor: 'transparent',
      cursor: 'pointer',
      transition: `all ${Animation.transitionDuration} ease-in-out`,
    }),
    props.type === 'checkbox' && [
      withModifier(
        'checkbox',
        css({
          width: 18,
          height: 18,
          borderRadius: 3,
          color: Colors.neutral100,
        })
      ),
      props.checked &&
        withModifier(
          'checked',
          css({
            backgroundColor: Colors.blue,
            borderColor: 'transparent',
          })
        ),
      props.isErrored &&
        withModifier(
          'errored',
          css({
            backgroundColor: props.checked ? Colors.error : 'transparent',
            borderColor: Colors.error,
          })
        ),
      props.disabled &&
        withModifier(
          'disabled',
          css({
            backgroundColor: props.checked ? Colors.neutral500 : 'transparent',
            borderColor: Colors.neutral500,
            cursor: 'not-allowed',
          })
        ),
    ],
    props.type === 'radio' && [
      withModifier(
        'radio',
        css({
          color: 'transparent',
          width: 20,
          height: 20,
          borderRadius: 20,
        })
      ),
      props.checked &&
        withModifier(
          'checked',
          css({
            borderColor: Colors.blue,
            color: Colors.blue,
          })
        ),
      props.isErrored &&
        withModifier(
          'errored',
          css({
            borderColor: Colors.error,
            color: Colors.error,
          })
        ),
      props.disabled &&
        withModifier(
          'disabled',
          css({
            borderColor: Colors.neutral500,
            color: Colors.neutral500,
            cursor: 'not-allowed',
          })
        ),
    ],
  ],
  layer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: -borderWidth,
    left: -borderWidth,
    width: `calc(100% + ${2 * borderWidth}px)`,
    height: `calc(100% + ${2 * borderWidth}px)`,
  }),
});

/**
 * `BooleanInput` is a stylized `input[type="checkbox | radio"]`.
 */
export const BooleanInput = (props: Props) => {
  return (
    <span css={Styles.root()}>
      <span css={Styles.inner(props)}>
        <input
          aria-label={props.ariaLabel}
          checked={props.checked}
          css={Styles.layer}
          disabled={props.disabled}
          id={props.id}
          name={props.name}
          onChange={props.onChange}
          required={props.isRequired}
          tabIndex={props.tabIndex}
          type={props.type}
          value={props.value}
        />
        {props.checked && (
          <span css={Styles.layer}>
            <Icon
              isBlock={true}
              name={
                {
                  checkbox: 'formCheck' as IconName,
                  radio: 'formRadioDot' as IconName,
                }[props.type]
              }
              size={18}
            />
          </span>
        )}
      </span>
    </span>
  );
};

BooleanInput.defaultProps = {
  checked: false,
  onChange: () => {},
  tabIndex: 0,
};
