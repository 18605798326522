import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavTable = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#FF4F00"
      d="M19 5v4H5V7H3v14h18V3H3v2h16ZM5 11h6v3H5v-3Zm0 8v-3h6v3H5Zm14 0h-6v-3h6v3Zm0-5h-6v-3h6v3Z"
    />
  </svg>
);
export default SvgNavTable;
