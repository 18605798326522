import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionCheckConnectionFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m17.28 12 4.5-5.36-1.54-1.28L14.67 12H9.34L3.76 5.36 2.23 6.64 6.73 12H2.01v9h20v-9h-4.73Zm.23 5.75a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5ZM8.58 7.91 9.9 9.48a4.88 4.88 0 0 1 4.22 0l1.32-1.57a6.92 6.92 0 0 0-6.86 0ZM12.01 5a8.89 8.89 0 0 1 4.73 1.36l1.27-1.55a10.95 10.95 0 0 0-12 0l1.3 1.55A8.89 8.89 0 0 1 12.01 5Z"
    />
  </svg>
);
export default SvgActionCheckConnectionFill;
