import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionResend = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m3.61 20.27 1-5.61-1.88-.88L1 23.73 13.09 18a6 6 0 0 1 1.3-2.83l-10.78 5.1ZM9 14.52v-2.21l-4.61-2.17-.78-4.41 14.89 7v-2.21L1 2.27l1.63 9.24L9 14.52ZM22.25 19A3.25 3.25 0 1 1 19 15.75h1.93l-1.47 1.75h2l2.03-2.5-2.1-2.5h-2l1.47 1.75H19A4.75 4.75 0 1 0 23.75 19h-1.5Z"
    />
  </svg>
);
export default SvgActionResend;
