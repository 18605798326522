import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgFormCheckSquareOutlined = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M3 3v18h18V3H3Zm16 16H5V5h14v14Zm-1.29-9.29-1.42-1.42-5.29 5.3-2.79-2.8-1.42 1.42 4.21 4.2 6.71-6.7Z"
    />
  </svg>
);
export default SvgFormCheckSquareOutlined;
