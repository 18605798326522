import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscEyeClosed = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <g clipPath="url(#miscEyeClosed_svg__a)">
      <path
        fill="#2D2E2E"
        fillRule="evenodd"
        d="m5.432 17.607-2.364 2.364 1.415 1.414 2.662-2.662a10.99 10.99 0 0 0 5.265 1.322c5.13 0 9.3-3.54 11.41-8-1.027-2.171-2.544-4.132-4.43-5.568l2.063-2.063L20.04 3l-2.37 2.37a11.137 11.137 0 0 0-5.259-1.325c-5.13 0-9.3 3.54-11.41 8l.038.081c.153.333.455.99 1.162 2.019l.013-.032c.236-.583 3.262-8.068 10.197-8.068 1.414 0 2.666.324 3.758.826l-1.42 1.42a4.471 4.471 0 0 0-2.338-.656c-2.48 0-4.5 2.02-4.5 4.5 0 .855.24 1.655.657 2.337l-1.69 1.69a12.977 12.977 0 0 1-2.357-2.277c-.43.86-.72 1.63-.88 2.07.514.558 1.11 1.126 1.792 1.652Zm3.22-.39a8.888 8.888 0 0 0 3.758.828c5.33 0 8.35-4.56 9.17-6-.478-.823-1.685-2.687-3.636-4.121l-1.761 1.761c.46.705.727 1.547.727 2.45 0 2.48-2.02 4.5-4.5 4.5a4.472 4.472 0 0 1-2.45-.728l-1.309 1.31Zm2.78-2.781a2.5 2.5 0 0 0 3.28-3.28l-3.28 3.28Zm1.825-4.654a2.5 2.5 0 0 0-3.2 3.2l3.2-3.2Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="miscEyeClosed_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgMiscEyeClosed;
