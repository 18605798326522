/** @jsx isolateComponent */

import Imgix from 'react-imgix';
import { SyntheticEvent } from 'react';
import { css } from '@emotion/react';

import { isolateComponent, labelStyles } from '@zapier/style-encapsulation';
import { Animation, ShadowName, Shadows } from '../../../theme';

export type Props = {
  /**
   * Specifies alternative text for the image
   */
  alt: string;

  /**
   * Inform screen-readers that they should ignore the image
   */
  ariaHidden?: boolean;

  /**
   * Only supported by Imgix URLs. Provide an aspect ratio as a string, in the
   * form of `width:height` e.g. `16:9`. Imgix crop the image to fulfill the
   * desired aspect ratio.
   */
  aspectRatio?: string;

  /**
   * The string provided by the `css` prop via emotion.
   * This should only ever be provided by the `css` prop!
   * Note that when this prop is passed, no styles will be
   * applied to `Img` except those defined by `className`.
   */
  className?: string;

  /**
   * The maximum height of the image in pixels. If both `height` and `width`
   * are set, and the image is an Imgix URL, the image will be center-cropped
   * by the Imgix service.
   */
  height?: number;

  /**
   * Whether to render `Img` as a block-level element.
   * Useful to remove `line-height`.
   */
  isBlock?: boolean;

  /**
   * Whether `Img` stretches as wide as its container.
   */
  isFullWidth?: boolean;

  /**
   * Optional override of `border-radius` style
   */
  borderRadius?: string | number;

  /**
   * The CSS `object-fit` property, used to adjust image cropping.
   */
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';

  /**
   * The CSS `object-position` property which works in conjunction
   * with `object-fit` to position the image as desired.
   */
  objectPosition?: string;

  /**
   * Called when image fails to load
   */
  onError?: (a: SyntheticEvent<HTMLImageElement>) => void;

  /**
   * Called when image loads
   */
  onLoad?: (a: SyntheticEvent<HTMLImageElement>) => void;

  /**
   * Applies a `box-shadow` with the specified shadow.
   */
  shadow?: ShadowName;

  /**
   * A comma separated list of source sizes. Each source size contains a media
   * query condition and the source size value. With this information, the
   * client will select one of the image sources available from the `srcset`
   * attribute.
   * The Img component uses `react-imgix` to automatically generate the `srcset`
   * list. The `srcset` list contains a comma separated list indicating possible
   * image sources the client can use.
   */
  sizes?: string;

  /**
   * Source URL of the image
   */
  src: string;

  /**
   * The width of the image in pixels. If both `height` and `width`
   * are set and the image is an Imgix URL, the image will be center-
   * cropped by the Imgix service.
   */
  width?: number;

  /**
   * The lazy-load setting for the image component. This prop enables the
   * browser-level lazy loading API.
   *
   * - `lazy`: Defer loading of the resource until it reaches a calculated
   * distance from the viewport.
   * - `eager`: Load the resource immediately, regardless of where it's located
   * on the page.
   *
   * For more information about this API see https://web.dev/browser-level-image-lazy-loading/
   */
  loading?: 'eager' | 'lazy';
};

const Styles = labelStyles('Img', {
  root: css`
    all: unset;
    box-sizing: border-box;
    transition: box-shadow ${Animation.transitionDuration}
      ${Animation.transitionTimingFunction};
    max-width: 100%;
  `,
});

const defaultProps = {
  borderRadius: 5,
  loading: 'lazy' as Props['loading'],
};

/**
 * Renders an `<img>` tag.
 *
 * Exposes additional features like lazy-loading, server-side cropping and
 * resizing, and responsive image support.
 */
export const Img = (_props: Props) => {
  const props: Props = {
    ...defaultProps,
    ..._props,
  };

  const sharedProps = {
    // Avoid styling conflicts by only applying either
    // `props.className` or `Styles.root`.
    className: props.className,
    css: props.className ? undefined : Styles.root,
    height: props.height,
    alt: props.alt || '',
    'aria-hidden': props.ariaHidden,
    'data-testid': 'img',
    loading: props.loading,
    onError: props.onError,
    onLoad: props.onLoad,
    sizes: props.sizes,
    src: props.src,
    width: props.width,
    // These styles are all highly dynamic and therefore aren't
    // useful to create classes for since the permutations
    // could result in many classes, so apply as inline styles
    // instead. Only apply if `className` isn't defined in order
    // to avoid style conflicts.
    style: !props.className
      ? {
          display: props.isBlock ? 'block' : undefined,
          width: props.isFullWidth ? '100%' : props.width,
          height: props.height,
          borderRadius: props.borderRadius,
          boxShadow: props.shadow && Shadows[props.shadow],
          objectFit: props.objectFit,
          objectPosition: props.objectPosition,
        }
      : undefined,
  };
  // If not an Imgix URL, render a regular `img`. This is important
  // so that the `Imgix` component doesn't apply `srcset` and related
  // attributes to `img` which can cause size rendering issues, specifically
  // when a `sizes` value doesn't have a 1:1 definition to one of
  // the `srcset` values.
  if (!props.src.includes('imgix.net')) {
    return (
      // `alt` is in `sharedProps` but eslint plugin doesn't see it
      // eslint-disable-next-line jsx-a11y/alt-text
      <img {...sharedProps} />
    );
  }
  return (
    <Imgix
      className={sharedProps.className}
      css={sharedProps.css}
      height={sharedProps.height}
      htmlAttributes={{
        alt: sharedProps.alt,
        'aria-hidden': sharedProps['aria-hidden'],
        'data-testid': sharedProps['data-testid'],
        loading: sharedProps.loading,
        onError: sharedProps.onError,
        onLoad: sharedProps.onLoad,
        style: sharedProps.style,
      }}
      imgixParams={{
        ar: props.aspectRatio,
        // v9.x of react-imgix implements the default behavior of the Imgix API
        // which is to use `fit=clip`. This LOC re-introduces the v8.x behavior
        // which previously attached `fit=crop` whenever aspect ratio, height,
        // or width properties were applied.
        // See https://github.com/imgix/react-imgix#8x-to-90
        fit:
          props.aspectRatio || props.width || props.height || props.sizes
            ? 'crop'
            : undefined,
      }}
      sizes={sharedProps.sizes}
      src={sharedProps.src}
      width={sharedProps.width}
    />
  );
};
