import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgFormTextLong = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M21 5h-9v2h9V5ZM21 11h-9v2h9v-2ZM21 17h-9v2h9v-2Z"
    />
    <path
      fill="#2D2E2E"
      fillRule="evenodd"
      d="M6.5 9.938H9v-2H2v2h2.5V17h2V9.937Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFormTextLong;
