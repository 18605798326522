import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscColorFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M22 12c0-2.72-1.08-5.27-3.03-7.17A9.902 9.902 0 0 0 11.72 2C6.51 2.15 2.15 6.51 2 11.72c-.08 2.73.93 5.3 2.83 7.26A9.975 9.975 0 0 0 12 22h1v-4c0-1.1.9-2 2-2h7v-4ZM8.78 15.29c-.44.7-1.36.92-2.06.49-.7-.44-.92-1.36-.49-2.06.44-.7 1.36-.92 2.06-.49.7.43.92 1.35.49 2.06ZM7.46 11c-.83-.02-1.48-.72-1.46-1.54.02-.83.72-1.48 1.54-1.46.83.02 1.48.72 1.46 1.54-.02.83-.72 1.48-1.54 1.46Zm5.25-2.68a1.499 1.499 0 1 1-1.42-2.64 1.499 1.499 0 0 1 1.42 2.64Zm4.82 1.97c-.44.7-1.36.92-2.06.49-.7-.44-.92-1.36-.49-2.06.44-.7 1.36-.92 2.06-.49.7.43.92 1.35.49 2.06Z"
    />
  </svg>
);
export default SvgMiscColorFill;
