import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionFallback = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M5 2h14v10.012h-2V4H7v4.91L10 6v2.62l-4 3.86-4-3.86V6l3 2.91V2Z"
    />
    <path
      fill="#2D2E2E"
      fillRule="evenodd"
      d="M2 14h8v8H2v-8Zm2 2v4h4v-4H4ZM14 14h8v8h-8v-8Zm2 2v4h4v-4h-4Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgActionFallback;
