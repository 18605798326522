import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscDollar = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M12.99 4.57V2h-2v2.55c-2.12.28-3.76 1.67-3.97 3.48-.2 1.69.94 3.87 4.71 4.93 1.75.49 2.91 1.2 3.28 2 .23.49.11.95-.03 1.25-.37.8-1.89 1.51-4.38 1.2-1.93-.24-3.22-1.39-3.6-1.77v2.52c1.98 1.25 3.62 1.25 3.99 1.27V22h2v-2.62c1.82-.26 3.22-1.08 3.8-2.33.45-.96.46-2 .03-2.93-.63-1.38-2.17-2.42-4.55-3.09-2.14-.6-3.39-1.67-3.26-2.78.09-.81 1.03-1.7 2.57-1.77 2.87-.11 4.85 1.73 4.92 1.8V5.82c-.85-.51-2.05-1.06-3.51-1.25Z"
    />
  </svg>
);
export default SvgMiscDollar;
