import React, { useCallback, useEffect } from 'react';
import type { ComponentProps } from 'react';

import { emitUserInteractionEvent, getCookie, setCookie } from './utils';

import { PolicyModal } from '../PolicyModal';
import { useQueryPolicyPending } from './useQueryPolicyPending';

const SNOOZE_COOKIE_PREFIX = 'policySnooze';
const SNOOZE_COOKIE_EXP = 60 * 60;

const EVENT_POLICY_INTERACTION_NAME = 'policy_pending_check';

const formatSlugForCookie = (slug: string) => `${SNOOZE_COOKIE_PREFIX}_${slug}`;

const silenceLogger = (slug: string, reasons: string): void => {
  console.info(`Silencing pending policy \`${slug}\` because ${reasons}.`);
};

type TrackingContext = {
  trackEvent: (eventName: string, metadata: {}) => void;
};

export type Props = {
  /**
   * Current pathname of page to exempt policies that apply.
   */
  currentPathname: string;
  /**
   * Function to render markdown.
   */
  renderMarkdown?: ComponentProps<typeof PolicyModal>['renderMarkdown'];
  /**
   * `useQuery` hook to actually perform the network request.
   * @deprecated No longer used. REST is now used internally.
   */
  useQuery?: any;
  /**
   * `useMutation` hook to actually perform the network request.
   * @deprecated No longer used. REST is now used internally.
   */
  useMutation?: any;
  /**
   * `useTrackingContext` hook that returns functions to actually
   * perform the network request.
   * @deprecated We have migrated to Avro events.
   */
  useTrackingContext?: () => TrackingContext;

  /**
   * Policy requests by default go to `/api/v4/policies`.
   * Use this prop to specify a hostname if your service doesn't run on zapier.com.
   *
   * e.g. Setting this to https://zapier.com will send requests to https://zapier.com/api/v4/policies
   */
  zapierUrl?: string;
};

export const PolicyPendingCheck = (props: Props) => {
  const { policy, acceptPolicy } = useQueryPolicyPending(props.zapierUrl || '');

  useEffect(() => {
    if (policy) {
      emitUserInteractionEvent({
        interaction_name: EVENT_POLICY_INTERACTION_NAME,
        event_action: 'in_view',
        option_selected: `${policy.slug} ${policy.content}`,
      });
    }
  }, [policy]);

  const onAccept = useCallback(async () => {
    if (!policy) {
      return null;
    }

    emitUserInteractionEvent({
      interaction_name: EVENT_POLICY_INTERACTION_NAME,
      event_action: 'click',
      option_selected: `${policy.slug} ${policy.content}`,
    });

    try {
      await acceptPolicy();
    } catch {
      setCookie(
        formatSlugForCookie(policy.slug),
        'from_accept_err',
        SNOOZE_COOKIE_EXP
      );
    }
  }, [policy]);

  if (!policy) {
    return null;
  }

  // Exempt the modal for the policy's own page.
  if (props.currentPathname === policy.slug) {
    silenceLogger(policy.slug, 'pathname');
    return null;
  }

  const hasSnoozeCookie = Boolean(getCookie(formatSlugForCookie(policy.slug)));
  if (hasSnoozeCookie) {
    silenceLogger(policy.slug, 'snooze is on');
    return null;
  }

  return (
    <PolicyModal
      onAccept={onAccept}
      renderMarkdown={props.renderMarkdown}
      title={policy.title}
      tldr={policy.tldr}
      url={`https://zapier.com/${policy.slug}`}
    />
  );
};
