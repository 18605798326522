import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgRatingHeart = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M12 21.31c1.02-.86 4.11-3.35 9.01-8.73a5.675 5.675 0 0 0 1.48-4.19 5.646 5.646 0 0 0-1.95-3.97c-2.03-1.77-5-1.84-7.12-.33.52.61.91 1.06 1.31 1.54 1.37-.89 3.22-.82 4.5.3a3.714 3.714 0 0 1 .3 5.31c-2.69 2.95-5.22 5.46-7.53 7.46-1.99-1.71-4.74-4.14-7.53-7.43-1.4-1.66-1.27-4 .27-5.35 1.52-1.33 3.87-1.19 5.24.31.39.43 2.61 3.06 3.04 3.57l1.51-1.25c-.9-1.12-2.97-3.56-3.07-3.66-2.09-2.3-5.69-2.51-8.03-.47-2.38 2.08-2.6 5.65-.49 8.14 3.43 4.03 6.76 6.79 9.06 8.75Z"
    />
  </svg>
);
export default SvgRatingHeart;
