import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgFileFileFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path fill="#2D2E2E" d="M14 8V2H4v20h16V8h-6Z" />
    <path fill="#2D2E2E" d="M15.5 2.09V6.5h4.41L15.5 2.09Z" />
  </svg>
);
export default SvgFileFileFill;
