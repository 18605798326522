import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscCreditCardFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M22 4H2v4h20V4ZM2 20h20v-9H2v9Zm9-6h2v2h-2v-2Zm-7 0h5v2H4v-2Z"
    />
  </svg>
);
export default SvgMiscCreditCardFill;
