import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscAi = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      fillRule="evenodd"
      d="m7.754 8.377 2.261-3.888 2.261 3.888.362.362L16.526 11l-3.888 2.261-.362.362-2.26 3.888-1.784-3.066-1.554 1.304 2.473 4.254h1.729l2.992-5.146 5.146-2.992v-1.73l-5.146-2.992-2.992-5.146H9.15L6.16 7.143l-5.146 2.993v1.729l4.243 2.467 1.628-1.367L3.504 11l3.889-2.26.361-.363Z"
      clipRule="evenodd"
    />
    <path
      fill="#2D2E2E"
      d="m19.516 15.991 1.28 2.203L23 19.474l-2.203 1.282-1.281 2.203-1.281-2.203-2.203-1.281 2.203-1.281 1.28-2.203Z"
    />
  </svg>
);
export default SvgMiscAi;
