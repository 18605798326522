import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionCameraFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path fill="#2D2E2E" d="M12 14.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
    <path
      fill="#2D2E2E"
      d="m17.47 4-1.26-1.5H7.79L6.53 4H2v16h20V4h-4.53ZM12 16.5c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5Z"
    />
  </svg>
);
export default SvgActionCameraFill;
