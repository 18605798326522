import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscFireFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m13.49 2.32-1.59-.98C9.93 2.52 3.4 7.03 3.4 14c0 4.69 3.81 8.5 8.5 8.5 4.69 0 8.5-3.81 8.5-8.5 0-3.37-1.47-5.58-2.65-7.56C16.43 8.6 15.89 10 13.9 10c-2.73 0-3.39-2.75-2.6-4.49.57-1.26 1.09-1.85 2.19-3.19Z"
    />
  </svg>
);
export default SvgMiscFireFill;
