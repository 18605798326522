import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgPersonAddFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M3.3 13 .75 20h12.24l-.72-1.99L8.25 18l.73-2 4.69.01L15.12 20h2.13l-2.55-7H3.3ZM20 9V6h-2v3h-3v2h3v3h2v-3h3V9h-3ZM9 11a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
    />
  </svg>
);
export default SvgPersonAddFill;
