import { hashString } from './hashString';

export const createUniqueCustomProperty = (
  block: string,
  propertyName: string
) => {
  const hash = hashString('design-system' + block + propertyName);
  return `--${block}-${propertyName}-${hash}`;
};

export const createUniqueCustomProperties = (
  block: string,
  propertyNames: Array<string>
) => {
  return propertyNames.reduce<{ [key: string]: string }>(
    (accum, propertyName) => {
      accum[propertyName] = createUniqueCustomProperty(block, propertyName);
      return accum;
    },
    {}
  );
};
