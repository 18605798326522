import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionTrashFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M6.17 22h11.67l.98-13H5.19l.98 13ZM20.42 4.55l-.35-1.97-5.12.89-.99-.84-4.86.86-.66 1.13-5.11.89.34 1.98 16.75-2.94Z"
    />
  </svg>
);
export default SvgActionTrashFill;
