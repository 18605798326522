import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscColor = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M18.97 4.83A9.918 9.918 0 0 0 11.72 2C6.51 2.15 2.15 6.51 2 11.72c-.08 2.73.93 5.3 2.83 7.26A9.935 9.935 0 0 0 12 22h1v-2h-1c-2.18 0-4.21-.86-5.73-2.42A7.96 7.96 0 0 1 4 11.77C4.12 7.6 7.6 4.12 11.77 4c2.2-.05 4.24.74 5.8 2.26A7.94 7.94 0 0 1 20 12v2h-5c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2h7v-4c0-2.72-1.08-5.27-3.03-7.17Z"
    />
    <path
      fill="#2D2E2E"
      d="M8.185 10.835a1.5 1.5 0 1 0-1.37-2.67 1.5 1.5 0 0 0 1.37 2.67ZM8.29 13.22c-.71-.44-1.63-.22-2.06.49-.44.71-.22 1.63.49 2.06.71.44 1.63.22 2.06-.49.43-.7.21-1.62-.49-2.06ZM15.46 10.78c.71.44 1.63.22 2.06-.49.44-.71.22-1.63-.49-2.06-.71-.44-1.63-.22-2.06.49-.43.7-.21 1.62.49 2.06ZM13.32 6.29a1.499 1.499 0 1 0-2.64 1.42 1.499 1.499 0 0 0 2.64-1.42Z"
    />
  </svg>
);
export default SvgMiscColor;
