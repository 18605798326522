import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgRatingHeartFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M12 21.31c1.03-.86 4.11-3.35 9.01-8.73a5.675 5.675 0 0 0 1.48-4.19 5.668 5.668 0 0 0-1.95-3.98c-2.03-1.77-5-1.84-7.12-.33 1.04 1.21 1.51 1.77 2.69 3.24l-1.56 1.26c-.8-.99-2.99-3.58-3.09-3.7-2.09-2.3-5.69-2.51-8.03-.47-2.38 2.08-2.6 5.65-.49 8.14 3.43 4.04 6.74 6.79 9.06 8.76Z"
    />
  </svg>
);
export default SvgRatingHeartFill;
