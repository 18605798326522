/** @jsx isolateComponent */

import type { ReactNode } from 'react';
import { css } from '@emotion/react';
import Markdown from 'markdown-to-jsx';

import { isolateComponent, labelStyles } from '@zapier/style-encapsulation';
import {
  Modal,
  ModalContent,
  ModalContentHeader,
  ModalContentBody,
  ModalContentFooter,
} from '../../display/Modal';
import { Button } from '../../navigation/Button';

type Props = {
  /**
   * Title of the policy.
   */
  title: string;
  /**
   * A description of what's changed in the policy.
   */
  tldr: string;
  /**
   * URL of the policy page to view more.
   */
  url?: string;
  /**
   * Callback for when acceptance button is clicked.
   */
  onAccept: () => void;
  /**
   * Function to render markdown.
   */
  renderMarkdown?: (markdown: string) => ReactNode;
};

const gap = '15px';

const Styles = labelStyles('PolicyModal', {
  markdown: css`
    text-align: left;

    p {
      &:not(:last-child) {
        margin: 0 0 ${gap};
      }
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: ${gap};
      &:not(:first-of-type) {
        margin-top: ${gap};
      }
    }

    ul {
      margin-left: 20px;
      list-style: disc;
    }
  `,
});

export const PolicyModal = ({
  onAccept,
  title,
  tldr,
  url,
  renderMarkdown = (markdown: string) => <Markdown>{markdown}</Markdown>,
}: Props) => {
  return (
    <Modal canClose={false} onClosed={() => {}}>
      <ModalContent maxWidth="70ch">
        <ModalContentHeader align="center">
          <h1>We’ve updated the {title}</h1>
        </ModalContentHeader>
        <ModalContentBody align="center" scrollable={true}>
          <div css={Styles.markdown}>{renderMarkdown(tldr)}</div>
        </ModalContentBody>
        <ModalContentFooter>
          {!!url && (
            <Button color="secondary" href={url} target="_blank">
              Review changes
            </Button>
          )}
          <Button color="primary" hasShadow={true} onClick={onAccept}>
            Accept
          </Button>
        </ModalContentFooter>
      </ModalContent>
    </Modal>
  );
};
