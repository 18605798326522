import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscGraphBoxFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M3 21h18V3H3v18Zm12-11h2v7h-2v-7Zm-4-3h2v10h-2V7Zm-4 6h2v4H7v-4Z"
    />
  </svg>
);
export default SvgMiscGraphBoxFill;
