/** @jsx isolateComponent */

import { isolateComponent } from '@zapier/style-encapsulation';
import {
  LabeledBooleanInput,
  Props as LabeledBooleanInputProps,
} from './LabeledBooleanInput';

type Props = Omit<LabeledBooleanInputProps, 'type'> & {
  /**
   * The content to render for the label
   */
  children: React.ReactNode;

  /**
   * Indicates whether `LabeledCheckbox` is disabled.
   * @deprecated Use `isDisabled` instead
   */
  disabled?: boolean;
};

export const LabeledCheckbox = ({ disabled, isDisabled, ...props }: Props) => (
  <LabeledBooleanInput
    {...props}
    isDisabled={disabled || isDisabled}
    type="checkbox"
  />
);
