import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscSecurityFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M21 1.91H3v8c0 7.46 4.63 10.43 9 12.62 4.38-2.19 9-5.16 9-12.62v-8Zm-9.5 12.42-3.21-3.21L9.71 9.7l1.79 1.79 3.79-3.79 1.41 1.41-5.2 5.22Z"
    />
  </svg>
);
export default SvgMiscSecurityFill;
