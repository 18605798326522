import * as React from 'react';

type DesignSystemProviderProps = {
  /** Children nodes to render. */
  children: React.ReactNode;
  /**
   * Custom link component to be used wherever a `<Link>` is used in the
   * Design System. Can be used to hook up `<Link>`'s to a custom routing
   * solution. Defaults to an `<a>` tag if none is provided.
   **/
  LinkComponent: any;
};

const defaultContext = {
  LinkComponent: 'a' as any,
};

const DesignSystemContext = React.createContext(defaultContext);

export const useDesignSystem = () => React.useContext(DesignSystemContext);

/**
 * Allows consumers to configure various details of the Design System. This
 * should be used at the root of your application.
 */
export const DesignSystemProvider = ({
  children,
  LinkComponent,
}: DesignSystemProviderProps) => {
  return children ? (
    <DesignSystemContext.Provider
      value={{
        LinkComponent,
      }}
    >
      {React.Children.only(children)}
    </DesignSystemContext.Provider>
  ) : null;
};
