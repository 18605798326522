/** @jsx isolateComponent */

import { keyframes } from '@emotion/react';
import {
  isolateComponent,
  labelStyles,
  withModifier,
} from '@zapier/style-encapsulation';
import { Colors } from '../../../theme';
import { VisuallyHidden } from '../VisuallyHidden';

const defaultProps = {
  ariaLabel: 'Loading',
  color: 'black',
  size: 'small',
};

/** Note on (OPTIONAL) comments:
  Our current TypeScript/Styleguidist setup is making these optional props below
  display in the docs as "required." I added (OPTIONAL) to be more clear that this are
  not truly required props.
*/
type Props = {
  /** (OPTIONAL) Accessibility label for the node */
  ariaLabel?: string;

  /** (OPTIONAL) Diameter and border width of loader */
  size?: 'small' | 'medium' | 'large';

  /** (OPTIONAL) Should the loader be overlayed on top of a background
   *  @deprecated 'white' and 'black' are deprecated for v5.
   */
  color?: 'white' | 'black' | 'light' | 'dark';
} & typeof defaultProps;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const sizes = {
  small: {
    diameter: 20,
    thickness: 4,
  },
  medium: {
    diameter: 30,
    thickness: 6,
  },
  large: {
    diameter: 60,
    thickness: 10,
  },
};

const mapPropToColorValue = (color: Props['color']) => {
  let returnedColor;
  switch (color) {
    case 'black':
      returnedColor = Colors.neutral800;
      break;
    case 'white':
      returnedColor = Colors.neutral100;
      break;
    case 'light':
      returnedColor = Colors.neutral100;
      break;
    case 'dark':
      returnedColor = Colors.neutral800;
  }
  return returnedColor;
};

const Styles = labelStyles('Spinner', {
  root: (props: Props) => [
    {
      animation: `${spin} 0.75s linear infinite`,
      borderStyle: 'solid',
      borderRightColor: 'transparent',
      borderRadius: '50%',
      opacity: 0.7,
    },
    withModifier(props.size, {
      height: sizes[props.size].diameter,
      width: sizes[props.size].diameter,
      borderWidth: `${sizes[props.size].thickness}px`,
    }),
    withModifier(props.color, {
      borderColor: mapPropToColorValue(props.color),
      borderRightColor: 'transparent',
    }),
  ],
});

/**
 * Renders a spinner that indicates that something async is happening.
 */

export const Spinner = (props: Props) => {
  return (
    <div css={Styles.root(props)} data-testid="spinner" role="status">
      <VisuallyHidden>{props.ariaLabel}</VisuallyHidden>
    </div>
  );
};

Spinner.defaultProps = defaultProps;
