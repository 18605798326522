import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionFilter = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m18.43 6 1.09-3h-2.13l-2.91 8h2.13l1.09-3H22V6h-3.57ZM14.7 6H2v2h11.97l.73-2ZM7.39 13 6.3 16H2v2h3.57l-1.09 3h2.13l2.91-8H7.39ZM9.3 18H22v-2H10.03l-.73 2Z"
    />
  </svg>
);
export default SvgActionFilter;
