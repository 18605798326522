import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscTimelapse = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M12 20a8 8 0 1 1 0-16V2a10 10 0 0 0 0 20 10 10 0 0 0 9.83-8.14l-2-.37A8 8 0 0 1 12 20Zm2.57-15.58a1.24 1.24 0 0 0 1.6-.74 1.24 1.24 0 0 0-.74-1.6 1.24 1.24 0 0 0-1.6.74 1.24 1.24 0 0 0 .74 1.6Zm5.05 2.41a1.25 1.25 0 0 0-.296-2.288 1.24 1.24 0 0 0-.944.128 1.25 1.25 0 0 0 .296 2.288 1.24 1.24 0 0 0 .944-.128Zm-.1 3.89a1.25 1.25 0 0 0 1.45 1 1.25 1.25 0 0 0 1-1.45 1.25 1.25 0 0 0-1.45-1 1.25 1.25 0 0 0-1 1.45Zm-8.52.81-3.22 2.71 1.29 1.53 3.93-3.3V7h-2v4.53Z"
    />
  </svg>
);
export default SvgMiscTimelapse;
