import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscPin = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M15.5 10c0-1.93-1.57-3.5-3.5-3.5S8.5 8.07 8.5 10s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5Zm-5 0c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5-.67 1.5-1.5 1.5-1.5-.67-1.5-1.5Z"
    />
    <path
      fill="#2D2E2E"
      d="M12 2c-4.41 0-8 3.59-8 8 0 3.58 2.12 6.38 5.01 9.15l1.51-1.33C7.52 14.97 6 12.74 6 10c0-3.31 2.69-6 6-6s6 2.69 6 6c0 3.17-2.02 5.64-6 9.17l-1.51 1.34c.28.25 1.51 1.32 1.51 1.32 4.8-4.18 8-7.3 8-11.83 0-4.41-3.59-8-8-8Z"
    />
  </svg>
);
export default SvgMiscPin;
