import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavProjects = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M13 13h4v2a2 2 0 0 1-2 2h-2v-4ZM7 13h4v4H9a2 2 0 0 1-2-2v-2ZM13 7h2a2 2 0 0 1 2 2v2h-4V7ZM7 9a2 2 0 0 1 2-2h2v4H7V9Z"
    />
    <path
      fill="#2D2E2E"
      fillRule="evenodd"
      d="M2 4v14h2V4h16v16H2v2h20V2H2v2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNavProjects;
