/** @jsx isolateComponent */
import { css } from '@emotion/react';
import { isolateComponent, labelStyles } from '@zapier/style-encapsulation';

type Props = {
  /**
   * The `Button`s to render within `ButtonGroup`.
   */
  children: React.ReactNode;
  /**
   * Value to use within `grid-template-columns` `repeat` function.
   */
  columnSize?: string;
  /**
   * How to horizontally justify the content of `ButtonGroup`.
   */
  justify?: 'start' | 'center' | 'end';
  /**
   * How wide `ButtonGroup` can render within its container.
   */
  maxWidth?: string;
};

const Styles = labelStyles('ButtonGroup', {
  root: (props: Props) => css`
    max-width: ${props.maxWidth};
  `,
  inner: (props: Props) => css`
    display: grid;
    gap: 10px 20px;
    justify-content: ${props.justify};
    grid-template-columns: repeat(auto-fit, ${props.columnSize});
  `,
});

const defaultProps = {
  columnSize: 'minmax(0, 1fr)',
  justify: 'start',
  maxWidth: 'initial',
};

/**
 * `ButtonGroup` renders a group of `Button` components that are
 * equally spaced apart from each other.
 */
export const ButtonGroup = (_props: Props) => {
  const props = {
    ...defaultProps,
    ..._props,
  };
  return (
    <div css={Styles.root(props)}>
      <div css={Styles.inner(props)}>{props.children}</div>
    </div>
  );
};
