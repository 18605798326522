/** @jsx isolateComponent */

import { kebabCaseAriaAttrs } from '../../../utils/formatAriaAttrKeys';
import { isolateComponent, labelStyles } from '@zapier/style-encapsulation';

export type Props = {
  /**
   * Accessibility label that describes the options available inside the
   * menu, e.g. "List of items", "List of Countries", etc.
   */
  ariaLabel: string;
  /**
   * The `id` for the menu so it can be properly referenced.
   */
  id?: string;
  /**
   * Items to render within the `Menu`.
   */
  children: React.ReactNode;
  /**
   * The optional a11y role for the menu. `menu` is the semantic equivalent of `<ul>`
   *  and should be used when the children have a role of `menuitem`,
   *  while `listbox` is the semantic equivalent of `<select>` and
   *  should be used when the children have the role of `option`.
   */
  role?: 'listbox' | 'menu';
};

const Styles = labelStyles('Menu', {
  root: {
    overflow: 'auto',
  },
});

/**
 * Renders a list of items within a scrollable container.
 */
export const Menu = (props: Props) => {
  return (
    <ul css={Styles.root} role={props.role} {...kebabCaseAriaAttrs(props)}>
      {props.children}
    </ul>
  );
};
