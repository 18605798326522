import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionPin = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M13.92.81 10.94 7.2l-5.21.46-1.67 1.67 10.61 10.61 1.67-1.67.46-5.21 6.39-2.98L13.92.81Zm.98 10.92-.45 5.16-7.34-7.34 5.16-.45 2.26-4.85 5.22 5.22-4.85 2.26ZM7.566 15.014l-5.274 5.275 1.414 1.414 5.275-5.275-1.415-1.414Z"
    />
  </svg>
);
export default SvgActionPin;
