import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavHome = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m12 5.16 11 6.34V9.2l-2-1.16V5h-2v1.89l-7-4.04L1 9.2v2.3l2-1.15V21h18v-8.33l-2-1.16V19H5V9.2l7-4.04Z"
    />
  </svg>
);
export default SvgNavHome;
