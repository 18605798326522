import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscCookieFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M22 11h-4V6h-5V2h-1C9.28 2 6.73 3.08 4.83 5.03A9.942 9.942 0 0 0 2 12.28c.14 5.21 4.5 9.57 9.71 9.71H12c2.62 0 5.09-1 6.97-2.83A9.905 9.905 0 0 0 22 12v-1ZM7 14.5A1.25 1.25 0 1 1 7 12a1.25 1.25 0 0 1 0 2.5Zm1.5-4.75a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5Zm2.25 8.5a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5Zm1.25-5a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5Zm3.5 3.5a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5Z"
    />
  </svg>
);
export default SvgMiscCookieFill;
