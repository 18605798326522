import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavExplore = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M12 2C7.53 2 3.74 4.95 2.46 9h2.13a8.058 8.058 0 0 1 4.54-4.46L6.78 11H2.05C1.46 16.89 6.1 22 12 22c5.51 0 10-4.49 10-10S17.51 2 12 2Zm-.56 2.03c.57-.04.57-.04 1.09 0L15.07 11H8.9l2.54-6.97ZM4.07 13h2.71l2.35 6.46c-2.7-1.05-4.69-3.5-5.06-6.46Zm7.37 6.97L8.9 13h6.17l-2.54 6.97c-.51.04-.52.04-1.09 0Zm3.41-.5L17.2 13h2.73c-.37 2.97-2.38 5.43-5.08 6.47ZM17.2 11l-2.35-6.47A8.007 8.007 0 0 1 19.93 11H17.2Z"
    />
  </svg>
);
export default SvgNavExplore;
