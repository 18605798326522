import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavTables = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M12 2.998h9.002v4.501H12V2.998ZM12 7.499H2.998V12H12V7.5ZM12 12h9.002v4.501H12v-4.5ZM2.998 16.501H12v4.501H2.998v-4.5Z"
    />
  </svg>
);
export default SvgNavTables;
