import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavInterfaces = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      fillRule="evenodd"
      d="M10 20H4.02V4H20v10l2 2V2H2v20h8v-2Zm2 2V10l10 8.786-6.168-.01-.196.15L12 22ZM6 6h2v2H6V6Zm12 0h-8v2h8V6Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNavInterfaces;
