import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgFileFilledFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path fill="#000" d="M15.5 2.1v4.4h4.4l-4.4-4.4Z" />
    <path fill="#000" d="M14 8V2H4v20h16V8h-6Zm2 10H8v-2h8v2Zm0-4H8v-2h8v2Z" />
  </svg>
);
export default SvgFileFilledFill;
