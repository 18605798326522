import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionBug = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M13 9h-2v4.5h2V9Zm-1 8.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM22 12V7h-2v3h-2a6 6 0 0 0-1.74-4.22l1-2.78h-2.12l-.57 1.58a6 6 0 0 0-5.14 0L8.86 3H6.73l1 2.78A6 6 0 0 0 6.35 8h2.21A4 4 0 0 1 16 10v6a4 4 0 1 1-8 0v-6H4V7H2v5h4v4H2v5h2v-3h2.35a6 6 0 0 0 11.3 0H20v3h2v-5h-4v-4h4Z"
    />
  </svg>
);
export default SvgActionBug;
