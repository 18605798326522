import React from 'react';
import { css, Global } from '@emotion/react';

type Props = {
  /**
   * @deprecated GTUltra no longer part of our font stack
   */
  includeUltra?: boolean;
  children?: React.ReactNode;
};

/**
 * A Helmet-like component to load Zinnia Fonts for NextJS applications.
 */
export const ZinniaFonts = ({ children }: Props) => {
  // ZIN-385 Update to correct paths for Degular
  return (
    <React.Fragment>
      <Global
        styles={css`
          @font-face {
            font-family: Degular Display;
            src: url('https://zapier-fonts-production.s3.amazonaws.com/Degular/Degular_Display-Bold.woff2')
              format('woff2');
            font-display: swap;
            font-weight: 700;
          }
          @font-face {
            font-family: Degular;
            src: url('https://zapier-fonts-production.s3.amazonaws.com/Degular/Degular-Medium.woff2')
              format('woff2');
            font-display: swap;
            font-weight: 500;
          }
          @font-face {
            font-family: Degular;
            src: url('https://zapier-fonts-production.s3.amazonaws.com/Degular/Degular-Semibold.woff2')
              format('woff2');
            font-display: swap;
            font-weight: 600;
          }
        `}
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
      />
      {children}
    </React.Fragment>
  );
};
