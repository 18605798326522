/** @jsx isolateComponent */
import { isolateComponent } from '@zapier/style-encapsulation';
import {
  BaseButton,
  Props as BaseButtonProps,
} from '../../navigation/Button/BaseButton';

import { VisuallyHidden } from '../../display/VisuallyHidden';

export type Props = Omit<BaseButtonProps, 'isPill' | 'isSquare'> & {
  color?: 'brand' | 'brand-nav';
  size?: 'small' | 'medium' | 'large';
};

/**
 * A Zapier brand-themed button.
 */

export function BrandButton({
  color = 'brand',
  size = 'small',
  ...props
}: Props) {
  return color === 'brand-nav' ? (
    <BaseButton
      color="brand-nav"
      iconBefore={props.iconBefore || 'formAdd'}
      size="medium"
    >
      <VisuallyHidden>{props.children || 'Create a Zap'}</VisuallyHidden>
    </BaseButton>
  ) : (
    <BaseButton color={color} size={size} {...props} />
  );
}
