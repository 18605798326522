import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscHeadsetFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m5 19.33.51.82 4.74-2.73L7.9 12H4.52v-.72A7.4 7.4 0 0 1 12 4a7.4 7.4 0 0 1 7.5 7.28V12h-3.39l-2.39 5.42 3 1.71L15.46 21H11v2h5.54l2.86-4.39a14.12 14.12 0 0 0 2.1-7.33A9.4 9.4 0 0 0 12 2a9.4 9.4 0 0 0-9.5 9.28A14.59 14.59 0 0 0 5 19.33Z"
    />
  </svg>
);
export default SvgMiscHeadsetFill;
