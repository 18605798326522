import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavCogFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M19.9 8.9a2.516 2.516 0 0 1-1.26-2.18l.01-2.42-3.3-1.9-2.09 1.22c-.78.45-1.74.45-2.52 0L8.65 2.39l-3.29 1.9.01 2.42c0 .9-.48 1.73-1.26 2.18L2 10.1v3.81l2.1 1.2c.78.45 1.26 1.28 1.26 2.18l-.01 2.42 3.3 1.9 2.09-1.22a2.52 2.52 0 0 1 2.52 0l2.09 1.22 3.29-1.9-.01-2.42c0-.9.48-1.73 1.26-2.18l2.1-1.2V10.1L19.9 8.9ZM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4Z"
    />
  </svg>
);
export default SvgNavCogFill;
