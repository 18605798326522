import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgStatusStatusFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      fillRule="evenodd"
      d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11.817 1.358L10.09 5.954l-2.958 4.781h-3.4v2h4.514l1.664-2.69 3.825 7.597 2.71-4.907H20v-2h-4.735l-1.448 2.623Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgStatusStatusFill;
