import { darken, getLuminance } from 'polished'; // These colors should exactly match those in the Zapier Visual Identity proposal:
//
// https://zapier.com/brand/colors/primary-and-secondary

export const Base = {
  black: '#000000',
  white: '#ffffff',
  inherit: 'inherit',
  transparent: 'transparent',
  // PRIMARY COLORS
  // Blacks/Greys
  deepSpace: '#354147',
  deepspace: '#354147',
  blackWidow: '#20272b',
  batmobile: '#5f6c72',
  steel: '#969ea2',
  adamantium: '#dadfe2',
  storm: '#f1f4f5',
  // bridge over to colors in design system
  grey000: '#fafafa',
  grey100: '#f2f2f2',
  grey200: '#E6E6E6',
  grey500: '#999999',
  // Reds
  lava: '#ff4a00',
  cyclops: '#c63a00',
  starLord: '#ffa38c',
  drax: '#ffd2bf',
  danger: '#e52f00',
  // From the Zapier Design System
  red100: '#ffd2bf',
  red200: '#ffa38c',
  red500: '#ff4a00',
  red700: '#c63a00',
  // Blues
  heroine: '#499df3',
  captainAmerica: '#428ddb',
  theTick: '#add2fa',
  iceman: '#d1e6fc',
  // From the Zapier Design System
  blue500: '#136bf5',
  blue700: '#105bd0',
  // SECONDARY COLORS
  // Oranges
  armor: '#fd7622',
  aquaman: '#e46a1f',
  sabretooth: '#fec19b',
  thing: '#feddc8',
  // Yellows
  wolverine: '#ffc43e',
  oldManLogan: '#e5b038',
  ultron: '#ffcf61',
  thor: '#fff0cf',
  // Greens
  gamma: '#13d0ab',
  joker: '#11bb9a',
  vision: '#95ead9',
  agamotto: '#c4f3ea',
  viper: '#62d493',
  // Aquamarines
  avatar: '#46caef',
  beast: '#3fb6d7',
  colossus: '#ace7f8',
  cyborg: '#d1f2fb',
  // Purples
  mystique: '#6061ed',
  venom: '#5657d5',
  hawkeye: '#b7b8f7',
  thunder: '#d7d7fa',
  // Pinks
  ramona: '#fc1c74',
  jubilee: '#e31968',
  gambit: '#fd99c0',
  krang: '#fec6dc',
};
type Spec = {
  main: string;
  text: string;
  border: string;
};

const createThemeColor = (spec: Spec) => {
  // Add `type` property of "light" or "dark". Light colors may disappear
  // against a light background, so need to account for that in some
  // places.
  const type =
    getLuminance(spec.main) > getLuminance(spec.text) ? 'light' : 'dark';
  return {
    type,
    dark: darken(0.03, spec.main),
    ...spec,
  };
};

export const Colors = {
  ...Base,
  // Semantic
  theme: {
    primary: createThemeColor({
      main: Base.heroine,
      text: Base.white,
      border: 'transparent',
    }),
    secondary: createThemeColor({
      main: Base.storm,
      text: Base.deepSpace,
      border: Base.adamantium,
    }),
    important: createThemeColor({
      main: Base.lava,
      text: Base.white,
      border: 'transparent',
    }),
    success: createThemeColor({
      main: Base.gamma,
      text: Base.white,
      border: 'transparent',
    }),
    platformDark: createThemeColor({
      main: Base.blackWidow,
      text: Base.white,
      border: Base.white,
    }),
    platformLight: createThemeColor({
      main: Base.white,
      text: Base.blackWidow,
      border: Base.blackWidow,
    }),
  },
  // Statuses
  status: {
    success: {
      border: Base.viper,
      bg: '#daf5e6',
    },
    warning: {
      border: Base.wolverine,
      bg: '#fff6e1',
    },
    error: {
      border: Base.lava,
      bg: '#ffede5',
    },
    plain: {
      border: Base.adamantium,
      bg: Base.white,
    },
  },
};
export type ColorName = keyof typeof Base;
