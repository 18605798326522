import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgStatusFilter = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M3.53 5h14.33L13 10.79v6.34l-2 1.15v-7.49L7.82 7H5.21L9 11.51v10.21l6-3.43v-6.78L22.14 3H1.86l1.67 2Z"
    />
  </svg>
);
export default SvgStatusFilter;
