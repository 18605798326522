/** @jsx isolateComponent */
import React, { useState } from 'react';

import { css } from '@emotion/react';
import { isolateComponent, labelStyles } from '@zapier/style-encapsulation';
import { Icon } from '../../display/Icon';
import { FormLabel } from '../../forms/FormLabel';
import { Colors, Typography } from '../../../theme';
import { useOverflow } from '../../../hooks/useOverflow';
import { uniqueId } from '../../../utils/uniqueId';

type RenderInputOptions = {
  ariaDescribedBy?: string;
  id: string;
  isDisabled: boolean;
  isErrored: boolean;
  isRequired: boolean;
};

type RenderLabelProps = {
  htmlFor: string;
  isDisabled?: boolean;
  isErrored?: boolean;
  isRequired?: boolean;
  label: string;
  requiredText?: React.ReactNode;
};

export type Props = {
  /**
   * Optional error message for the field. If it exists, the field will be
   * considered to have an error.
   */
  error?: string;
  /**
   * The number of help text lines to show (defaults to 1). Any extra text will be
   * hidden behind a "more" button.
   */
  helpTextLineClamp?: number;
  /**
   * `id` for the input field. If this isn't supplied then one will be generated
   * to properly link the `label` to the input.
   */
  inputId?: string;
  /**
   * Indicates whether the field is disabled.
   */
  isDisabled?: boolean;
  /**
   * Indicates whether the field is required.
   */
  isRequired?: boolean;
  /**
   * Text for the `label` element for this field.
   */
  label: string;
  /**
   * Function that returns the help text to be rendered beneath the input.
   */
  renderHelpText?: () => React.ReactNode;
  /**
   * Function that returns the rendered input field.
   */
  renderInput: (opts: RenderInputOptions) => React.ReactNode;
  /**
   * Function that returns the label to be rendered.
   */
  renderLabel?: (labelProps: RenderLabelProps) => React.ReactNode;
  /**
   * Optional text to render inside the required label.
   */
  requiredText?: React.ReactNode;
};

const Styles = labelStyles('Field', {
  root: css`
    display: grid;
    grid-gap: 5px;
  `,

  helpTextWrapper: css`
    ${Typography.smallPrint1}
  `,

  helpText: css`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 10px;
    align-items: start;
    color: ${Colors.neutral700};
  `,

  helpTextInner: css`
    padding-top: 1px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    // Without this truncation won't properly occur.
    * {
      display: inline;
      margin: 0;
    }
  `,

  helpTextToggle: css`
    cursor: pointer;
    text-decoration: underline;
    text-transform: lowercase;
    // Prevent help text toggle size from changing.
    width: 33px;
    &:hover,
    &:focus {
      outline: 1px solid ${Colors.blue};
      outline-offset: 1px;
      color: ${Colors.blue};
    }
  `,

  error: css`
    display: inline;
    color: ${Colors.error400};
  `,
});

type HelpTextProps = {
  children: React.ReactNode;
  helpTextLineClamp: number;
};

/**
 * Component to render help text which can be expanded and collapsed.
 */
const HelpText = ({ children, helpTextLineClamp }: HelpTextProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOverflowing, setOverflowingNode] = useOverflow();

  // Only render toggle if it's overflowing.
  const toggle = isOverflowing && (
    <button
      aria-hidden="true"
      css={Styles.helpTextToggle}
      onClick={() => setIsOpen(!isOpen)}
      type="button"
    >
      {isOpen ? 'Less' : 'More'}
    </button>
  );
  const style = {
    WebkitLineClamp: isOpen ? undefined : helpTextLineClamp,
  };
  return (
    <div css={Styles.helpText}>
      <div
        css={Styles.helpTextInner}
        ref={(node) => setOverflowingNode(node as HTMLElement)}
        style={style}
      >
        <div>{children}</div>
      </div>
      {toggle}
    </div>
  );
};

const defaultProps = {
  helpTextLineClamp: 1,
};

/**
 * Wraps an input field and renders a label and optional help text.
 */
export const Field = (_props: Props) => {
  const props = {
    ...defaultProps,
    ..._props,
  };
  const [id] = useState(props.inputId || uniqueId('Field-'));
  const describedById = `${id}-description`;
  const helpText = props.renderHelpText && props.renderHelpText();
  return (
    <div css={Styles.root}>
      <div>
        {props.renderLabel ? (
          props.renderLabel({
            htmlFor: id,
            isDisabled: props.isDisabled,
            isErrored: !!props.error,
            isRequired: props.isRequired,
            label: props.label,
            requiredText: props.requiredText,
          })
        ) : (
          <FormLabel
            alignItems="start"
            htmlFor={id}
            isDisabled={props.isDisabled}
            isErrored={!!props.error}
            isRequired={props.isRequired}
            requiredText={props.requiredText}
            size="small"
          >
            {props.error && (
              <Icon
                color="error400"
                isBlock={true}
                name="alertTriangle"
                size={18}
              />
            )}
            <span>{props.label}</span>
          </FormLabel>
        )}
      </div>
      <div>
        {props.renderInput({
          ariaDescribedBy: helpText || props.error ? describedById : undefined,
          id,
          isDisabled: !!props.isDisabled,
          isErrored: !!props.error,
          isRequired: !!props.isRequired,
        })}
      </div>
      <div
        aria-live={props.error ? 'polite' : 'off'}
        css={Styles.helpTextWrapper}
        id={describedById}
      >
        {(helpText || props.error) && (
          <HelpText helpTextLineClamp={props.helpTextLineClamp}>
            {props.error && <div css={Styles.error}>{props.error} </div>}
            {helpText}
          </HelpText>
        )}
      </div>
    </div>
  );
};
