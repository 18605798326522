import { readCookie, writeCookie } from '@zapier/cookies';
import { emit } from '@zapier/events-toolkit-browser';

export const setCookie = (
  id: string,
  value: string,
  maxAge: number,
  path: string = '/'
) => {
  try {
    writeCookie(id, value, {
      // Cookies are categorized as `essential` for backwards compatibility,
      // but devs should use `writeCookie` from `@zapier/cookies` with
      // the correct `category`.
      category: 'essential',
      maxAge,
      path,
    });
  } catch (e) {
    console.error('Unable to set cookie', {
      id,
    });
  }
};

export const getCookie = (id: string) => {
  try {
    return readCookie(id);
  } catch (e) {
    return undefined;
  }
};

const UserInteractionEvent =
  'web_analytics.tracking.user_interaction.UserInteractionEvent';

export type EventDetails = {
  interaction_name: string;
  event_action: string;
  option_selected?: string;
};

export const emitUserInteractionEvent = async ({
  ...eventDetails
}: EventDetails) => {
  return await emit(
    {
      ...eventDetails,
      absolute_url: window.location.pathname,
      referrer: document.referrer,
      system_name: 'design_system',
    },
    UserInteractionEvent
  );
};

export default setCookie;
