import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgFormCheck = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m18.51 6.82-8.26 8.27-4.43-4.43-1.41 1.41 5.84 5.84 9.68-9.67-1.42-1.42Z"
    />
  </svg>
);
export default SvgFormCheck;
