import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavProjectsFill = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      fillRule="evenodd"
      d="M2 2h20v20H2V2Zm15 11v2a2 2 0 0 1-2 2h-2v-4h4Zm-6 0H7v2a2 2 0 0 0 2 2h2v-4Zm2-6h2a2 2 0 0 1 2 2v2h-4V7ZM9 7a2 2 0 0 0-2 2v2h4V7H9Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNavProjectsFill;
