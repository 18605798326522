import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionExplore = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M13.59 13.59a2.25 2.25 0 1 0-3.18-3.184 2.25 2.25 0 0 0 3.18 3.184Zm-7-7L3.78 17 6 16.35 8.2 8.2l10.39-2.79L15.8 15.8 3.18 19.18l-.59 2.23 14.85-4 4-14.85L6.59 6.59Z"
    />
  </svg>
);
export default SvgActionExplore;
