import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionAttachment = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M7.01 21c1.28 0 2.56-.49 3.54-1.46L21.12 8.96a3 3 0 0 0 0-4.24 3 3 0 0 0-4.24 0L6.31 15.29l1.41 1.41L18.3 6.13a.996.996 0 1 1 1.41 1.41L9.13 18.12a3 3 0 0 1-4.24 0 3 3 0 0 1 0-4.24l8.49-8.48-1.41-1.41-8.49 8.48a5.003 5.003 0 0 0 0 7.07c.97.97 2.25 1.46 3.53 1.46Z"
    />
  </svg>
);
export default SvgActionAttachment;
