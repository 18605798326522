import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgArrowConnect = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M13.5 12h2.61l4.19-5-4.19-5H13.5l3.36 4H4v2h12.86l-3.36 4Zm-3 0H7.89L3.7 17l4.19 5h2.61l-3.36-4H20v-2H7.14l3.36-4Z"
    />
  </svg>
);
export default SvgArrowConnect;
