import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgNavBriefcase = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M22 6h-4.53l-2.52-3h-5.9L6.53 6H2v2h18v6h-7v-2h-2v2H4v-4H2v11h20V6ZM9.14 6l.84-1h4.03l.84 1H9.14ZM20 19H4v-3h7v1h2v-1h7v3Z"
    />
  </svg>
);
export default SvgNavBriefcase;
