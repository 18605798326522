import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgMiscSteps3 = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M21.01 5h-10v2h10V5ZM21.01 11h-10v2h10v-2ZM21.01 17h-10v2h10v-2ZM4.8 8h.95V4.2L4 4.93v.92l.8-.35V8ZM6.54 13.22H5.43l.47-.61c.33-.44.55-.78.55-1.21 0-.64-.51-1.11-1.23-1.11-.48 0-.91.19-1.18.42v.9c.22-.19.65-.46 1.02-.46.29 0 .44.14.44.38 0 .29-.33.69-.65 1.12L3.81 14h2.73v-.78ZM4.1 16.29v.78h1.15l-.64 1.11c.55.04.99.19.99.58 0 .29-.27.47-.6.47-.34 0-.69-.15-.97-.4v.88c.26.16.56.29 1.06.29.73 0 1.41-.46 1.41-1.25 0-.44-.25-.86-.71-1.03l.81-1.44H4.1v.01Z"
    />
  </svg>
);
export default SvgMiscSteps3;
