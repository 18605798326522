import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionEditPlus = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="m19.43 8.35-3.51-3.52L4 16.74v2.09h2.09L16.6 8.31l1.42 1.42-11.11 11.1H2v-4.91L15.92 2l4.93 4.93-1.42 1.42ZM19 14v3h3v2h-3v3h-2v-3h-3v-2h3v-3h2Z"
    />
  </svg>
);
export default SvgActionEditPlus;
