import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgRatingThumbsBoth = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M16 12h7v-2h-8l-4 4.81v2.69a2 2 0 0 0 2 2h3V21a2 2 0 0 0 2 2h1l4-4.76V14h-2v3.51L18.07 21H18v-3.5h-5v-2l3-3.5Zm-3-5.5a2 2 0 0 0-2-2H8V3a2 2 0 0 0-2-2H5L1 5.76V10h2V6.49L5.93 3H6v3.5h5v2l-3 3.53H1v2h8l4-4.81V6.5Z"
    />
  </svg>
);
export default SvgRatingThumbsBoth;
