import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgFormAddSquare = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M21 3H3v18h18V3Zm-4 10h-4v4h-2v-4H7v-2h4V7h2v4h4v2Z"
    />
  </svg>
);
export default SvgFormAddSquare;
