import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgFileRemove = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M15.41 2 20 6.59V8h-6V4H6v16h12V10h2v12H4V2h11.41Z"
    />
    <path fill="#2D2E2E" d="M16 13v2H8v-2h8Z" />
  </svg>
);
export default SvgFileRemove;
