import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionBuild = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <g clipPath="url(#actionBuild_svg__a)">
      <path
        fill="#2D2E2E"
        fillRule="evenodd"
        d="M16.958 5.09 14.26 7.642l.342 1.779 1.78.342 2.575-2.724 1.418-1.498a7.041 7.041 0 0 1 1.249 4.014 7.07 7.07 0 1 1-3.159-5.89L16.958 5.09Zm.876 6.048 1.781-1.884a5.07 5.07 0 1 1-4.93-4.767l-1.8 1.702-.796.754.207 1.077.342 1.779.257 1.33 1.33.256 1.778.342 1.077.208.754-.797Zm-15.933 9.66 5.583-5.583 1.414 1.414-5.582 5.582L1.9 20.797Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="actionBuild_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgActionBuild;
