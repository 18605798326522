/** @jsx isolateComponent */
import { Fragment } from 'react';
import { css } from '@emotion/react';
import { isolateComponent, labelStyles } from '@zapier/style-encapsulation';
import { Heading } from '../../display/Heading';
import { Icon } from '../../display/Icon';
import {
  Animation,
  Colors,
  ColorName,
  Shadows,
  Typography,
} from '../../../theme';
import type { IconName } from '../../display/Icon';

export type AlertType = 'info' | 'success' | 'warning' | 'error';

export type Props = {
  /**
   * Controls the `aria-live` attribute of `Alert`.
   */
  ariaLive?: 'off' | 'polite' | 'assertive';
  /**
   * Controls the `role` attribute of `Alert`.
   */
  role?: 'alert' | 'alertdialog';
  /**
   * Controls the styling of `Alert` to indicate to users what type
   * of alert it is.
   */
  type?: AlertType;
  /**
   * Whether or not `Alert` has a drop shadow.
   */
  hasShadow?: boolean;
  /**
   * Controls how content within `Alert` is laid out.
   */
  layout?: 'box' | 'banner' | 'banner-inverse' | 'modal' | 'mini';
  /**
   * Controls background color if the variant is `Mini`.
   */
  miniBackgroundColor?: 'moss' | 'neutral100' | 'blue';
  /**
   * The title of the `Alert`.
   */
  title?: React.ReactNode;
  /**
   * The content to render within `Alert`. Typically comprised of
   * subcomponents like `AlertDescription` and `ButtonGroup`.
   */
  children?: React.ReactNode;
  /**
   * Optional function to call when `Alert` is dismissed.
   * Its presence determines whether the close button renders.
   */
  onDismiss?: (e: React.MouseEvent) => void;
};

export type Configs = {
  [key in AlertType]: {
    backgroundColor: ColorName;
    color: ColorName;
    icon: IconName;
    iconColor: ColorName;
  };
};

const typeConfigs: Configs = {
  info: {
    backgroundColor: 'blue',
    color: 'neutral100',
    icon: 'alertInfoFill',
    iconColor: 'blue',
  },
  success: {
    backgroundColor: 'success400',
    color: 'neutral700',
    icon: 'formCheckCircle',
    iconColor: 'success400',
  },
  warning: {
    backgroundColor: 'warning400',
    color: 'neutral700',
    icon: 'alertCircleFill',
    iconColor: 'neutral700',
  },
  error: {
    backgroundColor: 'error400',
    color: 'neutral100',
    icon: 'alertTriangleFill',
    iconColor: 'error400',
  },
};

const Styles = labelStyles('Alert', {
  root: ({ miniBackgroundColor = 'blue', ...props }: Props) => [
    props.layout === 'box' && [
      css`
        label: -box;
        position: relative;
        display: grid;
        grid-template-columns: max-content 1fr max-content;
        align-items: start;
        gap: 10px;
        padding: 10px 10px 10px 20px;
        border: 1px solid ${Colors.neutral300};
        border-radius: 5px;
        background-color: ${Colors.neutral100};
        ${Typography.paragraph3};
        color: ${Colors.neutral700};

        // Colored stripe that renders on the left of the box.
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          border-top-left-radius: inherit;
          border-bottom-left-radius: inherit;
          width: 10px;
        }
      `,
      css`
        label: -${props.type};
        &::before {
          background-color: ${Colors[typeConfigs[props.type!].backgroundColor]};
        }
      `,
    ],
    props.layout === 'banner' && [
      css`
        label: -banner;
        position: relative;
        display: grid;
        align-items: center;
        ${Typography.paragraph3};
        padding: 10px 44px;
        text-align: center;
      `,
      css`
        label: -${props.type};
        color: ${Colors[typeConfigs[props.type!].color]};
        background-color: ${Colors[typeConfigs[props.type!].backgroundColor]};

        // Rare tag selection to ensure that links are accessible
        // and consumers don't need to worry about it.
        a[class] {
          color: inherit;
          text-decoration: underline;
          &:hover,
          &:focus {
            color: rgba(${Colors.neutral100rgb}, 0.75);
          }
          &:focus {
            outline: 1px solid rgba(${Colors.neutral100rgb}, 0.75);
          }
        }
      `,
    ],
    props.layout === 'banner-inverse' && [
      css`
        label: -banner;
        position: relative;
        display: grid;
        align-items: center;
        ${Typography.paragraph3};
        padding: 10px 44px;
        text-align: center;
      `,
      css`
        label: -${props.type};
        color: ${Colors.neutral700};
        background-color: ${Colors.neutral100};
      `,
    ],
    props.layout === 'mini' && [
      css`
        label: -mini;
        position: relative;
        display: grid;
        align-items: center;
        ${Typography.paragraph3Medium};
        text-align: center;
        padding: 8px 44px;
      `,
      css`
        label: -${props.type};
        background-color: ${Colors[miniBackgroundColor]};
        color: ${miniBackgroundColor === 'neutral100'
          ? Colors.neutral800
          : Colors.neutral100};

        a[class] {
          color: inherit;
          text-decoration: underline;
          &:hover,
          &:focus {
            color: ${miniBackgroundColor === 'neutral100'
              ? Colors.blue
              : `rgba(${Colors.neutral100rgb}, 0.75)`};
          }
          &:focus {
            outline: 1px solid
              ${miniBackgroundColor === 'neutral100'
                ? Colors.blue
                : `rgba(${Colors.neutral100rgb}, 0.75)`};
          }
        }
      `,
    ],
    props.layout === 'modal' && [
      css`
        label: -modal;
        position: relative;
        max-width: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: ${Colors.neutral100};
        padding: 15px;
        border: 1px solid ${Colors.neutral300};
        text-align: center;
        border-radius: 10px;
      `,
    ],
    props.hasShadow &&
      css`
        label: -shadowed;
        box-shadow: ${Shadows.elevation5};
      `,
  ],
  typeIcon: (props: Props) => [
    css`
      color: ${Colors[typeConfigs[props.type!].iconColor]};
    `,
    props.layout === 'modal' &&
      css`
        margin-bottom: 8px;
      `,
    (props.layout === 'banner' || props.layout === 'banner-inverse') &&
      css`
        color: ${props.layout === 'banner'
          ? 'currentColor'
          : Colors[typeConfigs[props.type!].iconColor]};
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        position: relative;
        top: -2px;
      `,
  ],
  titleInner: (props: Props) => [
    css`
      display: block;
      ${Typography.paragraph3Bold};
      color: ${Colors.neutral800};
    `,
    props.layout === 'banner' &&
      css`
        color: inherit;
      `,
    props.layout === 'mini' &&
      css`
        color: inherit;
      `,
  ],
  closeButton: (props: Props) => [
    props.layout !== 'banner' &&
      props.layout !== 'mini' &&
      css`
        color: ${Colors.neutral600};
        transition: ${Animation.transitionValue};

        &:hover,
        &:focus {
          color: ${Colors.neutral600};
        }
      `,
    props.layout === 'banner' &&
      css`
        color: inherit;
      `,
    props.layout === 'mini' &&
      css`
        color: ${props.miniBackgroundColor === 'neutral100'
          ? Colors.neutral600
          : 'inherit'};
      `,
    (props.layout === 'banner' || props.layout === 'banner-inverse') &&
      css`
        position: absolute;
        right: 15px;
        top: 22px;
        transform: translateY(-50%);
      `,
    props.layout === 'mini' &&
      css`
        position: absolute;
        right: 15px;
        top: 20px;
        transform: translateY(-50%);
      `,
    props.layout === 'modal' &&
      css`
        position: absolute;
        top: 15px;
        right: 15px;
      `,
  ],
});

const defaultProps = {
  hasShadow: true,
  type: 'info',
  layout: 'box',
};

/**
 * `Alert` renders information that is immediately or near-immediately
 * important for users to know.
 */
export const Alert = (_props: Props) => {
  const props = {
    ...defaultProps,
    ..._props,
  };

  const config = typeConfigs[props.type as AlertType];
  const isBanner =
    props.layout === 'banner' || props.layout === 'banner-inverse';
  const isMini = props.layout === 'mini';
  const icon = (
    <div css={Styles.typeIcon(props)}>
      <Icon
        ariaLabel={props.type}
        isBlock={true}
        name={config.icon}
        size={24}
      />
    </div>
  );
  const closeButton = props.onDismiss && (
    <button
      type="button"
      aria-label="Dismiss"
      css={Styles.closeButton(props)}
      onClick={props.onDismiss}
    >
      <Icon isBlock={true} name="formX" size={24} />
    </button>
  );
  // Use `&nbsp;` to prevent `icon` from wrapping and becoming a widow/orphan.
  const bannerIcon = isBanner && !isMini && icon && (
    <Fragment>{icon}&nbsp;</Fragment>
  );
  return (
    <article
      aria-live={props.ariaLive}
      css={Styles.root(props)}
      role={props.role}
    >
      {!isBanner && !isMini && icon}
      <div css={Styles.content}>
        {props.title && !isMini && (
          <Heading css={Styles.title}>
            <span css={Styles.titleInner(props)}>
              {bannerIcon}
              {props.title}
            </span>
          </Heading>
        )}
        {props.children}
      </div>
      {closeButton}
    </article>
  );
};
