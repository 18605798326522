import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgActionLink = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M10.12 18.12a3 3 0 0 1-4.24 0 3 3 0 0 1 0-4.24l3.29-3.29-1.41-1.42-3.29 3.29a5.003 5.003 0 0 0 0 7.07 5.003 5.003 0 0 0 7.07 0l3.29-3.29-1.41-1.41-3.3 3.29ZM19.54 4.46a5.003 5.003 0 0 0-7.07 0l-3.3 3.3 1.41 1.41 3.29-3.29a3 3 0 0 1 4.24 0 3 3 0 0 1 0 4.24l-3.29 3.29 1.41 1.41 3.29-3.29c1.96-1.94 1.96-5.12.02-7.07Z"
    />
    <path
      fill="#2D2E2E"
      d="m14.58 8.005-6.582 6.583 1.414 1.414 6.583-6.583-1.414-1.414Z"
    />
  </svg>
);
export default SvgActionLink;
