import * as React from 'react';
import type { SVGProps } from 'react';
type ZinniaSvgProps = SVGProps<SVGSVGElement> & {
  size: number | string,
};
const SvgFormXCircleOutlined = (props: ZinniaSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
    {...props}
  >
    <path
      fill="#2D2E2E"
      d="M14.83 7.67 12 10.5 9.17 7.67 7.76 9.08l2.83 2.83-2.83 2.83 1.41 1.41L12 13.33l2.83 2.82 1.41-1.41-2.83-2.83 2.83-2.83-1.41-1.41Z"
    />
    <path
      fill="#2D2E2E"
      d="M19.07 4.93a10 10 0 0 0-14.15 0c-3.9 3.9-3.9 10.24 0 14.14a10 10 0 0 0 14.15 0c3.91-3.9 3.91-10.24 0-14.14Zm-1.41 12.73c-3.13 3.12-8.19 3.13-11.32 0-3.12-3.12-3.12-8.19 0-11.31 3.13-3.12 8.19-3.13 11.32 0 3.12 3.11 3.12 8.19 0 11.31Z"
    />
  </svg>
);
export default SvgFormXCircleOutlined;
